/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import { Link } from "react-router-dom";
import validator from "validator";
const isEmpty = (value) => (value === "" ? "This field is required." : null);

function validationConfig(props) {
  const { email, password } = props.fields;

  return {
    fields: ["email", "password"],

    validations: {
      email: [[isEmpty, email]],
      password: [[isEmpty, password]],
    },
  };
}

class Login extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      fields,
      onChange,
      onValid,
      pagetype,
      onInvalid,
      $field,
      $validation,
    } = this.props;
    let errMsgEmail, errMsgPassword;

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    }

    return (
      <div className="popup-body">
        <span className="spn-error"></span>
        <div className="form-group">
          <input
            type="text"
            placeholder="Email Address"
            className="form-control"
            value={fields.email}
            {...$field("email", (e) => onChange("email", e.target.value))}
          />
          {errMsgEmail}
        </div>
        <div className="form-group">
          <input
            placeholder="Password"
            type="password"
            className="form-control"
            value={fields.password}
            {...$field("password", (e) => onChange("password", e.target.value))}
          />
          {errMsgPassword}
        </div>
        <div className="include-flex">
          <button
            className="btn button login_submit"
            onClick={(e) => {
              e.preventDefault();
              this.props.$submit(onValid, onInvalid);
            }}
          >
            {" "}
            Login{" "}
          </button>
          {pagetype === "mealplan" ? (
            <a href={void 0} onClick={this.props.onGoLink.bind(this, "forgot")}>
              Forget Password?
            </a>
          ) : (
            <Link to="/forgot-password">Forget Password?</Link>
          )}
          {/* <a href="#forgot-password-popup" className="open-popup-link">
              Forget Password?
            </a> */}
        </div>
        <div className="or-seperator">
          <span>or</span>
        </div>
        <div className="form-group no-margin-btm">
          {pagetype === "mealplan" ? (
            <a
              href={void 0}
              onClick={this.props.onGoLink.bind(this, "register")}
              className="button btn-ghost"
            >
              {" "}
              Register As New User
            </a>
          ) : (
            <Link to="/sign-in" className="button btn-ghost">
              {" "}
              Register As New User
            </Link>
          )}
          {/* <a
              href="#signup-popup"
              data-effect="mfp-zoom-in"
              className="open-popup-link button btn-ghost"
            >
              {" "}
              Register As New User
            </a> */}
        </div>
      </div>
    );
  }
}
Login = validated(validationConfig)(Login);

export default Login;
