/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_MEAL_PLAN_PORDERDETAIL,
  SET_MEAL_PLAN_PORDERDETAIL,
} from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetMealPlanPorderDetail = function* () {
  yield takeEvery(GET_MEAL_PLAN_PORDERDETAIL, workerGetMealPlanPorderDetail);
};

function* workerGetMealPlanPorderDetail({ params }) {
  try {
    const uri =
      apiUrl + "mealplan/order_history?order_status=P&app_id=" + appId + params;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_MEAL_PLAN_PORDERDETAIL, value: resultArr });
  } catch {
    console.log("Get Pastorder Failed");
  }
}
