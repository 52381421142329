/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { deliveryId } from "../Helpers/Config";
import { stripslashes, showPrices } from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";
import Mealplanproductdetail from "./Mealplanproductdetail";
import { format } from "date-fns";
var Parser = require("html-react-parser");
var qs = require("qs");

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      productDetails: [],
      product_List: [],
      filter_Tag: [],
      imagesource: "",
      viewProductSlug: "",
      OldupdateproductList: [],
      oldnavigateMenu: [],
      selProductEndSection: [],
      FinalDetailsProduct: [],
      loading: true,
      dayindex: this.props.dayindex,
      dayindexID: this.props.dayindex + "_",
      imagesource: this.props.productState.imagesource,
      productList: this.props.productState.productList,
      productDetailsList: this.props.productState.productDetailsList,
    };
  }

  productsubcatlist() {
    var productList = this.state.productList;
    if (productList.length > 0) {
      return (
        <div className="innerproduct">
          <ul className="products-list-ulmain">
            {this.productList(productList[0].subcategorie[0].products)}
          </ul>
        </div>
      );
    }
  }

  chkProStockCls(proSlug, Stock, checkLeadTime) {
    var searchProVal = "";
    var actClstxt = searchProVal === proSlug ? " active" : "";
    if (checkLeadTime !== "") {
      actClstxt += " current-not-available";
    }
    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }

    return returnText;
  }

  /* Products List */
  productList(list) {
    if (list.length > 0) {
      var imageSource = this.state.imagesource.product_image_source;

      const listProduct = list.map((productDetail, indexl) => {
        var description =
          productDetail.product_short_description !== null &&
          productDetail.product_short_description !== ""
            ? Parser(stripslashes(productDetail.product_short_description))
            : "";

        var prodivId =
          this.state.dayindexID +
          "proIndex-" +
          productDetail.product_primary_id;
        var comboProId =
          this.state.dayindexID + "comboPro-" + productDetail.product_slug;

        let checkLeadTime = this.checkLeadTime(productDetail.product_lead_time);
        var productName =
          productDetail.product_alias !== ""
            ? stripslashes(productDetail.product_alias)
            : stripslashes(productDetail.product_name);
        var checkProdAvil = this.checkProdAvil(productDetail);
        var periodType = "";
        if (this.props.selectedperiode === "Lunch & Dinner") {
          periodType = "L";
        } else {
          if (this.props.selectedperiode === "Lunch") {
            periodType = "L";
          } else {
            periodType = "D";
          }
        }

        if (
          checkProdAvil === 1 &&
          productDetail.product_mealplan_recommended !== "1" &&
          (productDetail.product_period === periodType ||
            productDetail.product_period === "B")
        ) {
          return (
            <li
              className={this.chkProStockCls(
                productDetail.product_slug,
                productDetail.product_stock,
                checkLeadTime
              )}
              id={prodivId}
              key={indexl}
            >
              <input
                type="hidden"
                className="product_type_hidden"
                value={productDetail.product_type}
              />
              <div className="products-image-div">
                {productDetail.product_thumbnail !== "" ? (
                  <img
                    src={imageSource + "/" + productDetail.product_thumbnail}
                  />
                ) : (
                  <img src={noimage} />
                )}
              </div>

              <div className="product-info-div">
                <div className="product-title">
                  <h5>{productName}</h5>
                  {description !== "" && <p>{description}</p>}
                </div>
                <div className="products-ordernow-action">
                  <div className="products-ordernow-inner">
                    <div className="product-price">
                      <h3>{showPrices(productDetail.product_price, "Y")}</h3>
                      <div
                        className="meal-plane-combo-addt-price"
                        style={{ display: "none" }}
                      >
                        <span></span>
                        <strong></strong>
                      </div>
                    </div>
                    {checkLeadTime !== "" ? (
                      <a
                        className="button order_nowdiv smiple_product_lk disbl_href_action"
                        href={void 0}
                      >
                        Choose
                      </a>
                    ) : productDetail.product_stock > 0 ||
                      productDetail.product_stock === null ? (
                      productDetail.product_type === "1" ? (
                        <a
                          className="button order_nowdiv smiple_product_lk disbl_href_action"
                          href="/"
                          onClick={this.addToCartSimple.bind(
                            this,
                            productDetail,
                            "initial"
                          )}
                        >
                          Add To cart
                        </a>
                      ) : (
                        <a
                          href="/"
                          onClick={this.viewProDetail.bind(this)}
                          title="Product Details"
                          id={comboProId}
                          className="button order_nowdiv compo_product_lk"
                        >
                          Choose
                        </a>
                      )
                    ) : (
                      <a
                        className="button order_nowdiv disabled disbl_href_action"
                        href="/"
                      >
                        Sold Out
                      </a>
                    )}
                    <div
                      className="addcart_row addcart_done_maindiv"
                      style={{ display: "none" }}
                    >
                      <div className="addcart-row-child">
                        <div className="qty_bx">
                          <span
                            className="qty_minus"
                            onClick={this.proQtyAction.bind(
                              this,
                              productDetail.product_primary_id,
                              "decr"
                            )}
                          >
                            -
                          </span>
                          <input
                            type="text"
                            className="proqty_input"
                            readOnly
                            defaultValue="1"
                            /* value="1" */
                          />
                          <input
                            type="hidden"
                            className="proextraprice_input"
                            /* defaultValue="0" */
                          />
                          <input
                            type="hidden"
                            className="updateproqty_input"
                            /* defaultValue="0" */
                          />
                          <input
                            type="hidden"
                            className="proprice_input"
                            defaultValue={productDetail.product_price}
                          />
                          <input
                            type="hidden"
                            className="prodetails_input"
                            value={
                              productDetail.product_primary_id +
                              "~" +
                              productName +
                              "~" +
                              productDetail.product_id +
                              "~" +
                              productDetail.product_sku +
                              "~" +
                              productDetail.product_thumbnail
                            }
                          />
                          <span
                            className="qty_plus"
                            onClick={this.proQtyAction.bind(
                              this,
                              productDetail.product_primary_id,
                              "incr"
                            )}
                          >
                            +
                          </span>
                        </div>
                        <button
                          className="btn btn_black"
                          onClick={this.addToCartSimple.bind(
                            this,
                            productDetail,
                            "done"
                          )}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                    {productDetail.product_type !== "1" &&
                      this.state.productDetailsList.length > 0 &&
                      this.state.productDetailsList[indexl] !== "" &&
                      typeof this.state.productDetailsList[indexl] !==
                        undefined &&
                      typeof this.state.productDetailsList[indexl] !==
                        "undefined" && (
                        <>
                          <Mealplanproductdetail
                            proValue={productDetail.product_slug}
                            productDetails={
                              this.state.productDetailsList[indexl]
                            }
                            dayindex={this.state.dayindex}
                          />
                        </>
                      )}
                  </div>
                </div>
              </div>
              <div className="deselect-product">
                <a
                  href={void 0}
                  onClick={this.removeThisProduct.bind(
                    this,
                    this.state.dayindex,
                    productDetail.product_primary_id
                  )}
                >
                  X
                </a>
              </div>
            </li>
          );
        }
      });

      return listProduct;
    }
  }
  removeThisProduct(dayindex, product_primary_id) {
    $("#" + dayindex + "_proIndex-" + product_primary_id).removeClass("active");
    if (
      $(
        "#" +
          dayindex +
          "_proIndex-" +
          product_primary_id +
          " .smiple_product_lk"
      ).length > 0
    ) {
      $(
        "#" +
          dayindex +
          "_proIndex-" +
          product_primary_id +
          " .smiple_product_lk"
      ).html("Add To cart");
    }
    if (
      $(
        "#" +
          dayindex +
          "_proIndex-" +
          product_primary_id +
          " .compo_product_lk"
      ).length > 0
    ) {
      $(
        "#" +
          dayindex +
          "_proIndex-" +
          product_primary_id +
          " .compo_product_lk"
      ).html("Choose");
    }
    $(
      "#" +
        dayindex +
        "_proIndex-" +
        product_primary_id +
        " .meal-plane-combo-addt-price"
    ).hide();
    $(
      "#" +
        dayindex +
        "_proIndex-" +
        product_primary_id +
        " .meal-plane-combo-addt-price span"
    ).html("");
    $(
      "#" +
        dayindex +
        "_proIndex-" +
        product_primary_id +
        " .meal-plane-combo-addt-price strong"
    ).html("");
  }

  checkLeadTime(product_lead_time) {
    if (
      product_lead_time !== "" &&
      parseInt(product_lead_time) > 0 &&
      cookie.load("orderDateTime") !== "" &&
      typeof cookie.load("orderDateTime") !== undefined &&
      typeof cookie.load("orderDateTime") !== "undefined"
    ) {
      var addedData = new Date(
        new Date().getTime() + product_lead_time * 24 * 60 * 60 * 1000
      );
      var orderDateTime = new Date(
        cookie.load("orderDateTime").split('"').join("")
      );
      var selectedDate =
        orderDateTime.getDate() +
        "-" +
        orderDateTime.getMonth() +
        "-" +
        orderDateTime.getFullYear();
      var leadDate =
        addedData.getDate() +
        "-" +
        addedData.getMonth() +
        "-" +
        addedData.getFullYear();
      if (selectedDate !== leadDate) {
        /*    var date1 = new Date('7/11/2010');
          var date2 = new Date('12/12/2010'); */
        var diffDays = addedData.getDate() - orderDateTime.getDate();
        var result = "";
        var avail = "";
        if (cookie.load("defaultAvilablityId") === deliveryId) {
          avail = "Delivery";
        } else {
          avail = "Pickup";
        }
        if (diffDays === 1) {
          // result = "This item is available for next day " + avail + " only";
        } else {
          /*  result =
            "This item is available for " + leadDate + " " + avail + " only"; */
        }
        return result;
      }
    } else {
      return "";
    }
  }

  checkProdAvil(productDetail) {
    var selectedDate = this.props.selectedDate;
    var dayindexID = this.props.dayindexID;
    if (selectedDate !== "") {
      var dateNew = new Date(selectedDate);
      var resultDate = dateNew.setDate(dateNew.getDate() + dayindexID);
      var productdate = format(resultDate, "EEEE").toLocaleLowerCase();
      var avilablePro = 0;
      if (productdate === "monday") {
        if (productDetail.product_day_monday_check === "1") {
          avilablePro = 1;
        }
      }
      if (productdate === "tuesday") {
        if (productDetail.product_day_tuesday_check === "1") {
          avilablePro = 1;
        }
      }
      if (productdate === "wednesday") {
        if (productDetail.product_day_wednesday_check === "1") {
          avilablePro = 1;
        }
      }
      if (productdate === "thursday") {
        if (productDetail.product_day_thursday_check === "1") {
          avilablePro = 1;
        }
      }
      if (productdate === "friday") {
        if (productDetail.product_day_friday_check === "1") {
          avilablePro = 1;
        }
      }
      return avilablePro;
    } else {
      return 1;
    }
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#" + this.state.dayindexID + "proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#" + this.state.dayindexID + "proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var IndexFlg = productDetail.product_primary_id;
    if (actionFlg === "initial") {
      $("#" + this.state.dayindexID + "proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#" + this.state.dayindexID + "proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();

      return false;
    } else {
      $("#" + this.state.dayindexID + "proIndex-" + IndexFlg).addClass(
        "active"
      );
      $("#" + this.state.dayindexID + "proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .hide();
      $("#" + this.state.dayindexID + "proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .show();

      var selectedQty = $(
        "#" + this.state.dayindexID + "proIndex-" + IndexFlg + " .proqty_input"
      ).val();

      $(
        "#" +
          this.state.dayindexID +
          "proIndex-" +
          IndexFlg +
          " .meal-plane-combo-addt-price"
      ).show();
      $(
        "#" +
          this.state.dayindexID +
          "proIndex-" +
          IndexFlg +
          " .meal-plane-combo-addt-price span"
      ).html("");
      $(
        "#" +
          this.state.dayindexID +
          "proIndex-" +
          IndexFlg +
          " .meal-plane-combo-addt-price strong"
      ).html("X" + selectedQty);

      $(
        "#" +
          this.state.dayindexID +
          "proIndex-" +
          IndexFlg +
          " .updateproqty_input"
      ).val(selectedQty);

      $(
        "#" + this.state.dayindexID + "proIndex-" + IndexFlg + " .order_nowdiv"
      ).html("Selected");
    }
  }

  viewProDetail(event) {
    event.preventDefault();
    $("body").addClass("meal-plan-popup");

    var indexID = this.state.dayindex;
    $("#ProductDetailMd" + indexID).modal({
      backdrop: "static",
      keyboard: false,
    });
  }

  render() {
    return (
      <div
        className="productlist-main-div"
        id={"productlistall_" + this.props.dayindex}
        style={{
          display: this.props.dayindex === this.props.currnetDay ? "" : "none",
        }}
      >
        <div className="innerproduct_row">
          {this.state.productList.length > 0 && this.productsubcatlist()}
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
