/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import update from "immutability-helper";
import axios from "axios";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
var Parser = require("html-react-parser");
import {
  GET_GLOBAL_SETTINGS,
  GET_LOGINDATA,
  GET_FORGET_PASSWORD,
  GET_REGISTRATION,
} from "../../actions";
import {
  appId,
  apiUrl,
  apiUrlV2,
  defaultOutlet,
  mealplanId,
  currencySymbol,
  proFolderUrl,
} from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  getReferenceID,
} from "../Helpers/SettingHelper";
import whitetick from "../../common/images/white-tick.svg";
import greytick from "../../common/images/grey-tick.svg";
import tickImage from "../../common/images/tick_popup.png";
import {
  Login,
  Forgotpassword,
  Signup,
} from "../../components/Myaccount/Index";
import register from "../../common/images/register-bg.jpg";

import { format } from "date-fns";
var qs = require("qs");
class Pages extends Component {
  constructor(props) {
    super(props);
    var userLoginType = "login";
    console.log(this.props, "this.props");
    if (this.props.match.path === "/login") {
      userLoginType = "login";
    } else if (this.props.match.path === "/sign-in") {
      userLoginType = "register";
    } else if (this.props.match.path === "/forgot-password") {
      userLoginType = "forgot";
    }
    this.state = {
      userID:
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== undefined &&
        typeof cookie.load("UserId") !== "undefined"
          ? cookie.load("UserId")
          : "",

      fpstatus: "initiating",
      regstatus: "initiating",
      fields: {
        email: "",
        password: "",
      },
      fieldssignup: {
        firstname: "",
        email: "",
        password: "",
        rePassword: "",
        mobile: "",
        pdpa_consent: "",
        terms: "",
        completeReg: "N",
      },
      fieldsfgtpassword: {
        email: "",
      },
      pagepath: this.props.match.path,
      userLoginType: userLoginType,
      currnetPriod: "L",
    };
    $.magnificPopup.close();
    $("body").removeClass("cart-items-open");
    this.handleTextChange = this.handleTextChange.bind(this);
    this.props.getSettings();
  }

  componentWillReceiveProps(PropsDt) {
    if (this.state.pagepath !== PropsDt.match.path) {
      var userLoginType = "login";
      console.log(this.props, "this.props");
      if (PropsDt.match.path === "/login") {
        userLoginType = "login";
      } else if (PropsDt.match.path === "/sign-in") {
        userLoginType = "register";
      } else if (PropsDt.match.path === "/forgot-password") {
        userLoginType = "forgot";
      }
      console.log(userLoginType, "userLoginTypeuserLoginType");
      this.setState(
        {
          userLoginType: userLoginType,
          pagepath: PropsDt.match.path,
        },
        function () {
          setTimeout(function () {
            $("html, body").animate(
              {
                scrollTop: $(".login_main_section").offset().top - 130,
              },
              500
            );
          }, 100);
        }
      );
    }

    if (this.state.fbstatus === "loading") {
      if (PropsDt.fblogin !== undefined) {
        if (PropsDt.fblogin.length > 0) {
          this.setState({ fbstatus: "ok" }, function () {
            this.doLogin(PropsDt.fblogin[0]);
          });
        }
      }
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        var formPay_load = this.state.fieldssignup;
        this.setState({
          regstatus: "ok",
          enableOtp: false,
          fieldssignup: {
            firstname: "",
            email: "",
            password: "",
            rePassword: "",
            mobile: "",
            pdpa_consent: "",
            terms: "",
            completeReg: "Y",
          },
        });
        hideLoader("otpverify_submit", "class");
        this.showMessage(PropsDt.registration[0]);
      }
    }
  }

  componentDidMount() {
    $(".dvLoadrCls").hide();
  }

  handleTextChange(event) {
    var name = event.target.name;
    if (name === "about_us") {
      var aboutus = this.state.about_us.length > 0 ? this.state.about_us : [];
      var index = aboutus.indexOf(event.target.value);
      if (index > -1) {
        aboutus.splice(index, 1);
      } else {
        aboutus.push(event.target.value);
      }
      this.setState({ [name]: aboutus });
    } else if (name === "contactno") {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ [name]: event.target.value, ["error_" + name]: false });
      }
    } else if (name === "terms") {
      this.setState({ frmterms: !this.state.frmterms, error_terms: false });
    } else {
      this.setState({ [name]: event.target.value, ["error_" + name]: false });
    }
  }
  selectGoal(goal) {
    this.setState({ selectedGoal: goal, currentStep: 2 }, function () {
      $("html, body").animate(
        {
          scrollTop: $(".progressbar").offset().top - 130,
        },
        500
      );
    });
  }

  handlegoLink = (value) => {
    this.setState({ userLoginType: value });
    //console.log(value, "value");
  };

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      email_address: formPayload.email,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      login_username: formPayload.email,
      login_password: formPayload.password,
    };
    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }
    console.log(value, "valuevaluevalue");
    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });
    var pdpaConsent = formPayload.pdpa_consent === true ? "yes" : "no";

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.firstname,
      customer_email: formPayload.email,
      customer_password: formPayload.password,
      customer_phone: formPayload.mobile,
      customer_pdpa_consent: pdpaConsent,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };
  /* for signup - end*/

  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }
      cookie.save("UserId", fbloginData.result_set.customer_id, { path: "/" });
      cookie.save("UserEmail", fbloginData.result_set.customer_email, {
        path: "/",
      });
      cookie.save(
        "UserFname",
        fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "",
        { path: "/" }
      );
      cookie.save(
        "UserLname",
        fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "",
        { path: "/" }
      );
      cookie.save("UserMobile", mobileno, { path: "/" });
      cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
      cookie.save(
        "UserDefaultAddress",
        fbloginData.result_set.customer_address_name,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitOne",
        fbloginData.result_set.customer_address_line1,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitTwo",
        fbloginData.result_set.customer_address_line2,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultPostalCode",
        fbloginData.result_set.customer_postal_code,
        { path: "/" }
      );
      const { history } = this.props;
      var qs = require("qs");
      var postObject = {
        app_id: appId,
        reference_id: getReferenceID(),
        customer_id: fbloginData.result_set.customer_id,
        availability_id: cookie.load("defaultAvilablityId"),
      };

      axios
        .post(apiUrl + "cart/update_customer_info", qs.stringify(postObject))
        .then((res) => {
          showAlert("Success", "Logged in Successfully!");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          if (res.data.status === "ok") {
            if (cookie.load("loginpopupTrigger") === "fromcheckout") {
              cookie.remove("loginpopupTrigger", { path: "/" });
              history.push("/checkout");
            } else {
              history.push("/myaccount");
            }
          } else {
            history.push("/myaccount");
          }
        });
    } else {
      cookie.remove("loginpopupTrigger", { path: "/" });
      showAlert("Error", fbloginData.message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  showMessage(response) {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    if (response.status === "ok") {
      showAlert("Success", response.message);
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}
        <div className="login_main_section">
          {this.state.userLoginType === "login" && (
            <div className="login-page contact-box">
              <div className="contact-box-lhs ">
                <img className="img-single" src={register} alt="" />
              </div>
              <div className="contact-box-rhs">
                <h2>Member Login</h2>
                <Login
                  fields={this.state.fields}
                  onChange={this.fieldChange}
                  onValid={this.handleSignin}
                  pagetype="other"
                  onGoLink={this.handlegoLink}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          )}
          {this.state.userLoginType === "register" && (
            <div className="register-page contact-box">
              <div className="contact-box-lhs ">
                <img className="img-single" src={register} alt="" />
              </div>
              <div className="contact-box-rhs">
                <h2>Register</h2>
                <Signup
                  fields={this.state.fieldssignup}
                  onChange={this.fieldChangeSignup}
                  onValid={this.handleSignup}
                  pagetype="other"
                  onGoLink={this.handlegoLink}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          )}
          {this.state.userLoginType === "forgot" && (
            <div className="forgot-page contact-box">
              <div className="contact-box-lhs ">
                <img className="img-single" src={register} alt="" />
              </div>
              <div className="contact-box-rhs">
                <h2>Forgot Password</h2>
                <Forgotpassword
                  fields={this.state.fieldsfgtpassword}
                  onChange={this.fieldforgot}
                  onValid={this.forgotpassword}
                  pagetype="other"
                  onGoLink={this.handlegoLink}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          )}
        </div>
        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var pagedataRst = Array();
  if (Object.keys(state.pagedata).length > 0) {
    if (state.pagedata[0].status === "ok") {
      pagedataRst = state.pagedata[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    logindata: state.login,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
  };
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
