/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import { profileimg } from "../../../common/images/user-profile.png";

import { stripslashes } from "../../Helpers/SettingHelper";
import { GET_TESTIMONIAL } from "../../../actions";

var settingsTestimonial = {
  items: 3,
  center: true,
  autoplay: false,
  margin: 30,
  loop: true,
  nav: false,
  dots: true,
  responsive: {
    0: {
      items: 1,
    },
    780: {
      items: 2,
    },
    1280: {
      items: 3,
    },
  },
};
class Testimonial extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getBlogList();
  }
  render() {
    let testimonialArr = this.props.testimonial;
    let testimoniallist = [];
    let testimonialcommon = [];

    if (Object.keys(testimonialArr).length > 0) {
      if (testimonialArr[0].status === "ok") {
        testimoniallist = testimonialArr[0].result_set;
        testimonialcommon = testimonialArr[0].common;
      }
    }
    return (
      <>
        {Object.keys(testimoniallist).length > 0 && (
          <div className="client_riv">
            <div className="container">
              <div className="fproducts-title-img">
                <h1>What Our Client Says</h1>
              </div>
              <div className="client_riv_slider">
                <OwlCarousel options={settingsTestimonial}>
                  {testimoniallist.map((item, index) => {
                    return (
                      <div className="client_riv_item" key={index}>
                        {item.testimonial_description !== "" &&
                          item.testimonial_description !== null && (
                            <p>{stripslashes(item.testimonial_description)}</p>
                          )}
                        <div className="client_riv_slider-author">
                          <div className="testimonial-image">
                            {item.testimonial_image !== "" &&
                            item.testimonial_image !== null ? (
                              <img
                                src={
                                  testimonialcommon.image_source +
                                  item.testimonial_image
                                }
                                alt=""
                              />
                            ) : (
                              <img src={profileimg} />
                            )}
                          </div>
                          <h5>{stripslashes(item.testimonial_tittle)}</h5>

                          {item.testimonial_destination !== "" && (
                            <p className="client_riv_stitle">
                              {stripslashes(item.testimonial_destination)}
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    testimonial: state.testimonial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBlogList: () => {
      dispatch({ type: GET_TESTIMONIAL });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Testimonial);
