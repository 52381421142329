/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_MEAL_PLAN_ORDER_DETAIL,
  SET_MEAL_PLAN_ORDER_DETAIL,
} from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetMealPlanOrderDetail = function* () {
  yield takeEvery(GET_MEAL_PLAN_ORDER_DETAIL, workerGetMealPlanOrderDetail);
};

function* workerGetMealPlanOrderDetail({ orderId }) {
  try {
    const uri =
      apiUrl +
      "mealplan/order_history?app_id=" +
      appId +
      "&local_order_no=" +
      orderId +
      "&customer_id=" +
      cookie.load("UserId");
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_MEAL_PLAN_ORDER_DETAIL, value: resultArr });
  } catch {
    console.log("Get Order Detail Failed");
  }
}
