import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import ProductDetail from "./components/Products/ProductDetail";
import FavouriteProducts from "./components/Products/FavouriteProducts";
import Mealplan from "./components/Products/Mealplan";
import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Mealplansthankyou from "./components/Checkout/Mealplansthankyou";

import Ourstory from "./components/Pages/Ourstory";
import Pages from "./components/Pages/Pages";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";
import Faq from "./components/Pages/Faq";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Myvoucher from "./components/Myaccount/Myvoucher";
import Rewards from "./components/Myaccount/Rewards";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Logout from "./components/Myaccount/Logout";
import Refpage from "./components/Layout/Refpage";
import Loginregister from "./components/Pages/Loginregister";
import Page404 from "./Page404";

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/sign-in" component={Loginregister} />
        <Route exact path="/login" component={Loginregister} />
        <Route exact path="/forgot-password" component={Loginregister} />

        <Route
          path={"/our-menu/:slugType/:slugValue/:proValue"}
          component={ProductDetail}
        />
        <Route path={"/our-menu/:slugType/:slugValue"} component={Products} />
        <Route path="/our-menu" component={Products} />

        <Route path="/favourite" component={FavouriteProducts} />

        <Route
          path="/meal-plans/thankyou/:orderId"
          component={Mealplansthankyou}
        />
        <Route path="/meal-plans" component={Mealplan} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/our-story" component={Ourstory} />
        <Route path="/about-us" component={Pages} />
        <Route path="/gallery" component={Pages} />
        <Route path="/our-team" component={Pages} />
        <Route path="/terms-conditions" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/faq" component={Faq} />
        <Route path="/locations" component={Outlets} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/voucher" component={Myvoucher} />
        <Route path="/myorders/meal-plan" component={Orders} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/logout" component={Logout} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
