/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
var Parser = require("html-react-parser");
import { connect } from "react-redux";

import { GET_GLOBAL_SETTINGS, GET_REQUESTPAGEDATA } from "../../actions";
import Testimonial from "../Home/HomeSubCompo/Testimonial";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import out1 from "../../common/images/ourstory-top1.jpg";
import out2 from "../../common/images/ourstory-top2.jpg";
import oub from "../../common/images/ourstory-bottom.jpg";
import wmd1 from "../../common/images/wmd1.svg";
import wmd2 from "../../common/images/wmd2.svg";
import wmd3 from "../../common/images/wmd3.svg";
import wmd4 from "../../common/images/wmd4.svg";
import wmd5 from "../../common/images/wmd5.svg";
import wmd6 from "../../common/images/wmd6.svg";
import beforeImg from "../../common/images/before.jpg";
import afterImg from "../../common/images/after.jpg";

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = { pagedata: [], pagedetail: "", pagetitle_txt: "" };
    if (
      this.props.match.params !== "" &&
      this.props.match.params.page_slug !== undefined
    ) {
      var page_slug = this.props.match.params.page_slug;
    } else {
      var page_slug = this.props.match.url.replace(/\\|\//g, "");
    }
    this.props.getSettings();
    this.props.getRequestpage(page_slug);
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".test-popup-link").magnificPopup({ type: "image" });
    }, 2000);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.path !== this.props.match.path) {
      if ($(".trigger_menu").length > 0) {
        $(".trigger_menu").toggleClass("active");
        if ($(".hmenu_list").hasClass("open")) {
          $(".mega_menu").slideUp();
        }
        $(".hmenu_list").toggleClass("open");
      }
      var pageslug = nextProps.match.path.replace(/\\|\//g, "");
      this.props.getRequestpage(pageslug);
    }

    if (nextProps.pagedata !== this.state.pagedata) {
      $(".dvLoadrCls").fadeOut(500);
      var pageDetails = "";
      var pageTitleTxt = "";
      if (Object.keys(nextProps.pagedata).length > 0) {
        var pagedataTxt = nextProps.pagedata[0].cmspage_description;
        pageTitleTxt = nextProps.pagedata[0].cmspage_title;
        pageDetails = pagedataTxt !== "" ? Parser(pagedataTxt) : "";
      }

      this.setState({
        pagedata: nextProps.pagedata,
        pagedetail: pageDetails,
        pagetitle_txt: pageTitleTxt,
      });
    }
  }

  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}
        <div className="common-inner-blckdiv">
          <div className="story-about">
            <div className="container">
              <div className="story-about-lhs">
                <div className="sa-top">
                  <img src={out1} alt="" />
                  <img src={out2} alt="" />
                </div>
                <div className="sa-btm">
                  <img src={oub} alt="" />
                </div>
              </div>
              <div className="story-about-rhs">
                <h1>
                  We are a provider of daily meal plans that are nutritious,
                  palatable, calorie-controlled and well balanced.
                </h1>
                <p>
                  More importantly, our foods are personalised to suit the needs
                  of each individual. Our lunch and dinner meals are cooked and
                  delivered fresh daily to your doorstep. We make clean eating
                  easy and sustainable... so you can just relax and make the
                  most of your day.
                </p>
              </div>
            </div>
          </div>
          <div className="made-easy story-need">
            <div className="container">
              <h1>What makes us different?</h1>
              <ul>
                <li>
                  <figure>
                    <img src={wmd1} alt="" />
                  </figure>
                  <div className="me-content">
                    <h5>Organic & Fresh</h5>
                    <p>Our meals are made with real foods, all natural.</p>
                  </div>
                </li>
                <li>
                  <figure>
                    <img src={wmd2} alt="" />
                  </figure>
                  <div className="me-content">
                    <h5>No MSG, Trans Fat</h5>
                    <p>
                      We are free from the nasties such as MSG, trans fat, or
                      other additives.
                    </p>
                  </div>
                </li>
                <li>
                  <figure>
                    <img src={wmd3} alt="" />
                  </figure>
                  <div className="me-content">
                    <h5>Nutritious</h5>
                    <p>
                      FMK meals are all natural, high in protein and fibre, but
                      low in carbs and fat.
                    </p>
                  </div>
                </li>
                <li>
                  <figure>
                    <img src={wmd4} alt="" />
                  </figure>
                  <div className="me-content">
                    <h5>Calorie-controlled</h5>
                    <p>
                      The carbs we use are complex carbs that are low in GI.
                    </p>
                  </div>
                </li>
                <li>
                  <figure>
                    <img src={wmd5} alt="" />
                  </figure>
                  <div className="me-content">
                    <h5>Ensure maximum taste</h5>
                    <p>
                      We use only the highest quality ingredients and
                      seasonings, such as Himalayan Pink Salt and Lakanto Sugar.
                    </p>
                  </div>
                </li>
                <li>
                  <figure>
                    <img src={wmd6} alt="" />
                  </figure>
                  <div className="me-content">
                    <h5>Convenient</h5>
                    <p>
                      Prepared fresh daily for people wishing to lead a healthy
                      life, so you can just relax!
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="story-ba">
            <div className="container">
              <div className="story-ba-lhs">
                <h1>Who's the Chef?</h1>
                <p>
                  Introducing the Fit Mum herself... Mei Mei. Originally from
                  Northern Thailand, she has now been living in Singapore for
                  the past decade. She loves food. After giving birth to two
                  lovely girls, she embarked on a steep weight loss journey.
                  <br />
                  With a few experiments, she discovered that she can enjoy food
                  and still shed kilos. The secret? A combination of clean
                  eating and a balanced, active lifestyle.
                </p>
              </div>
              <div className="story-ba-rhs">
                <div className="story-ba-inner">
                  <img src={beforeImg} alt="" />
                  <span>Before</span>
                </div>
                <div className="story-ba-inner">
                  <img src={afterImg} alt="" />
                  <span>After</span>
                </div>
              </div>
            </div>
          </div>

          <div className="moreabt story-abt">
            <div className="container">
              <div className="moreabt-content">
                <h2>Who needs us?</h2>
                <p>
                  People just like Mei Mei who love to eat a variety of
                  cuisines, and those who are on a weight management program. Be
                  it for weight loss or weight gain, Fit Mum Kitchen is the
                  solution to those who wish to make better use of their time.
                  We understand that your time is precious!
                </p>
                <a href="#" className="button">
                  Start Your Journey Now!
                </a>
              </div>
            </div>
          </div>
          <Testimonial />
        </div>

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var pagedataRst = Array();
  if (Object.keys(state.pagedata).length > 0) {
    if (state.pagedata[0].status === "ok") {
      pagedataRst = state.pagedata[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    pagedata: pagedataRst,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getRequestpage: (slug) => {
      dispatch({ type: GET_REQUESTPAGEDATA, slug });
    },
  };
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
