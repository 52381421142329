/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";

import instagram from "../../common/images/instagram.svg";
import facebook from "../../common/images/fb.svg";
import paymethod from "../../common/images/pay-method.svg";
import arrowtop from "../../common/images/arrow-top.png";

import { GET_GLOBAL_SETTINGS } from "../../actions";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "", globalsettings: [] };
    this.props.getGlobalSettings();
  }

  componentDidMount() {
    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
    if (this.state.globalsettings !== PropsData.globalsettings) {
      if (PropsData.globalsettings.length > 0) {
        this.setState({
          globalsettings: PropsData.globalsettings[0].result_set,
        });
      }
    }
  }
  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="container">
          {this.state.footerlink}
          <div className="footer-payment-info">
            <h5>Supported Payment Methods</h5>
            <img src={paymethod} alt="payment" />
          </div>
        </div>

        <div className="mobile-footer-nav">
          <Accordion>
            <AccordionItem title="Keep Fit With Us">
              <ul>
                <li>
                  <a href="/our-menu">Our Menu</a>
                </li>
                <li>
                  <a href="/meal-plans">Meal Plans</a>
                </li>
              </ul>
            </AccordionItem>
            <AccordionItem title="Fit Mum Kitchen">
              <ul>
                <li>
                  <a href="/our-story">Our Story</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
                <li>
                  <a href="/terms-conditions">Terms & Conditions</a>
                </li>
              </ul>
            </AccordionItem>
            <AccordionItem title="Stay Connected">
              <ul>
                <a
                  href="https://www.facebook.com/fitmumkitchen/"
                  target="_blank"
                >
                  <img src={facebook} className="facebook" alt="facebook" />
                </a>{" "}
                <a
                  href="https://www.instagram.com/fitmumkitchen/"
                  target="_blank"
                >
                  <img src={instagram} className="instagram" alt="instagram" />
                </a>{" "}
              </ul>
            </AccordionItem>
          </Accordion>
        </div>
        <div className="footer-payment-info mob-fpi">
          <h5>Supported Payment Methods</h5>
          <img src={paymethod} alt="payment" />
        </div>
        <div className="copyright-section">
          <p>© {yearSp} Fit Mum Kitchen. All Rights Reserved.</p>
        </div>

        <div className="scrolltop" id="scrollbutton">
          <a href="/" className="disbl_href_action">
            <img src={arrowtop} alt="top" />
          </a>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
