/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import cookie from "react-cookies";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import { appId, apiUrlV2, deliveryId } from "../../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showPriceValueSub,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
} from "../../Helpers/SettingHelper";
import noimage from "../../../common/images/no-img-product.png";

import { GET_FEATUREPRO } from "../../../actions";
var qs = require("qs");
var Parser = require("html-react-parser");
var settingsfeature = {
  items: 3,
  autoplay: false,
  margin: 30,
  loop: true,
  nav: true,
  dots: false,
  responsive: {
    0: {
      items: 1,
    },
    780: {
      items: 2,
    },
    980: {
      items: 3,
    },
    1380: {
      items: 4,
    },
  },
};
class FeaturedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.props.getFeatureProList();
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndexF-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndexF-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        console.log(
          actionFlg,
          $("#proIndexF-" + IndexFlg).length,
          $("#proIndexF-" + IndexFlg).find(".smiple_product_lk").length,
          $("#proIndexF-" + IndexFlg).find(".addcart_done_maindiv").length,
          "actionFlgactionFlgactionFlg"
        );
        $("#proIndexF-" + IndexFlg).addClass("active");
        $("#proIndexF-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndexF-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
      } else {
        showLoader("proIndexF-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndexF-" + IndexFlg)
          .find(".proqty_input")
          .val();

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndexF-" + IndexFlg).removeClass("active");
          hideLoader("proIndexF-" + IndexFlg, "Idtext");
          $("#proIndexF-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndexF-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            return false;
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
          }
        });
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        this.props.history.push(
          "/our-menu/" +
            productDetail.pro_cate_slug +
            "/" +
            productDetail.pro_subcate_slug +
            "/" +
            productSlug
        );
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
    return false;
  }

  render() {
    let featureproArr = this.props.featureproduct;
    let featureprolist = [];
    let featureproimagesource = "";
    let tagimagesource = "";

    if (Object.keys(featureproArr).length > 0) {
      if (featureproArr[0].status === "ok") {
        featureprolist = featureproArr[0].result_set;
        featureproimagesource = featureproArr[0].common.image_source;
        tagimagesource = featureproArr[0].common.tag_image_source;
      }
    }
    var settingsProduct = {
      items: 4,
      autoplay: false,
      margin: 18,
      loop: true,
      nav: false,
      dots: true,
      responsive: {
        0: {
          items: 1,
        },
        520: {
          items: 2,
          margin: 10,
        },
        780: {
          items: 3,
          margin: 12,
        },
        1280: {
          items: 4,
        },
      },
    };

    return (
      <section className="featuredpro-section">
        {Object.keys(featureprolist).length > 0 ? (
          <div className="container">
            <div className="featured-products-main">
              <div className="products-list-ulmain">
                {
                  /* this.props.stayhomeblk !== "" && ( */
                  <div className="fproducts-title">
                    <h1>Our Menu</h1>
                  </div>
                  /* ) */
                }
                <OwlCarousel options={settingsfeature}>
                  {featureprolist.map((featurepro, index) => {
                    var prodivId = "proIndexF-" + featurepro.product_primary_id;
                    var comboProId = "comboPro-" + featurepro.product_slug;
                    return (
                      <div
                        className="products-single-li"
                        id={prodivId}
                        key={index}
                      >
                        <div className="products-image-div">
                          {featurepro.product_thumbnail !== "" ? (
                            <img
                              src={
                                featureproimagesource +
                                "/" +
                                featurepro.product_thumbnail
                              }
                            />
                          ) : (
                            <img src={noimage} />
                          )}
                        </div>

                        <div className="product-info-div">
                          <div className="product-title">
                            <h3>
                              {featurepro.product_alias !== ""
                                ? stripslashes(featurepro.product_alias)
                                : stripslashes(featurepro.product_name)}
                            </h3>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    featureproduct: state.featureproduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFeatureProList: () => {
      dispatch({ type: GET_FEATUREPRO });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(FeaturedProducts);
