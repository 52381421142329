/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import update from "immutability-helper";
import axios from "axios";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Mealplanproductlist from "./Mealplanproductlist";
var Parser = require("html-react-parser");
var base64 = require("base-64");
import {
  GET_GLOBAL_SETTINGS,
  GET_LOGINDATA,
  GET_FORGET_PASSWORD,
  GET_REGISTRATION,
} from "../../actions";
import {
  appId,
  apiUrl,
  apiUrlV2,
  defaultOutlet,
  mealplanId,
  currencySymbol,
  proFolderUrl,
  stripeImage,
  projectTitle,
  stripeCurrency,
  defaultOutletName,
  stripeReference,
  baseUrl,
  mediaUrl,
} from "../Helpers/Config";
import {
  showPrices,
  emailValidate,
  showLoader,
  hideLoader,
  showAlert,
} from "../Helpers/SettingHelper";
import whitetick from "../../common/images/white-tick.svg";
import greytick from "../../common/images/grey-tick.svg";
import loadingImage from "../../common/images/loading_popup.gif";
import tickImage from "../../common/images/tick_popup.png";
import paymentImage from "../../common/images/payment.jpg";
import paymentPayNowImage from "../../common/images/omise-paynow.png";
import cashImage from "../../common/images/cash-on-delivery.png";
import OrderAdvancedDatetimeSlot from "../Layout/OrderAdvancedDatetimeSlot";
import {
  Login,
  Forgotpassword,
  Signup,
} from "../../components/Myaccount/Index";
import register from "../../common/images/register-bg.jpg";
var startTimePay;
import { format } from "date-fns";
var qs = require("qs");
class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      completeStep: 1,
      currnetDay: 0,
      openProducts: false,
      selectedGoal: "",
      plan_id: "",
      planname: "",
      planprice: 0,
      periode: "",
      meal_plan_week: "",
      startWeek: 0,
      userID:
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== undefined &&
        typeof cookie.load("UserId") !== "undefined"
          ? cookie.load("UserId")
          : "",
      first_name:
        cookie.load("UserFname") !== "" &&
        typeof cookie.load("UserFname") !== undefined &&
        typeof cookie.load("UserFname") !== "undefined"
          ? cookie.load("UserFname")
          : "",
      last_name:
        cookie.load("UserLname") !== "" &&
        typeof cookie.load("UserLname") !== undefined &&
        typeof cookie.load("UserLname") !== "undefined"
          ? cookie.load("UserLname")
          : "",
      email:
        cookie.load("UserEmail") !== "" &&
        typeof cookie.load("UserEmail") !== undefined &&
        typeof cookie.load("UserEmail") !== "undefined"
          ? cookie.load("UserEmail")
          : "",
      contactno:
        cookie.load("UserMobile") !== "" &&
        typeof cookie.load("UserMobile") !== undefined &&
        typeof cookie.load("UserMobile") !== "undefined"
          ? cookie.load("UserMobile")
          : "",
      address: "",
      postal_code: "",
      unitnumber1: "",
      unitnumber2: "",
      error_first_name: false,
      error_last_name: false,
      error_email: false,
      error_email_invalid: false,
      error_contactno: false,
      error_address: false,
      error_receive_news: false,
      error_terms: false,
      error_postal_code: false,
      error_unitnumber1: false,
      error_other_about: false,
      about_other: "",
      receive_news: "",
      didyouhear: [],
      about_us_other: "",
      frmterms: false,
      mealPlan: [],
      /* For Advanced Slot Start */
      seleted_ord_date: "",
      seleted_ord_time: "",
      seleted_ord_slot: "",
      seleted_ord_slotTxt: "",
      seleted_ord_slot_str: "",
      seleted_ord_slot_end: "",
      isAdvanced: "no",
      slotType: "0",
      /* For Advanced Slot End */
      getDateTimeFlg: "",
      seletedOutletId: "",
      order_tat_time: 0,
      seletedAvilablityId: "",
      currentPlanDate: new Date(),
      mealPlane: "Y",
      productList: [],
      imagesource: [],
      productDetailsList: [],
      subTotal: 0,
      deliveryCharge: 0,
      fpstatus: "initiating",
      regstatus: "initiating",
      fields: {
        email: "",
        password: "",
      },
      fieldssignup: {
        firstname: "",
        email: "",
        password: "",
        rePassword: "",
        mobile: "",
        pdpa_consent: "",
        terms: "",
        completeReg: "N",
      },
      fieldsfgtpassword: {
        email: "",
      },
      userLoginType: "",
      currnetPriod: "L",
      validateimage: loadingImage,
      placingorderimage: loadingImage,
      completingpaymentimage: loadingImage,
      omise_payment_enable: 0,
      omise_log_id: "",
      omisecodeerror: "",
      omiseyearerror: "",
      omisemontherror: "",
      omisecardrror: "",
      omisenameerror: "",
      holdername: "",
      cardNumber: "",
      expiration_month: "",
      expiration_year: "",
      security_code: "",
      cardImage: "",
      payment_ref_id: "",
      omise_tokken_response: "",
      omise_tokken_card_id: "",
      omise_tokken_id: "",
      updateCartResponse: [],
      globalSettings: [],
      paymentmodevalue: "Omise",
      omisePayNowResponse: [],
      drietary_requirement: [],
      recomented_food: "No",
      recomentedProduct: [],
      loadDates: "",
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.props.getSettings();
    this.loadMealPlan();
    this.getCateProductList();
    this.loadDates();
  }

  componentWillReceiveProps(PropsDt) {
    if (this.state.globalSettings !== PropsDt.globalsettings) {
      var tampStArr = PropsDt.globalsettings;
      var paymentmode = "Stripe";
      var codPayment = 0;
      var stripePayment = 0;
      var omisePayment = 0;
      var omisePaynowPayment = 0;
      if (Object.keys(tampStArr).length > 0) {
        var tampStArr = PropsDt.globalsettings;
        if (
          tampStArr.client_cod_enable == 1 &&
          tampStArr.client_cod_availability == 1
        ) {
          codPayment = 1;
          paymentmode = "Cash";
        }

        if (
          tampStArr.client_stripe_enable == 1 &&
          tampStArr.client_stripe_availability == 1
        ) {
          stripePayment = 1;
        }

        if (
          tampStArr.client_omise_enable == 1 /*  &&
          tampStArr.client_omise_availability == 1 */
        ) {
          omisePayment = 1;
          if (paymentmode !== "Cash") {
            paymentmode = "Omise";
          }
        }

        if (
          tampStArr.client_enable_omise_paynow == 1 /* &&
          tampStArr.client_omisepaynow_availability == 1 */
        ) {
          omisePaynowPayment = 1;
          if (paymentmode !== "Cash" && paymentmode !== "Omise") {
            paymentmode = "OmisePayNow";
          }
        }
      }
      this.setState({
        globalSettings: PropsDt.globalsettings,
        cod_payment_enable: codPayment,
        stripe_payment_enable: stripePayment,
        omise_payment_enable: omisePayment,
        paymentmodevalue: paymentmode,
        omisepaynow_payment_enable: omisePaynowPayment,
      });
    }

    if (this.state.fbstatus === "loading") {
      if (PropsDt.fblogin !== undefined) {
        if (PropsDt.fblogin.length > 0) {
          this.setState({ fbstatus: "ok" }, function () {
            this.doLogin(PropsDt.fblogin[0]);
          });
        }
      }
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        var formPay_load = this.state.fieldssignup;
        this.setState({
          regstatus: "ok",
          enableOtp: false,
          fieldssignup: {
            firstname: "",
            email: "",
            password: "",
            rePassword: "",
            mobile: "",
            pdpa_consent: "",
            terms: "",
            completeReg: "Y",
          },
        });
        hideLoader("otpverify_submit", "class");
        this.showMessage(PropsDt.registration[0]);
      }
    }
  }
  loadDates() {
    var loadDates = "";
    /*for (let index = 0; index < array.length; index++) {
      const element = array[index];
      
    } */
    this.getNextMonday(new Date());
    this.setState({ loadDates: loadDates });
  }
  getNextMonday(date = new Date()) {
    const dateCopy = new Date(date.getTime());

    const nextMonday = new Date(
      dateCopy.setDate(
        dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7)
      )
    );
    return nextMonday;
  }

  getCateProductList() {
    var current = this;
    axios
      .get(
        apiUrlV2 +
          "products/getAllProducts?app_id=" +
          appId +
          "&availability=" +
          mealplanId +
          "&category_slug=menu" +
          "&outletId=" +
          defaultOutlet +
          "&timeavailability=Yes"
      )
      .then(function (response) {
        if (response.data.status === "ok") {
          current.setState(
            {
              imagesource: response.data.common,
              productList: response.data.result_set,
            },
            function () {
              this.loadProductDetails();
            }
          );
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  loadProductDetails() {
    var productDetailsList = [];
    if (this.state.productList.length > 0) {
      var categoryProduct = this.state.productList[0].subcategorie[0].products;
      categoryProduct.map((item, index) => {
        if (item.product_type === "2") {
          axios
            .get(
              apiUrl +
                "products/products_list?app_id=" +
                appId +
                "&availability=" +
                mealplanId +
                "&product_slug=" +
                item.product_slug
            )
            .then(function (response) {
              if (response.data.status === "ok") {
                productDetailsList[index] = response.data.result_set;
              }
            });
        } else {
          productDetailsList[index] = [];
        }
      });
    }
    this.setState({ productDetailsList: productDetailsList });
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    document.body.appendChild(script);
    const script1 = document.createElement("script");
    script1.src = baseUrl + "js/bootstrap.min.js";
    script1.async = true;
    document.body.appendChild(script1);

    $(".dvLoadrCls").show();
  }
  loadMealPlan() {
    axios.get(apiUrl + "mealplan/getMealPlan?app_id=" + appId).then((plan) => {
      $(".dvLoadrCls").hide();

      /* Success time slot response */
      if (plan.data.status === "ok") {
        this.setState({ mealPlan: plan.data.result_set });
      } else {
      }
    });
  }
  handleChange(meal_plan_id, plan_price, type, event) {
    var checkedDetails = event.target.checked;
    $(".plan_list").each(function () {
      $(this)
        .find(".meal-footer li")
        .each(function () {
          $(this).find(".planpriode").prop("checked", false);
        });
    });
    $("#" + type + meal_plan_id).prop("checked", true);
    $("#plan_" + meal_plan_id + " .plan_price").html(
      currencySymbol + plan_price
    );
    $("#plan_" + meal_plan_id + " .planprice").val(plan_price);
    $(".meal_title").html("Per Meal");
    if (type === "lunch_and_dinner") {
      $("#plan_" + meal_plan_id + " .meal_title").html("Per Day");
    } else {
      $("#plan_" + meal_plan_id + " .meal_title").html("Per Meal");
    }
  }
  handleTextChange(event) {
    var name = event.target.name;

    if (name === "about_us") {
      var aboutus =
        this.state.didyouhear.length > 0 ? this.state.didyouhear : [];
      var index = aboutus.indexOf(event.target.value);
      if (index > -1) {
        aboutus.splice(index, 1);
      } else {
        aboutus.push(event.target.value);
      }
      this.setState({ didyouhear: aboutus });
    }
    if (name === "drietary_requirement") {
      var drietary_requirement =
        this.state.drietary_requirement.length > 0
          ? this.state.drietary_requirement
          : [];
      var index = drietary_requirement.indexOf(event.target.value);
      if (index > -1) {
        drietary_requirement.splice(index, 1);
      } else {
        drietary_requirement.push(event.target.value);
      }
      this.setState({ [name]: drietary_requirement });
    } else if (name === "contactno") {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ [name]: event.target.value, ["error_" + name]: false });
      }
    } else if (name === "terms") {
      this.setState({ frmterms: !this.state.frmterms, error_terms: false });
    } else if (name === "postal_code") {
      var postalCode = event.target.value;
      this.setState({ [name]: event.target.value, ["error_" + name]: false });
      if (postalCode.length === 6) {
        var current_This = this;
        axios
          .get(
            apiUrl +
              "settings/get_address?app_id=" +
              appId +
              "&zip_code=" +
              postalCode
          )
          .then(function (response) {
            if (response.data.status === "ok") {
              var addresDetails = response.data.result_set;
              current_This.setState({
                address: addresDetails.zip_buno + " " + addresDetails.zip_sname,
                ["error_" + name]: false,
              });
            } else {
              this.setState({ address: "", ["error_" + name]: true });
            }
          });
      }
    } else if (name === "recomented_food") {
      this.setState({ recomented_food: event.target.value });
    } else {
      this.setState({ [name]: event.target.value, ["error_" + name]: false });
    }
  }
  selectGoal(goal) {
    this.setState(
      { selectedGoal: goal, currentStep: 2, completeStep: 2 },
      function () {
        $("html, body").animate(
          {
            scrollTop: $(".progressbar").offset().top - 130,
          },
          500
        );
      }
    );
  }
  choosePlan(plan_id, meal_plan_week, planIndex) {
    console.log(plan_id, meal_plan_week, planIndex, "fdsfdsdfsfsfsfsf");
    var planname = $("#plan_" + plan_id + " .planname").val();
    var planprice = $("#plan_" + plan_id + " .planprice").val();
    var periode = $("#plan_" + plan_id + " .planpriode:checked").val();

    var PlanDetails = this.state.mealPlan[planIndex];
    var deliveryCharge = 0;
    if (periode === "Lunch") {
      deliveryCharge = PlanDetails.meal_plan_lunch_delivery_price;
    } else if (periode === "Dinner") {
      deliveryCharge = PlanDetails.meal_plan_dinner_delivery_price;
    } else if (periode === "Lunch & Dinner") {
      deliveryCharge = PlanDetails.meal_plan_lunch_dinner_delivery_price;
    }
    var totalNumberOfDays = meal_plan_week * 5;
    this.setState(
      {
        plan_id: plan_id,
        planname: planname,
        planprice: planprice,
        periode: periode,
        openProducts: true,
        meal_plan_week: meal_plan_week,
        deliveryCharge: deliveryCharge * totalNumberOfDays,
      },
      function () {
        this.setState({
          getDateTimeFlg: "yes",
          seletedOutletId: defaultOutlet,
          order_tat_time: 15,
          seletedAvilablityId: mealplanId,
        });
        $("html, body").animate(
          {
            scrollTop: $("#choose_plan_date").offset().top - 80,
          },
          500
        );
      }
    );
  }
  topgotoStep(step) {
    if (this.state.completeStep < step) {
      showAlert("Error", "Please complete step " + this.state.completeStep);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    } else {
      this.setState({ currentStep: step });
    }
  }
  goToRecomented() {
    if (this.state.userID === "") {
      this.setState({ userLoginType: "login" }, function () {
        setTimeout(function () {
          $("html, body").animate(
            {
              scrollTop: $(".login-page").offset().top - 130,
            },
            500
          );
        }, 100);
      });
    } else {
      var step = 3;
      var recomentedProduct = [];
      var additionalProPrice = 0;
      if (this.state.productList.length > 0) {
        this.state.productList.map((item) => {
          item.subcategorie.map((item1) => {
            item1.products.map((item2) => {
              if (item2.product_mealplan_recommended === "1") {
                recomentedProduct.push(item2);
                additionalProPrice += parseFloat(item2.product_price);
              }
            });
          });
        });
      }
      var dateList = [];
      for (let index = 0; index < this.state.meal_plan_week * 5; index++) {
        dateList.push(index);
      }
      var selectedDate = this.state.seleted_ord_date;
      var finalDataList = [];
      dateList.map((item) => {
        var dateNew = new Date(selectedDate);
        var resultDate = dateNew.setDate(dateNew.getDate() + item);
        if (this.state.periode === "Lunch & Dinner") {
          finalDataList.push(format(resultDate, "yyyy-MM-dd") + "_L");
          finalDataList.push(format(resultDate, "yyyy-MM-dd") + "_D");
        } else {
          var typeperiode = "";
          if (this.state.periode === "Lunch") {
            typeperiode = "L";
          } else if (this.state.periode === "Dinner") {
            typeperiode = "D";
          }

          finalDataList.push(
            format(resultDate, "yyyy-MM-dd") + "_" + typeperiode
          );
        }
      });
      var finaladditionalProPrice = 0;
      if (finalDataList.length > 0) {
        finalDataList.map((item) => {
          finaladditionalProPrice += parseFloat(additionalProPrice);
        });
      } else {
        finaladditionalProPrice = parseFloat(additionalProPrice);
      }
      this.setState(
        {
          subTotal: finaladditionalProPrice,
          currentStep: step,
          recomentedProduct: recomentedProduct,
        },
        function () {
          if (this.state.completeStep < step) {
            this.setState({ completeStep: step });
          }

          $("html, body").animate(
            {
              scrollTop: $(".progressbar").offset().top - 130,
            },
            500
          );
        }
      );

      /*  $(".product_list_parent").each(function () {
          $(this)
            .find(".products-list-ulmain li.active")
            .each(function () {
              var productPrice = $(this).find(".proprice_input").val();
              var proExtraPrice = $(this).find(".proextraprice_input").val();
              var proqty_input = $(this).find(".updateproqty_input").val();
              if (isNaN(parseFloat(proExtraPrice))) {
                proExtraPrice = 0;
              }
              if (parseFloat(productPrice) > 0) {
                var addPrice =
                  parseFloat(proExtraPrice) + parseFloat(productPrice);
                var multipliedQty = addPrice * proqty_input;
                additionalProPrice =
                  parseFloat(additionalProPrice) + parseFloat(multipliedQty);
              }
            });
        }); */
      /*  */
    }
  }
  gotoStep(step) {
    if (step === 3) {
      if (this.state.userID === "") {
        this.setState({ userLoginType: "login" }, function () {
          setTimeout(function () {
            $("html, body").animate(
              {
                scrollTop: $(".login-page").offset().top - 130,
              },
              500
            );
          }, 100);
        });
      } else {
        var additionalProPrice = 0;
        $(".product_list_parent").each(function () {
          $(this)
            .find(".products-list-ulmain li.active")
            .each(function () {
              var productPrice = $(this).find(".proprice_input").val();
              var proExtraPrice = $(this).find(".proextraprice_input").val();
              var proqty_input = $(this).find(".updateproqty_input").val();
              if (isNaN(parseFloat(proExtraPrice))) {
                proExtraPrice = 0;
              }
              if (parseFloat(productPrice) > 0) {
                var addPrice =
                  parseFloat(proExtraPrice) + parseFloat(productPrice);
                var multipliedQty = addPrice * proqty_input;
                additionalProPrice =
                  parseFloat(additionalProPrice) + parseFloat(multipliedQty);
              }
            });
        });
        this.setState(
          { subTotal: additionalProPrice, currentStep: step },
          function () {
            if (this.state.completeStep < step) {
              this.setState({ completeStep: step });
            }

            $("html, body").animate(
              {
                scrollTop: $(".progressbar").offset().top - 130,
              },
              500
            );
          }
        );
      }
    } else if (step === 4) {
      var error = 0;
      if (this.state.first_name === "") {
        error = 1;
        this.setState({ error_first_name: true });
      } else {
        this.setState({ error_first_name: false });
      }
      if (this.state.last_name === "") {
        error = 1;
        this.setState({ error_last_name: true });
      } else {
        this.setState({ error_last_name: false });
      }
      if (this.state.email === "") {
        error = 1;
        this.setState({ error_email: true, error_email_invalid: false });
      } else {
        var emailVal = emailValidate(this.state.email);
        if (emailVal === true) {
          this.setState({
            error_email_invalid: true,
            error_email_invalid: false,
          });
        } else {
          this.setState({ error_email: false, error_email_invalid: true });
        }
      }
      if (this.state.contactno === "") {
        error = 1;
        this.setState({ error_contactno: true });
      } else {
        this.setState({ error_contactno: false });
      }
      if (this.state.postal_code === "") {
        error = 1;
        this.setState({ error_postal_code: true });
      } else {
        this.setState({ error_postal_code: false });
      }
      if (this.state.address === "") {
        error = 1;
        this.setState({ error_address: true });
      } else {
        this.setState({ error_address: false });
      }
      if (this.state.unitnumber1 === "") {
        error = 1;
        this.setState({ error_unitnumber1: true });
      } else {
        this.setState({ error_unitnumber1: false });
      }
      if (this.state.frmterms === false) {
        error = 1;
        this.setState({ error_terms: true });
      } else {
        this.setState({ error_terms: false });
      }
      if (this.state.receive_news === "") {
        error = 1;
        this.setState({ error_receive_news: true });
      } else {
        this.setState({ error_receive_news: false });
      }

      if (this.state.didyouhear.indexOf("Others") >= 0) {
        if (this.state.about_other === "") {
          error = 1;
          this.setState({ error_other_about: true });
        } else {
          this.setState({ error_other_about: false });
        }
      }

      if (error === 0) {
        this.setState({ currentStep: step }, function () {
          if (this.state.completeStep < step) {
            this.setState({ completeStep: step });
          }

          $("html, body").animate(
            {
              scrollTop: $(".progressbar").offset().top - 130,
            },
            100
          );
        });
      } else {
        setTimeout(function () {
          if ($("#meal_plane_frm .error:visible:first").length > 0) {
            $("html, body").animate(
              {
                scrollTop:
                  $("#meal_plane_frm .error:visible:first").offset().top - 190,
              },
              100
            );
          }
        }, 300);
      }
    } else {
      this.setState({ currentStep: step }, function () {
        $("html, body").animate(
          {
            scrollTop: $(".progressbar").offset().top - 130,
          },
          500
        );
      });
    }
  }
  /* For Advanced Slot */
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        currentPlanDate: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
        openProducts: true,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  loadPlanDates() {
    var date_List = [];
    if (this.state.meal_plan_week !== "") {
      var startWeek = this.state.startWeek * 5;
      var endWeek = (parseInt(this.state.startWeek) + 1) * 5;
      for (let index = startWeek; index < endWeek; index++) {
        if (this.state.startWeek > 0) {
          date_List.push(parseInt(index) + parseInt(this.state.startWeek * 2));
        } else {
          date_List.push(index);
        }
      }
      var selectedDate = this.state.seleted_ord_date;
      if (selectedDate !== "") {
        return date_List.map((item, index) => {
          var dateNew = new Date(selectedDate);
          var resultDate = dateNew.setDate(dateNew.getDate() + item);
          var checkSelectDay = "";
          if (this.state.startWeek >= 1) {
            checkSelectDay = this.state.currnetDay - this.state.startWeek * 5;
          } else {
            if (this.state.currnetDay == index % 5) {
              checkSelectDay = index % 5;
            }
          }
          return (
            <li
              key={index}
              className={checkSelectDay === index ? "active" : ""}
              onClick={this.gotoDay.bind(this, index)}
            >
              <span>{format(resultDate, "EEEE")}</span>
              <span>{format(resultDate, "dd-MM-yyyy")}</span>
            </li>
          );
        });
      }
    }
  }

  loadProductList() {
    var date_List = [];
    /* * this.state.periode */
    if (this.state.meal_plan_week !== "") {
      for (let index = 0; index < this.state.meal_plan_week * 5; index++) {
        date_List.push(index);
      }
      return (
        <>
          <h3>
            Which option of{" "}
            {this.state.selectedGoal != ""
              ? this.state.selectedGoal.toUpperCase()
              : ""}{" "}
            would you like?
          </h3>
          {date_List.map((item, index) => {
            return (
              <div key={index} className="product_list_parent">
                {this.state.periode === "Lunch & Dinner" && (
                  <div
                    className="lunch-dinner-parent"
                    style={{
                      display: index === this.state.currnetDay ? "" : "none",
                    }}
                  >
                    <a
                      href={void 0}
                      onClick={this.selectmealType.bind(this, index, "L")}
                      className={
                        this.state.currnetPriod === "L"
                          ? "lunch-dinner-tab active"
                          : "lunch-dinner-tab "
                      }
                    >
                      Lunch
                    </a>
                    <a
                      href={void 0}
                      onClick={this.selectmealType.bind(this, index, "D")}
                      className={
                        this.state.currnetPriod === "D"
                          ? "lunch-dinner-tab active"
                          : "lunch-dinner-tab"
                      }
                    >
                      Dinner
                    </a>
                  </div>
                )}

                <Mealplanproductlist
                  dayindex={index}
                  dayindexID={index}
                  currnetDay={this.state.currnetDay}
                  productState={this.state}
                  selectedDate={this.state.seleted_ord_date}
                  selectedperiode={this.state.periode}
                />
                {this.state.periode === "Lunch & Dinner" && (
                  <Mealplanproductlist
                    dayindex={"d" + index}
                    dayindexID={index}
                    currnetDay={this.state.currnetDay}
                    productState={this.state}
                    selectedDate={this.state.seleted_ord_date}
                    selectedperiode={"Dinner"}
                  />
                )}
              </div>
            );
          })}
        </>
      );
    }
  }

  gotoNexDay(type) {
    var currnetDay = this.state.currnetDay;
    if (type === "n") {
      var errorValid = 1;
      if (
        $("#productlistall_" + currnetDay + " .products-single-li.active")
          .length > 0
      ) {
        $(
          "#productlistall_d" +
            currnetDay +
            ", " +
            "#productlistall_" +
            currnetDay
        ).hide();
        if (this.state.periode !== "Lunch & Dinner") {
          currnetDay = parseInt(currnetDay) + 1;
          var startWeek = this.state.startWeek;
          if (currnetDay % 5 === 0) {
            startWeek = currnetDay / 5;
          }

          this.setState(
            {
              currnetDay: currnetDay,
              currnetPriod: "L",
              startWeek: startWeek,
            },
            function () {
              setTimeout(function () {
                $("html, body").animate(
                  {
                    scrollTop: $(".mealbox-product").offset().top - 130,
                  },
                  500
                );
              }, 100);
            }
          );
        } else {
          this.setState({ currnetPriod: "D" });
        }
        errorValid = 0;
      }

      if (errorValid === 1) {
        var selected_Date = this.state.seleted_ord_date;
        var date_New = new Date(selected_Date);
        if (this.state.startWeek > 0) {
          currnetDay = parseInt(currnetDay) + this.state.startWeek * 2;
        }
        var resultDate = date_New.setDate(date_New.getDate() + currnetDay);
        if (this.state.periode === "Lunch & Dinner") {
          if (resultDate !== "") {
            showAlert(
              "Error",
              "Please make your selection for " +
                format(resultDate, "dd/MM/yyyy") +
                "(Lunch)"
            );
          } else {
            showAlert("Error", "Please Select any one lunch meal");
          }
        } else {
          if (resultDate !== "") {
            showAlert(
              "Error",
              "Please make your selection for " +
                format(resultDate, "dd/MM/yyyy")
            );
          } else {
            showAlert("Error", "Please Select any one meal");
          }
        }
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        return false;
      }

      if (errorValid === 0) {
        var selected_Date = this.state.seleted_ord_date;
        var date_New = new Date(selected_Date);
        if (this.state.startWeek > 0) {
          currnetDay = parseInt(currnetDay) + this.state.startWeek * 2;
        }
        var resultDate = date_New.setDate(date_New.getDate() + currnetDay);

        if (this.state.periode === "Lunch & Dinner") {
          if (
            $("#productlistall_d" + currnetDay + " .products-single-li.active")
              .length > 0
          ) {
            $(
              "#productlistall_d" +
                currnetDay +
                ", " +
                "#productlistall_" +
                currnetDay
            ).hide();
            currnetDay = parseInt(currnetDay) + 1;

            this.setState(
              { currnetDay: currnetDay, currnetPriod: "L" },
              function () {
                setTimeout(function () {
                  $("html, body").animate(
                    {
                      scrollTop: $(".mealbox-product").offset().top - 130,
                    },
                    500
                  );
                }, 100);
              }
            );
            errorValid = 0;
          } else {
            $(
              "#productlistall_d" +
                currnetDay +
                ", " +
                "#productlistall_d" +
                currnetDay
            ).show();
            if (resultDate !== "") {
              showAlert(
                "Error",
                "Please make your selection for " +
                  format(resultDate, "dd/MM/yyyy") +
                  "(Dinner)"
              );
            } else {
              showAlert("Error", "Please Select any one dinner meal");
            }
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            return false;
          }
        }
      }

      /* if (
        $("#productlistall_" + currnetDay + " .products-single-li.active")
          .length > 0
      ) {
        $(
          "#productlistall_d" +
            currnetDay +
            ", " +
            "#productlistall_" +
            currnetDay
        ).hide();
        currnetDay = parseInt(currnetDay) + 1;
        this.setState({ currnetDay: currnetDay, currnetPriod: "L" });
      } else {
        if (this.state.periode === "Lunch & Dinner") {
          if (
            $("#productlistall_d" + currnetDay + " .products-single-li.active")
              .length > 0
          ) {
            $(
              "#productlistall_d" +
                currnetDay +
                ", " +
                "#productlistall_" +
                currnetDay
            ).hide();
            currnetDay = parseInt(currnetDay) + 1;

            this.setState({ currnetDay: currnetDay, currnetPriod: "L" });
          } else {
            showAlert("Error", "Please Select any one meal");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        } else {
          showAlert("Error", "Please Select any one meal");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      } */
    } else {
      currnetDay = currnetDay - 1;
      var startWeek = this.state.startWeek;
      if (currnetDay % 5 === 0) {
        startWeek = currnetDay / 5;
      }
      this.setState({ currnetDay: currnetDay, startWeek: startWeek });
    }
  }
  gotoDay(currnetDay) {
    var start_Week = this.state.startWeek;
    var updatecurrnetDay = "";
    if (start_Week === 0) {
      updatecurrnetDay = currnetDay;
    } else {
      if (currnetDay === 0) {
        updatecurrnetDay = start_Week * 5;
      } else {
        updatecurrnetDay = parseInt(start_Week * 5) + parseInt(currnetDay);
      }
    }

    var Validerror = 0;
    var errorIndex = "";
    for (let index = 0; index <= updatecurrnetDay; index++) {
      if (
        $("#productlistall_" + index + " .products-single-li.active").length ===
        0
      ) {
        errorIndex = index;
        Validerror = 1;
        break;
      }
      if (Validerror === 0) {
        if (this.state.periode === "Lunch & Dinner") {
          if (
            $("#productlistall_" + index + " .products-single-li.active")
              .length === 0
          ) {
            Validerror = 1;
            errorIndex = index;
            break;
          }
        }
      }
    }
    if (Validerror === 0) {
      this.setState({ currnetDay: updatecurrnetDay });
    } else {
      if (errorIndex !== "") {
        var selected_Date = this.state.seleted_ord_date;
        var date_New = new Date(selected_Date);
        if (this.state.startWeek > 0) {
          errorIndex = parseInt(errorIndex) + this.state.startWeek * 2;
        }
        var resultDate = date_New.setDate(date_New.getDate() + errorIndex);
        showAlert(
          "Error",
          "Please make your selection for " + format(resultDate, "dd/MM/yyyy")
        );
      } else {
        showAlert("Error", "Please make your selection");
      }

      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      return false;
    }
  }

  gotoNexWeek(type) {
    var startWeek = this.state.startWeek;
    if (type === "n") {
      var errorIndex = 0;
      var Validerror = "";
      var enadVal = "";
      if (startWeek === 0) {
        enadVal = 5;
      } else {
        enadVal = startWeek * 5;
      }
      for (let index = 0; index < enadVal; index++) {
        if (
          $("#productlistall_" + index + " .products-single-li.active")
            .length === 0
        ) {
          Validerror = 1;
          errorIndex = index;
          break;
        }
        if (Validerror === 0) {
          if (this.state.periode === "Lunch & Dinner") {
            if (
              $("#productlistall_" + index + " .products-single-li.active")
                .length === 0
            ) {
              Validerror = 1;
              errorIndex = index;
              break;
            }
          }
        }
      }
      if (Validerror > 0) {
        if (errorIndex !== "") {
          var selected_Date = this.state.seleted_ord_date;
          var date_New = new Date(selected_Date);
          if (startWeek > 0) {
            errorIndex = parseInt(errorIndex) + this.state.startWeek * 2;
          }
          var resultDate = date_New.setDate(date_New.getDate() + errorIndex);
          showAlert(
            "Error",
            "Please make your selection for " + format(resultDate, "dd/MM/yyyy")
          );
        } else {
          showAlert("Error", "Please make your selection");
        }
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        return;
      }

      startWeek = parseInt(startWeek) + 1;
    } else {
      startWeek = startWeek - 1;
    }

    this.setState({ startWeek: startWeek });
  }

  selectmealType(index, meal_Type) {
    this.setState({ currnetPriod: meal_Type }, function () {
      if (meal_Type === "L") {
        $("#productlistall_d" + index).hide();
        $("#productlistall_" + index).show();
      } else {
        $("#productlistall_" + index).hide();
        $("#productlistall_d" + index).show();
      }
    });
  }

  handlegoLink = (value) => {
    this.setState({ userLoginType: value });
  };

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      email_address: formPayload.email,
      site_url: this.props.globalsettings.client_site_url,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      login_username: formPayload.email,
      login_password: formPayload.password,
    };
    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }
    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });
    var pdpaConsent = formPayload.pdpa_consent === true ? "yes" : "no";

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.firstname,
      customer_email: formPayload.email,
      customer_password: formPayload.password,
      customer_phone: formPayload.mobile,
      customer_pdpa_consent: pdpaConsent,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };
  /* for signup - end*/

  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }
      cookie.save("UserId", fbloginData.result_set.customer_id, { path: "/" });
      cookie.save("UserEmail", fbloginData.result_set.customer_email, {
        path: "/",
      });
      cookie.save(
        "UserFname",
        fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "",
        { path: "/" }
      );
      cookie.save(
        "UserLname",
        fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "",
        { path: "/" }
      );
      cookie.save("UserMobile", mobileno, { path: "/" });
      cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
      cookie.save(
        "UserDefaultAddress",
        fbloginData.result_set.customer_address_name,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitOne",
        fbloginData.result_set.customer_address_line1,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitTwo",
        fbloginData.result_set.customer_address_line2,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultPostalCode",
        fbloginData.result_set.customer_postal_code,
        { path: "/" }
      );
      this.setState(
        {
          first_name: fbloginData.result_set.customer_first_name,
          last_name: fbloginData.result_set.customer_last_name,
          email: fbloginData.result_set.customer_email,
          contactno: fbloginData.result_set.customer_phone,
          address: "",
          userLoginType: "",

          userID: fbloginData.result_set.customer_id,
        },
        function () {
          if (this.state.recomented_food === "Yes") {
            this.goToRecomented();
          } else {
            this.setState({ currentStep: 3, completeStep: 3 }, function () {
              setTimeout(function () {
                $("html, body").animate(
                  {
                    scrollTop: $(".progressbar").offset().top - 130,
                  },
                  500
                );
              }, 1000);
            });
          }
        }
      );
    } else {
      cookie.remove("loginpopupTrigger", { path: "/" });
      showAlert("Error", fbloginData.message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  showMessage(response) {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    if (response.status === "ok") {
      showAlert("Success", response.message);
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  getComboproData($_this, CompoType) {
    var comboproSet = Array(),
      comboproMdf = Array(),
      aplypriceZero = 0;

    if (CompoType === 1) {
      $_this.find(".individual_combo_pro").each(function () {
        var componentsProDet = $(this).attr("data-productdata");

        var compoProDet = componentsProDet.split("~");
        var combolstQtyValue = $(this)
          .find(".combolst_qty_value")
          .attr("data-qtyval");
        var comboProInvPrice = $(this).attr("data-invcomboprice");
        if (parseInt(combolstQtyValue) > 0) {
          comboproSet.push({
            product_id: compoProDet[0],
            product_name: compoProDet[1],
            product_sku: compoProDet[2],
            product_price: comboProInvPrice,
            product_qty: combolstQtyValue,
            modifiers: comboproMdf,
          });
        }
      });
    } else {
      $_this.find(".components_selct").each(function () {
        var combopriceapplychk = $(this)
          .closest(".main_combo_div")
          .attr("data-combopriceapply");
        if ($(this).find(":checked").length > 0) {
          $(this)
            .find(":checked")
            .each(function () {
              var aplyprice_temp = $(this).attr("data-combopropriceaply");

              var componentsProDet = $(this).attr("data-compomainselval");
              var compoProDet = componentsProDet.split("~");

              var combolstQtyValue = 1;
              var comboProInvPrice =
                parseFloat(combopriceapplychk) > 0
                  ? parseFloat(aplyprice_temp)
                  : parseFloat(aplypriceZero);
              comboproSet.push({
                product_id: compoProDet[0],
                product_name: compoProDet[1],
                product_sku: compoProDet[2],
                product_price: comboProInvPrice,
                product_qty: combolstQtyValue,
              });
            });
        }
      });
    }

    return comboproSet;
  }

  checkMinValfun($_this) {
    var combo_qtycount = 0,
      min_val_error = 0;
    var min_selectcombo =
      $_this.attr("data-minselectcombo") != ""
        ? $_this.attr("data-minselectcombo")
        : "0";
    $_this.find(".combolst_qty_value").each(function () {
      var qtyval = $(this).attr("data-qtyval");
      combo_qtycount += parseInt(qtyval);
    });
    if (parseInt(min_selectcombo) > parseInt(combo_qtycount)) {
      min_val_error = 1;
    }
    return min_val_error;
  }

  createMealPlanOrder(captureID = "", payGetWayType = "") {
    /* if  merge order date */
    var orderDate = "";

    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;

    /* For Advanced Slot */
    var order_is_timeslot = "No",
      ordSlotStrTm = "",
      ordSlotEndTm = "";
    if (seletedOrdDate !== "" && seletedOrdTime !== "") {
      var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();

      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

      orderDate = formatedDate + " " + orderTime;

      if (this.state.isAdvanced === "yes" && this.state.slotType === "2") {
        order_is_timeslot = "Yes";
        ordSlotStrTm = this.state.seleted_ord_slot_str;
        ordSlotEndTm = this.state.seleted_ord_slot_end;
      }
    }

    if (seletedOrdDate !== "" && seletedOrdTime !== "") {
      var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();

      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

      orderDate = formatedDate + " " + orderTime;
    }

    if (orderDate === "" && paymentMode !== "initial") {
      showAlert("Error", "Please select order date and time.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      return false;
    }

    var dateList = [];
    for (let index = 0; index < this.state.meal_plan_week * 5; index++) {
      dateList.push(index);
    }

    var selectedDate = this.state.seleted_ord_date;
    var finalDataList = [];
    dateList.map((item) => {
      var dateNew = new Date(selectedDate);
      var resultDate = dateNew.setDate(dateNew.getDate() + item);
      if (this.state.periode === "Lunch & Dinner") {
        finalDataList.push(format(resultDate, "yyyy-MM-dd") + "_L");
        finalDataList.push(format(resultDate, "yyyy-MM-dd") + "_D");
      } else {
        var typeperiode = "";
        if (this.state.periode === "Lunch") {
          typeperiode = "L";
        } else if (this.state.periode === "Dinner") {
          typeperiode = "D";
        }

        finalDataList.push(
          format(resultDate, "yyyy-MM-dd") + "_" + typeperiode
        );
      }
    });

    var productDetails = [];
    if (
      this.state.recomented_food === "Yes" &&
      this.state.recomentedProduct.length > 0
    ) {
      var i = 0;
      finalDataList.map((item) => {
        this.state.recomentedProduct.map((item1) => {
          productDetails[i] = [];
          var productName =
            item1.product_alias !== "" && item1.product_alias !== null
              ? item1.product_alias
              : item1.product_name;

          productDetails[i].push({
            product_name: productName,
            product_id: item1.product_id,
            product_sku: item1.product_sku,
            product_image:
              item1.product_thumbnail !== "" &&
              item1.product_thumbnail !== null &&
              typeof item1.product_thumbnail !== undefined &&
              typeof item1.product_thumbnail !== "undefined"
                ? proFolderUrl + "main-image/" + item1.product_thumbnail
                : "",
            product_unit_price: item1.product_price,
            product_total_amount: item1.product_price,
            product_qty: 1,
            menu_set_components: [],
          });
          i++;
        });
        i;
      });
    } else {
      var $_react_this = this;
      $(".productlist-main-div").each(function (index) {
        if ($(this).find(".products-list-ulmain li.active").length > 0) {
          productDetails[index] = [];
          $(this)
            .find(".products-list-ulmain li.active")
            .each(function () {
              var product_type_hidden = $(this)
                .find(".product_type_hidden")
                .val();
              var prodetails_input = $(this)
                .find(".prodetails_input")
                .val()
                .split("~");
              var product_type_hidden = $(this)
                .find(".product_type_hidden")
                .val();
              var proprice_input = $(this).find(".proprice_input").val();
              var proqty_input = $(this).find(".updateproqty_input").val();
              var proextraprice_input = $(this)
                .find(".proextraprice_input")
                .val();

              if (isNaN(parseFloat(proextraprice_input))) {
                proextraprice_input = 0;
              }

              var productTotalPrice =
                (parseFloat(proprice_input) + parseFloat(proextraprice_input)) *
                parseInt(proqty_input);

              if (product_type_hidden === "1") {
                productDetails[index].push({
                  product_name: prodetails_input[1],
                  product_id: prodetails_input[2],
                  product_sku: prodetails_input[3],
                  product_image:
                    prodetails_input[4] !== "" &&
                    typeof prodetails_input[4] !== undefined &&
                    typeof prodetails_input[4] !== "undefined"
                      ? proFolderUrl + "main-image/" + prodetails_input[4]
                      : "",
                  product_unit_price: proprice_input,
                  product_total_amount: productTotalPrice,
                  product_qty: proqty_input,
                });
              } else {
                var menuSet = Array();
                var productDetailsMain = Array();
                var modifier = Array();
                var CompoType = $(this)
                  .find("#" + index + "_set_menu_component_type")
                  .val();
                CompoType = parseInt(CompoType);
                var compo_errors = "0";

                if (CompoType === 1) {
                  $(this)
                    .find(".main_combo_div")
                    .each(function () {
                      var modifierapply = $(this).attr("data-modifierapply");
                      if (modifierapply === "1") {
                        var combodata_txt = $(this).attr("data-combodata");
                        var menu_component = combodata_txt.split("~");
                        var productDetails = $_react_this.getComboproData(
                          $(this),
                          0
                        );
                        productDetailsMain.push({
                          menu_component_id: menu_component[0],
                          menu_component_name: menu_component[1],
                          product_details: productDetails,
                          min_max_flag: menu_component[2],
                        });
                      } else {
                        var check_min_val = $_react_this.checkMinValfun(
                          $(this)
                        );
                        if (check_min_val == 0) {
                          var combodata_txt = $(this).attr("data-combodata");
                          menu_component = combodata_txt.split("~");
                          var productDetails = $_react_this.getComboproData(
                            $(this),
                            CompoType
                          );
                          productDetailsMain.push({
                            menu_component_id: menu_component[0],
                            menu_component_name: menu_component[1],
                            product_details: productDetails,
                            min_max_flag: menu_component[2],
                          });
                        } else {
                          compo_errors = "1";
                          $(this).find(".error_combo_div").show();
                          if (
                            $("#ProductDetailMdl .error_combo_div:visible")
                              .length > 0
                          ) {
                            $("html, body").animate(
                              {
                                scrollTop:
                                  $(
                                    "#ProductDetailMdl .error_combo_div:visible:first"
                                  ).offset().top - 200,
                              },
                              1000
                            );
                          }
                          return false;
                        }
                      }
                    });
                } else {
                  $(this)
                    .find(".main_combo_div")
                    .each(function () {
                      var combodata_txt = $(this).attr("data-combodata");
                      var menu_component = combodata_txt.split("~");
                      var productDetails = $_react_this.getComboproData(
                        $(this),
                        CompoType
                      );
                      if (productDetails !== "") {
                        productDetailsMain.push({
                          menu_component_id: menu_component[0],
                          menu_component_name: menu_component[1],
                          product_details: productDetails,
                          min_max_flag: menu_component[2],
                        });
                      }
                    });
                }
                menuSet = productDetailsMain;

                productDetails[index].push({
                  product_name: prodetails_input[1],
                  product_id: prodetails_input[2],
                  product_sku: prodetails_input[3],
                  product_image:
                    prodetails_input[4] !== "" &&
                    typeof prodetails_input[4] !== undefined &&
                    typeof prodetails_input[4] !== "undefined"
                      ? proFolderUrl + "main-image/" + prodetails_input[4]
                      : "",
                  product_unit_price: proprice_input,
                  product_total_amount: productTotalPrice,
                  product_qty: proqty_input,
                  menu_set_components: menuSet,
                });
              }
            });
        }
      });
    }
    if (Object.keys(productDetails).length === 0) {
      showAlert("Error", "Please select products");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      return false;
    }

    var validation = "No";
    var grandTotal =
      parseFloat(this.state.subTotal) +
      parseFloat(this.state.planprice) +
      parseFloat(this.state.deliveryCharge);
    var postObject = {};

    postObject = {
      app_id: appId,
      availability_id: mealplanId,
      goal: this.state.selectedGoal,
      plan_id: this.state.plan_id,
      plan_name: this.state.planname,
      periode: this.state.periode,
      meal_plan_week: this.state.meal_plan_week,
      receive_news: this.state.receive_news,
      about_us: this.state.didyouhear,
      about_other: this.state.about_other,
      outlet_id: defaultOutlet,
      delivery_charge: this.state.deliveryCharge,

      order_date: orderDate,
      sub_total:
        parseFloat(this.state.subTotal) + parseFloat(this.state.planprice),
      grand_total: grandTotal,
      order_source: "Web",
      customer_id: this.state.userID,
      customer_fname: this.state.first_name,
      customer_lname: this.state.last_name,
      customer_email: this.state.email,
      customer_mobile_no: this.state.contactno,
      customer_postal_code: this.state.postal_code,
      customer_address: this.state.address,
      customer_unit_no1: this.state.unitnumber1,
      customer_unit_no2: this.state.unitnumber2,
      products: productDetails,
      dataList: finalDataList,
      payment_mode: payGetWayType === "OmisePayNow" ? 11 : 3,
      payment_type:
        payGetWayType === "OmisePayNow" ? "Omise PayNow" : payGetWayType,
      payment_getway_details:
        payGetWayType === "OmisePayNow"
          ? { payment_type: "Omise PayNow", payment_status: "Success" }
          : "",
    };

    axios
      .post(apiUrl + "mealplan/submit_order", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ orderValidFail: 0 });

          if (validation !== "Yes") {
            var localOrderNo = res.data.common.local_order_no;
            this.setState({ placingorderimage: tickImage });

            /* capture payemnt */
            this.captureOmiseAmount(
              captureID,
              res.data.common.order_primary_id,
              localOrderNo
            );

            this.showSuccessPage(localOrderNo);
          } else {
            return true;
          }
        } else if (res.data.status === "error") {
          if (res.data.form_error !== undefined) {
            showAlert("Error", res.data.form_error);
          } else {
            showAlert("Error", res.data.message);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      })
      .catch((error) => {
        $.magnificPopup.close();
        this.paymentFail(
          "Error",
          "Error code: 1004 Oops! Unable to processing your order. Please try again."
        );
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
        return false;
      });

    return false;
  }
  /* sucess page */
  showSuccessPage(localOrderNo) {
    const { history } = this.props;
    setTimeout(function () {
      $.magnificPopup.close();
      history.push("/meal-plans/thankyou/" + localOrderNo);
    }, 450);
  }
  paymentFail(header, message) {
    $(".warining_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="btn btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>'
    );
  }
  pageReload = () => {
    setTimeout(function () {
      // window.location.reload();
    }, 15000);
  };
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
  /* Omise Capture Payment */
  captureOmiseAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {};
    cabtureObjects = {
      payment_reference: stripeReference,
      customer_id: this.state.userID,
      app_id: appId,
      token: captureID,
      order_id: orderPrimaryId,
      log_id: this.state.omise_log_id,
      outlet_id: defaultOutlet,
    };
    axios
      .post(
        apiUrl + "mealplanpaymentv1/captureAmountOmise",
        qs.stringify(cabtureObjects)
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });

          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.showSuccessPage(localOrderNo);
            }.bind(this)
          );
        } else if (captureRes.data.status === "pending") {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        } else if (captureRes.data.status === "error") {
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : "Your order was not successful.";
          showAlert("Error", omsMsgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          //Get Status of Charge ID
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        }
      })
      .catch((error) => {
        //this.captureOmiseAmount(captureID, orderPrimaryId, localOrderNo);
      });
  }

  retrieve_charge_details = (captureID, localOrderNo, orderPrimaryId) => {
    var orderOutlet_Id = defaultOutlet;
    var postObject = {};
    postObject = {
      charge_id: captureID,
      app_id: appId,
      order_primary_id: orderPrimaryId,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(
        apiUrl + "mealplanpaymentv1/retrieve_charge_details",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (
          res.data.status === "ok" &&
          res.data.payment_status === "successful"
        ) {
          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.showSuccessPage(localOrderNo);
            }.bind(this)
          );
        } else if (
          res.data.status === "ok" &&
          res.data.payment_status === "pending"
        ) {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        } else {
          var magnfPopup = $.magnificPopup.instance;
          var msgTxt =
            res.data.message != ""
              ? res.data.message
              : "Your order was not successful.";
          showAlert("Error", msgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
  };
  choosePayment(paymentMode) {
    if (paymentMode === "Cash") {
      this.setState({ paymentmodevalue: "Cash" });
    } else if (paymentMode === "Omise") {
      this.setState({ paymentmodevalue: "Omise" });
    } else if (paymentMode === "OmisePayNow") {
      this.setState({ paymentmodevalue: "OmisePayNow" });
    } else {
      this.setState({ paymentmodevalue: "promotion" });
    }

    //this.postOrder(2, "Yes");
  }

  /* Omise Payment Gateway Start */
  handleChangeTxt = (item, event) => {
    const re = /^[0-9 \b]+$/;
    if (item == "cardNumber") {
      if (event.target.value === "" || re.test(event.target.value)) {
        var cardnumber = this.space(event.target.value);
        var cardnumberLenght = cardnumber.length;
        if (cardnumberLenght <= 19) {
          this.setState({ [item]: cardnumber });
          this.cardValidation(cardnumber);
        }
      }
    } else if (
      item == "expiration_month" ||
      item == "expiration_year" ||
      item == "security_code"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ [item]: event.target.value });
      }
    } else {
      this.setState({ [item]: event.target.value });
    }

    this.setState({
      omisenameerror: "",
      omisecardrror: "",
      omisemontherror: "",
      omiseyearerror: "",
      omisecodeerror: "",
    });
  };

  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }

  cardValidation(carnumber) {
    var imagename = "";
    if (carnumber != "") {
      var single = carnumber.substring(0, 1);
      var double = carnumber.substring(0, 2);

      if (single == 4) {
        imagename = "visa";
      } else if (double == 34 || double == 37) {
        imagename = "american";
      } else if (double >= 51 && double <= 55) {
        imagename = "master";
      } else if (double == 60 || double == 64 || double == 65) {
        imagename = "discover";
      }
    }

    this.setState({ cardImage: imagename });
  }

  getOmiseToken() {
    var errorFlg = 0,
      omisenameerror = "",
      omisecardrror = "",
      omisemontherror = "",
      omiseyearerror = "",
      omisecodeerror = "";
    if (this.state.holdername == "") {
      errorFlg++;
      omisenameerror = "omise-form-error";
    }
    if (this.state.cardNumber == "") {
      errorFlg++;
      omisecardrror = "omise-form-error";
    }
    if (this.state.expiration_month == "") {
      errorFlg++;
      omisemontherror = "omise-form-error";
    }
    if (this.state.expiration_year == "") {
      errorFlg++;
      omiseyearerror = "omise-form-error";
    }
    if (this.state.security_code == "") {
      errorFlg++;
      omisecodeerror = "omise-form-error";
    }

    this.setState({
      omisenameerror: omisenameerror,
      omisecardrror: omisecardrror,
      omisemontherror: omisemontherror,
      omiseyearerror: omiseyearerror,
      omisecodeerror: omisecodeerror,
    });

    if (errorFlg === 0) {
      showLoader("omise-pay-btn", "class");
      Omise.setPublicKey(this.state.globalSettings.omise_public_key);
      var current = this;
      var card = {
        name: this.state.holdername,
        number: this.state.cardNumber,
        expiration_month: this.state.expiration_month,
        expiration_year: this.state.expiration_year,
        security_code: this.state.security_code,
        livemode: false,
      };
      Omise.createToken("card", card, function (statusCode, response) {
        hideLoader("omise-pay-btn", "class");
        if (statusCode === 200) {
          if (
            response.object == "error" ||
            !response.card.security_code_check
          ) {
            var msgArr = Array();
            msgArr["message"] =
              response.object == "error"
                ? response.message
                : "Invalid card details.";
            current.showOmiselert(msgArr);
          } else {
            $.magnificPopup.close();
            current.setState(
              {
                omise_tokken_response: response,
                omise_tokken_card_id: response.card.id,
                omise_tokken_id: response.id,
              },
              () => {
                current.onProcessOmiseToken(response);
              }
            );
          }
        } else {
          var msgArr = Array();
          msgArr["message"] =
            response.message !== ""
              ? response.message
              : "Sorry!. Error from omise.";
          current.showOmiselert(msgArr);
        }
      });
    } else {
      return false;
    }
  }

  showOmiselert(msgArr) {
    var dataTimeoutId = $(".omise-error-info-div").attr("data-timeoutid");
    clearTimeout(dataTimeoutId);
    $(".omise-error-info-div").hide();
    $(".omise-error-msg").html(msgArr["message"]);
    $("#omise-error-info-div").fadeIn();
    var timeoutid = setTimeout(function () {
      $(".omise-error-info-div").hide();
    }, 6000);
    $(".omise-error-info-div").attr("data-timeoutid", timeoutid);
  }

  /* post stripe account */
  onProcessOmiseToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    var payAmount = (
      parseFloat(this.state.subTotal) +
      parseFloat(this.state.planprice) +
      parseFloat(this.state.deliveryCharge)
    ).toFixed("2");

    var requestType = "-" + defaultOutlet + "-" + this.state.userID;

    axios
      .get(apiUrl + "mealplanpaymentv1/getPaymentReferenceId?app_id=" + appId)
      .then((topRes) => {
        if (topRes.data.status === "ok") {
          var paymentRef = topRes.data.payment_ref_id;
          this.setState({ payment_ref_id: paymentRef });

          /*  load process html */
          var postObject = {};
          postObject = {
            app_id: appId,
            token: token.id,
            customer_id: this.state.userID,
            outlet_id: defaultOutlet,
            paid_amount: payAmount,
            request_type: requestType,
            outlet_name: defaultOutletName + "-" + paymentRef,
          };

          //this.postOrder(2, "Yes"); // To validate the order

          /* if (this.state.orderValidFail === 0) { */
          axios
            .post(
              apiUrl + "mealplanpaymentv1/authOmise",
              qs.stringify(postObject)
            )
            .then((res) => {
              if (res.data.status === "ok") {
                var captureID = res.data.result_set.payment_reference_1;
                var omiseLogId = res.data.result_set.log_id;
                this.setState(
                  { validateimage: tickImage, omise_log_id: omiseLogId },
                  function () {
                    this.createMealPlanOrder(captureID, "omise");
                  }.bind(this)
                );
              } else if (res.data.status === "error") {
                if (res.data.message == "token was already used") {
                  this.omise_search_history();
                } else {
                  $.magnificPopup.close();
                  var errerTxt =
                    res.data.message !== ""
                      ? res.data.message
                      : "Error code: 1001 Oops! Something went wrong! Please try again.";
                  this.paymentFail("Error", errerTxt);
                  $.magnificPopup.open({
                    items: {
                      src: ".warining_popup",
                    },
                    type: "inline",
                  });
                  return false;
                }
              } else {
                var currents = this;
                setTimeout(
                  function () {
                    currents.omise_search_history();
                  }.bind(this),
                  2000
                );
              }
            })
            .catch((error) => {
              var currentcatch = this;
              setTimeout(
                function () {
                  currentcatch.omise_search_history();
                }.bind(this),
                2000
              );
            });
          /* } else {
            $.magnificPopup.close();
            this.paymentFail(
              "Error",
              "Error code:1003 Oops! Something went wrong! Please try again."
            );
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });

            return false;
          } */
        } else {
          $.magnificPopup.close();
          this.paymentFail(
            "Error",
            "Error code:1003 Oops! Something went wrong! Please try again."
          );
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });

          return false;
        }
      })
      .catch((error) => {
        $.magnificPopup.close();
        this.paymentFail(
          "Error",
          "Error code: 1002 Oops! Unable to connect to server. Please try again."
        );
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
        return false;
      });
  };

  omise_search_history = () => {
    var postObject = {};
    postObject = {
      app_id: appId,
      card_id: this.state.omise_tokken_card_id,
      token_id: this.state.omise_tokken_id,
      outlet_id: defaultOutlet,
    };

    axios
      .post(
        apiUrl + "mealplanpaymentv1/omise_search_details",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var captureID = res.data.captureID;
          var omiseLogId = res.data.log_id;
          this.setState(
            { validateimage: tickImage, omise_log_id: omiseLogId },
            function () {
              this.createMealPlanOrder(captureID, "Omise");
            }.bind(this)
          );
        } else if (res.data.status === "error") {
          /* Reset poup message -  start */
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        } else {
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        }
      });
  };

  createOmiseSource() {
    if (
      this.state.globalSettings.omise_paynow_public !== "" &&
      typeof this.state.globalSettings.omise_paynow_public !== undefined &&
      typeof this.state.globalSettings.omise_paynow_public !== "undefined"
    ) {
      var payNowID = base64.decode(
        this.state.globalSettings.omise_paynow_public
      );
      Omise.setPublicKey(payNowID);

      showLoader("omisepaynow_btn", "Idtext");
      var payAmount =
        (
          parseFloat(this.state.subTotal) +
          parseFloat(this.state.planprice) +
          parseFloat(this.state.deliveryCharge)
        ).toFixed("2") * 100;

      var currentThis = this;
      Omise.createSource(
        "paynow",
        {
          amount: parseInt(payAmount),
          currency: "SGD",
        },
        function (statusCode, response) {
          if (response.id !== "" && response.id !== null) {
            currentThis.paynowCapture(response.id, response.amount);
          } else {
            hideLoader("omisepaynow_btn", "Idtext");
          }
        }
      );
    }
  }

  paynowCapture(sourceID, amount) {
    if (sourceID !== "") {
      var omisePostObject = {};
      var currentThis = this;
      omisePostObject = {
        app_id: appId,
        source_id: sourceID,
        customer_id: this.state.userID,
        outlet_id: defaultOutlet,
        amount: amount,
      };
      axios
        .post(
          apiUrl + "mealplanpaymentv1/paynowCapture",
          qs.stringify(omisePostObject)
        )
        .then((res) => {
          hideLoader("omisepaynow_btn", "Idtext");
          if (res.data.status === "ok") {
            this.setState(
              {
                omisePayNowResponse: res.data.result_set,
                omisepaynowAmount: amount / 100,
                omisePaynowTime: 600,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-omisepaynowconf-popup",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                  callbacks: {
                    close: function () {
                      clearInterval(startTimePay);
                    },
                  },
                });
                this.omisePaynowTimeReduce();
                startTimePay = setInterval(function () {
                  var modTime = currentThis.state.omisePaynowTime % 3;
                  if (currentThis.state.omisePaynowTime > 1) {
                    if (modTime === 0) {
                      currentThis.checkOmisePayNowPamentStatus(startTimePay);
                    }
                    currentThis.omisePaynowTimeReduce();
                  } else {
                    clearInterval(startTimePay);
                    $.magnificPopup.close();
                    currentThis.paymentFail(
                      "Error",
                      "Error code: 1002 Oops! Unable to processing your order. Please try again."
                    );
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                  }
                }, 1000);
              }
            );
          } else {
            var erromessage =
              res.data.message !== "" &&
              typeof res.data.message !== undefined &&
              typeof res.data.message !== "undefined"
                ? res.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  omisePaynowTimeReduce() {
    var omisePaynowTime = parseInt(this.state.omisePaynowTime) - 1;
    this.setState({ omisePaynowTime: omisePaynowTime });
  }

  checkOmisePayNowPamentStatus(startTimePay) {
    if (Object.keys(this.state.omisePayNowResponse).length > 0) {
      var statusObj = {};
      statusObj = {
        app_id: appId,
        customer_id: this.state.userID,
        outlet_id: defaultOutlet,
        capture_id: this.state.omisePayNowResponse.captureId,
      };
      axios
        .post(
          apiUrl + "mealplanpaymentv1/checkPaynowStatus",
          qs.stringify(statusObj)
        )
        .then((statusRes) => {
          if (statusRes.data.status === "ok") {
            if (statusRes.data.result_set.omise_paynow_status) {
              clearInterval(startTimePay);
              var captureID = statusRes.data.result_set.charge_id;

              this.setState(
                {
                  validateimage: tickImage,
                  payNowCaptureId: captureID,
                  omise_log_id: statusRes.data.result_set.omiseLogId,
                },
                function () {
                  this.createMealPlanOrder(captureID, "OmisePayNow");
                }.bind(this)
              );
            } else {
            }
          } else {
            clearInterval(startTimePay);
            var erromessage =
              statusRes.data.message !== "" &&
              typeof statusRes.data.message !== undefined &&
              typeof statusRes.data.message !== "undefined"
                ? statusRes.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        });
    }
  }

  orderPayValidaion(payGetWayType, event) {
    event.preventDefault();
    var popupIdTxt = "";
    if (payGetWayType === "Omise") {
      popupIdTxt = "#pay-omiseconf-popup";
    } else if (payGetWayType === "OmisePayNow") {
      this.createOmiseSource();
      return false;
      popupIdTxt = "#pay-omisepaynowconf-popup";
    } else {
      popupIdTxt = "#pay-conf-popup";
    }
    $.magnificPopup.open({
      items: {
        src: popupIdTxt,
      },
      type: "inline",
      closeOnBgClick: false,
    });
  }

  /* show online payment mode loading */
  onlinePaymentLoading() {
    var grandTotal = (
      parseFloat(this.state.subTotal) +
      parseFloat(this.state.planprice) +
      parseFloat(this.state.deliveryCharge)
    ).toFixed("2");

    if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.validateimage} />
          </div>
          <div className="process_right">
            <h5>Processing</h5>
            <p>Validating card information.</p>
          </div>
        </div>
      );
    }
  }
  /* show online payment mode loading */
  orderBCLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.placingorderimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Placing your order now.</p>
        </div>
      </div>
    );
  }
  /* show online payment mode loading */
  amountCaptureLoading() {
    var grandTotal = (
      parseFloat(this.state.subTotal) +
      parseFloat(this.state.planprice) +
      parseFloat(this.state.deliveryCharge)
    ).toFixed("2");

    if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.completingpaymentimage} />
          </div>
          <div className="process_right">
            <h5>Processing</h5>
            <p>Completing your online payment.</p>
          </div>
        </div>
      );
    }
  }

  render() {
    var grandTotalPrice = (
      parseFloat(this.state.subTotal) +
      parseFloat(this.state.planprice) +
      parseFloat(this.state.deliveryCharge)
    ).toFixed("2");
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header />

        {this.state.userLoginType === "login" && (
          <div className="login-page contact-box">
            <div className="contact-box-lhs ">
              <img className="img-single" src={register} alt="" />
            </div>
            <div className="contact-box-rhs">
              <h2>Member Login</h2>
              <Login
                fields={this.state.fields}
                onChange={this.fieldChange}
                onValid={this.handleSignin}
                pagetype="mealplan"
                onGoLink={this.handlegoLink}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        )}
        {this.state.userLoginType === "register" && (
          <div className="register-page contact-box">
            <div className="contact-box-lhs ">
              <img className="img-single" src={register} alt="" />
            </div>
            <div className="contact-box-rhs">
              <h2>Register</h2>
              <Signup
                fields={this.state.fieldssignup}
                onChange={this.fieldChangeSignup}
                onValid={this.handleSignup}
                pagetype="mealplan"
                onGoLink={this.handlegoLink}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        )}
        {this.state.userLoginType === "forgot" && (
          <div className="forgot-page contact-box">
            <div className="contact-box-lhs ">
              <img className="img-single" src={register} alt="" />
            </div>
            <div className="contact-box-rhs">
              <h2>Forgot Password</h2>
              <Forgotpassword
                fields={this.state.fieldsfgtpassword}
                onChange={this.fieldforgot}
                onValid={this.forgotpassword}
                pagetype="mealplan"
                onGoLink={this.handlegoLink}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        )}

        {/* Header End */}
        <div
          className="common-inner-blckdiv mealplan-parent"
          style={{ display: this.state.userLoginType !== "" ? "none" : "" }}
        >
          <div className="mealplan">
            <div className="container">
              <div className="mealplantable textcenter">
                <div className="mealplan-tittle">
                  <h1>Meal Plans</h1>
                  <p>
                    Our minimum order is lunch & dinner package for 5 days,
                    Monday to Friday (excluding public holidays). *There will be
                    a delivery charge otherwise.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="step-by-step">
            <div className="step-by-top">
              <div className="container">
                <ul className="progressbar">
                  <li className={this.state.currentStep >= 1 ? "active" : ""}>
                    <figure onClick={this.topgotoStep.bind(this, 1)}>
                      <img className="whitetick" src={whitetick} alt="" />
                      <img className="greytick" src={greytick} alt="" />
                    </figure>
                    <div className="progress-content">
                      <p>Step 1</p>
                      <span>Set a Goal</span>
                    </div>
                  </li>
                  <li className={this.state.currentStep >= 2 ? "active" : ""}>
                    <figure onClick={this.topgotoStep.bind(this, 2)}>
                      <img className="whitetick" src={whitetick} alt="" />
                      <img className="greytick" src={greytick} alt="" />
                    </figure>
                    <div className="progress-content">
                      <p>Step 2</p>
                      <span>Choose Your Plan</span>
                    </div>
                  </li>
                  <li className={this.state.currentStep >= 3 ? "active" : ""}>
                    <figure onClick={this.topgotoStep.bind(this, 3)}>
                      <img className="whitetick" src={whitetick} alt="" />
                      <img className="greytick" src={greytick} alt="" />
                    </figure>
                    <div className="progress-content">
                      <p>Step 3</p>
                      <span>Tell Us About Yourself</span>
                    </div>
                  </li>
                  <li className={this.state.currentStep >= 4 ? "active" : ""}>
                    <figure onClick={this.topgotoStep.bind(this, 4)}>
                      <img className="whitetick" src={whitetick} alt="" />
                      <img className="greytick" src={greytick} alt="" />
                    </figure>
                    <div className="progress-content">
                      <p>Step 4</p>
                      <span>Review & Check Out</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {this.state.currentStep === 1 && (
            <div className="what-today textcenter ">
              <div className="container">
                <h4 className="purple">Step 1</h4>
                <h1>Choose Your Goal</h1>
                <div className="what-today-inner">
                  <div className="what-today-lhs">
                    <ul>
                      <li
                        className={
                          this.state.selectedGoal === "Small" ? "active" : ""
                        }
                        onClick={this.selectGoal.bind(this, "Small")}
                      >
                        <div className="tdt-header">
                          <h5>Small</h5>
                          <strong>350 Kcal</strong>
                          <span>Average</span>
                        </div>
                        <div className="tdt-body">
                          <strong>$14.90</strong>
                          <span>Per Meal</span>
                        </div>
                        <div className="tdt-footer">
                          <ul>
                            <li>
                              <span>Protein</span>
                              <strong>High</strong>
                            </li>
                            <li>
                              <span>Fiber</span>
                              <strong>High</strong>
                            </li>
                            <li>
                              <span>Carbs</span>
                              <strong>Low</strong>
                            </li>
                            <li>
                              <span>Healthy Fats</span>
                              <strong>Low</strong>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        className={
                          this.state.selectedGoal === "Medium" ? "active" : ""
                        }
                        onClick={this.selectGoal.bind(this, "Medium")}
                      >
                        <div className="tdt-header">
                          <h5>Medium</h5>
                          <strong>450 Kcal</strong>
                          <span>Average</span>
                        </div>
                        <div className="tdt-body">
                          <strong>$16.90</strong>
                          <span>Per Meal</span>
                        </div>
                        <div className="tdt-footer">
                          <ul>
                            <li>
                              <span>Protein</span>
                              <strong>High</strong>
                            </li>
                            <li>
                              <span>Fiber</span>
                              <strong>High</strong>
                            </li>
                            <li>
                              <span>Carbs</span>
                              <strong>Moderate</strong>
                            </li>
                            <li>
                              <span>Healthy Fats</span>
                              <strong>Moderate</strong>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        className={
                          this.state.selectedGoal === "Large" ? "active" : ""
                        }
                        onClick={this.selectGoal.bind(this, "Large")}
                      >
                        <div className="tdt-header">
                          <h5>Large</h5>
                          <strong>570 Kcal</strong>
                          <span>Average</span>
                        </div>
                        <div className="tdt-body">
                          <strong>$18.90</strong>
                          <span>Per Meal</span>
                        </div>
                        <div className="tdt-footer">
                          <ul>
                            <li>
                              <span>Protein</span>
                              <strong>High</strong>
                            </li>
                            <li>
                              <span>Fiber</span>
                              <strong>High</strong>
                            </li>
                            <li>
                              <span>Carbs</span>
                              <strong>High</strong>
                            </li>
                            <li>
                              <span>Healthy Fats</span>
                              <strong>Moderate</strong>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="mealplan"
            style={{ display: this.state.currentStep === 2 ? "" : "none" }}
          >
            <div className="container">
              <div className="mealplantable textcenter">
                <div className="mealplan-tittle">
                  <h4 className="purple">Step 2</h4>
                  <h1>Choose Your Plan</h1>
                  <p>
                    Our minimum order is lunch & dinner package for 5 days,
                    Monday to Friday (excluding public holidays). *There will be
                    a delivery charge otherwise.
                  </p>
                </div>
                <ul>
                  {this.state.mealPlan.length > 0 &&
                    this.state.mealPlan.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={
                            item.meal_plan_id == this.state.plan_id
                              ? "plan_list active"
                              : "plan_list"
                          }
                          id={"plan_" + item.meal_plan_id}
                        >
                          {item.meal_plan_tag_info !== "" &&
                            item.meal_plan_tag_info !== null && (
                              <div className="ribbon">
                                <span>{item.meal_plan_tag_info}</span>
                              </div>
                            )}
                          <div className="meal-header">
                            <h3>{item.meal_plan_title}</h3>
                            {item.meal_plan_description !== "" && (
                              <span>{Parser(item.meal_plan_description)}</span>
                            )}
                          </div>
                          <div className="meal-body-parent">
                            <div className="meal-body">
                              <h2 className="plan_price">
                                {showPrices(item.meal_plan_lunch_price)}
                              </h2>
                              <input
                                type="hidden"
                                value={item.meal_plan_lunch_price}
                                className="planprice"
                              />
                              <input
                                type="hidden"
                                value={item.meal_plan_title}
                                className="planname"
                              />
                              <span className="meal_title">Per Meal</span>
                            </div>
                            <div className="meal-footer">
                              <ul>
                                <li>
                                  <input
                                    type="radio"
                                    name={"mealoption_" + item.meal_plan_id}
                                    className="planpriode"
                                    id={"lunch" + item.meal_plan_id}
                                    onChange={this.handleChange.bind(
                                      this,
                                      item.meal_plan_id,
                                      item.meal_plan_lunch_price,
                                      "lunch"
                                    )}
                                    value="Lunch"
                                  />
                                  <label htmlFor={"lunch" + item.meal_plan_id}>
                                    Lunch
                                  </label>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    name={"mealoption_" + item.meal_plan_id}
                                    className="planpriode"
                                    value="Dinner"
                                    id={"dinner" + item.meal_plan_id}
                                    onChange={this.handleChange.bind(
                                      this,
                                      item.meal_plan_id,
                                      item.meal_plan_dinner_price,
                                      "dinner"
                                    )}
                                  />
                                  <label htmlFor={"dinner" + item.meal_plan_id}>
                                    Dinner
                                  </label>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    name={"mealoption_" + item.meal_plan_id}
                                    className="planpriode"
                                    id={"lunch_and_dinner" + item.meal_plan_id}
                                    onChange={this.handleChange.bind(
                                      this,
                                      item.meal_plan_id,
                                      item.meal_plan_lunch_dinner_price,
                                      "lunch_and_dinner"
                                    )}
                                    value="Lunch & Dinner"
                                  />
                                  <label
                                    htmlFor={
                                      "lunch_and_dinner" + item.meal_plan_id
                                    }
                                  >
                                    Lunch & Dinner
                                  </label>
                                </li>
                              </ul>
                              <a
                                href={void 0}
                                onClick={this.choosePlan.bind(
                                  this,
                                  item.meal_plan_id,
                                  item.meal_plan_week,
                                  index
                                )}
                                className="button btn-blk"
                              >
                                Choose This Plan
                              </a>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
                {this.state.openProducts && (
                  <div id="choose_plan_date" className="choose-plan-date">
                    <h1>Choose Your Plan Date</h1>
                    {/*  <ul className="date-list">
                      <li>
                        <div className="cal-day">Mon</div>
                        <div className="cal-date">18</div>
                        <div className="cal-month">Apr</div>
                      </li>
                      <li>
                        <div className="cal-day">Mon</div>
                        <div className="cal-date">18</div>
                        <div className="cal-month">Apr</div>
                      </li>
                      <li>
                        <div className="cal-day">Mon</div>
                        <div className="cal-date">18</div>
                        <div className="cal-month">Apr</div>
                      </li>
                      <li>
                        <div className="cal-day">Mon</div>
                        <div className="cal-date">18</div>
                        <div className="cal-month">Apr</div>
                      </li>
                    </ul>
                    <a href="#">More Dates</a> */}
                    <OrderAdvancedDatetimeSlot
                      {...this.props}
                      hdrState={this.state}
                      setdateTimeFlg={this.setdateTimeFlg}
                      avail="mealplan"
                    />
                  </div>
                )}
                {this.state.meal_plan_week !== "" &&
                  this.state.openProducts &&
                  this.state.globalSettings.client_melapan_menu_pdf !== "" &&
                  this.state.globalSettings.client_melapan_menu_pdf !==
                    null && (
                    <div className="choose-plan-date recommended-select">
                      <div className="normal-inline-chk">
                        {/*  <h4>FMK MEAL PLAN</h4>
                        <p>
                          Our meal plan is fixed from Monday to Friday. We roll
                          out a different variety of menu every week.{" "}
                        </p>
                        <p>
                          You may indicate your food allergies or special
                          dietary restrictions (for example: no beef, no pork,
                          no nuts, etc). We try our best to cater to you.
                        </p> */}
                        {/* <div className="inline-chk-inner">
                        <input
                          type="radio"
                          defaultChecked={
                            this.state.recomented_food === "Yes" ? true : false
                          }
                          name="recomented_food"
                          value="Yes"
                          onChange={this.handleTextChange}
                        />
                        <label htmlFor="news_yes">Yes</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="radio"
                          defaultChecked={
                            this.state.recomented_food === "No" ? true : false
                          }
                          name="recomented_food"
                          value="No"
                          onChange={this.handleTextChange}
                        />
                        <label htmlFor="news_no">No</label>
                      </div> */}
                        {/* this.state.recomented_food === "Yes" && (
                          <div className="move-to-next">
                            <a
                              href={void 0}
                              onClick={this.goToRecomented.bind(this, 3)}
                              className="button btn-next proceed-to-next"
                            >
                              Proceed to Next Step
                            </a>
                          </div>
                        ) */}
                        {/* <div className="move-to-next">
                          <a
                            href={void 0}
                            onClick={this.goToRecomented.bind(this, 3)}
                            className="button btn-next proceed-to-next"
                          >
                            Proceed to Next Step
                          </a>
                          <a
                            href={mediaUrl+"melapanmenupdf/"+this.state.globalSettings.client_melapan_menu_pdf}
                            target="_blank"
                            className="button btn-next proceed-to-next"
                          >
                            View Menu
                          </a>
                        </div> */}
                      </div>
                    </div>
                  )}

                {this.state.meal_plan_week !== "" &&
                  this.state.openProducts && (
                    <div
                      className="meal-box"
                      /* style={{
                        display:
                          this.state.recomented_food === "Yes" ? "none" : "",
                      }} */
                    >
                      <h1>Choose Your Meal</h1>
                      <div className="meal-box-inner">
                        <div className="top-date-box">
                          <ul>
                            {this.state.startWeek >= 1 && (
                              <li
                                className="block-week"
                                onClick={this.gotoNexWeek.bind(this, "p")}
                              >
                                <span>Previous Week </span>
                              </li>
                            )}
                            {this.loadPlanDates()}
                            {this.state.meal_plan_week !== "" &&
                              parseInt(this.state.meal_plan_week) > 1 &&
                              parseInt(this.state.startWeek) + 1 !=
                                this.state.meal_plan_week && (
                                <li
                                  className="block-week"
                                  onClick={this.gotoNexWeek.bind(this, "n")}
                                >
                                  <span>Next Week </span>
                                </li>
                              )}
                          </ul>
                        </div>
                        <div className="move-to-next">
                          {this.state.currnetDay !== 0 && (
                            <a
                              href={void 0}
                              onClick={this.gotoNexDay.bind(this, "p")}
                              className="button btn-prev"
                            >
                              Previous Day
                            </a>
                          )}
                          {this.state.meal_plan_week * 5 - 1 !==
                            this.state.currnetDay && (
                            <a
                              href={void 0}
                              onClick={this.gotoNexDay.bind(this, "n")}
                              className="button btn-next"
                            >
                              Next Day
                            </a>
                          )}
                          {this.state.meal_plan_week * 5 - 1 ===
                            this.state.currnetDay && (
                            <a
                              href={void 0}
                              onClick={this.gotoStep.bind(this, 3)}
                              className="button btn-next proceed-to-next"
                            >
                              Proceed to Next Step
                            </a>
                          )}
                        </div>
                        <div className="mealbox-product textcenter">
                          {this.loadProductList()}
                          <div className="move-to-next">
                            {this.state.currnetDay !== 0 && (
                              <a
                                href={void 0}
                                onClick={this.gotoNexDay.bind(this, "p")}
                                className="button btn-prev"
                              >
                                Previous Day
                              </a>
                            )}
                            {this.state.meal_plan_week * 5 - 1 !==
                              this.state.currnetDay && (
                              <a
                                href={void 0}
                                onClick={this.gotoNexDay.bind(this, "n")}
                                className="button btn-next"
                              >
                                Next Day
                              </a>
                            )}
                            {this.state.meal_plan_week * 5 - 1 ===
                              this.state.currnetDay && (
                              <a
                                href={void 0}
                                onClick={this.gotoStep.bind(this, 3)}
                                className="button btn-next proceed-to-next"
                              >
                                Proceed to Next Step
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>

          {this.state.currentStep === 3 && (
            <div className="tell-urself">
              <div className="container">
                <div className="mealplan-tittle textcenter">
                  <h4 className="purple">Step 3</h4>
                  <h1>Tell Us About Yourself</h1>
                  <p>
                    Thank you for your interest in Fit Mum Kitchen meal plans.
                    Kindly provide us with the following information below.
                  </p>
                </div>

                <div className="self-form" id="meal_plane_frm">
                  <div className="sf-common your-info">
                    <h3>Your information</h3>
                    <div className="form-group form-grp-half">
                      <div className="fg-merge">
                        <input
                          type="text"
                          placeholder="First Name"
                          className="form-input"
                          name="first_name"
                          value={this.state.first_name}
                          onChange={this.handleTextChange}
                        />
                        {this.state.error_first_name === true && (
                          <span className="error">This field is required.</span>
                        )}
                      </div>
                      <div className="fg-merge">
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="form-input"
                          name="last_name"
                          value={this.state.last_name}
                          onChange={this.handleTextChange}
                        />
                        {this.state.error_last_name === true && (
                          <span className="error">This field is required.</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group form-grp-half">
                      <div className="fg-merge">
                        <input
                          type="text"
                          placeholder="Email"
                          className="form-input"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleTextChange}
                        />
                        {this.state.error_email === true && (
                          <span className="error">This field is required.</span>
                        )}
                        {this.state.error_email_invalid === true && (
                          <span className="error">Invalid Email.</span>
                        )}
                      </div>
                      <div className="fg-merge">
                        <input
                          type="text"
                          placeholder="Contact No"
                          name="contactno"
                          value={this.state.contactno}
                          onChange={this.handleTextChange}
                          className="form-input"
                        />
                        {this.state.error_contactno === true && (
                          <span className="error">This field is required.</span>
                        )}
                      </div>
                    </div>

                    <div className="form-group form-grp-half">
                      <div className="fg-merge">
                        <input
                          type="text"
                          placeholder="Postal Code"
                          className="form-input"
                          name="postal_code"
                          value={this.state.postal_code}
                          onChange={this.handleTextChange}
                        />
                        {this.state.error_postal_code === true && (
                          <span className="error">This field is required.</span>
                        )}
                      </div>
                      <div className="fg-merge">
                        <input
                          type="text"
                          placeholder="Address"
                          className="form-input"
                          name="address"
                          value={this.state.address}
                          readOnly
                          onChange={this.handleTextChange}
                        />
                        {this.state.error_address === true && (
                          <span className="error">This field is required.</span>
                        )}
                      </div>
                    </div>

                    <div className="form-group form-grp-half">
                      <div className="fg-merge">
                        <input
                          type="text"
                          placeholder="Unit No 01"
                          className="form-input"
                          name="unitnumber1"
                          value={this.state.unitnumber1}
                          onChange={this.handleTextChange}
                        />
                        {this.state.error_unitnumber1 === true && (
                          <span className="error">This field is required.</span>
                        )}
                      </div>
                      <div className="fg-merge">
                        <input
                          type="text"
                          placeholder="Unit No 02"
                          className="form-input"
                          name="unitnumber2"
                          value={this.state.unitnumber2}
                          onChange={this.handleTextChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sf-common your-diet">
                    <h3>Any Special Dietary Requirement?</h3>
                    <div className="inline-chk">
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          name="drietary_requirement"
                          value="No Beef"
                          onChange={this.handleTextChange}
                        />
                        <label>No Beef</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          name="drietary_requirement"
                          value="No Pork"
                          onChange={this.handleTextChange}
                        />
                        <label>No Pork</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          name="drietary_requirement"
                          value="No Seafood"
                          onChange={this.handleTextChange}
                        />
                        <label>No Seafood</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          name="drietary_requirement"
                          value="No Nuts"
                          onChange={this.handleTextChange}
                        />
                        <label>No Nuts</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          name="drietary_requirement"
                          value="No Beans"
                          onChange={this.handleTextChange}
                        />
                        <label>No Beans</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          name="drietary_requirement"
                          value="I'm Not Fussy!"
                          onChange={this.handleTextChange}
                        />
                        <label>I'm Not Fussy!</label>
                      </div>
                    </div>
                  </div>

                  <div className="sf-common fmk-news">
                    <h3>
                      Would You Like To Receive News From Fit Mum Kitchen? *
                    </h3>
                    <div className="inline-chk">
                      <div className="inline-chk-inner">
                        <input
                          type="radio"
                          value="Yes"
                          name="receive_news"
                          id="news_yes"
                          checked={
                            this.state.receive_news === "Yes" ? true : false
                          }
                          onChange={this.handleTextChange}
                        />
                        <label htmlFor="news_yes">Yes</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="radio"
                          value="No"
                          name="receive_news"
                          id="news_no"
                          checked={
                            this.state.receive_news === "No" ? true : false
                          }
                          onChange={this.handleTextChange}
                        />
                        <label htmlFor="news_no">No</label>
                      </div>
                    </div>
                    {this.state.error_receive_news === true && (
                      <span className="error">This field is required.</span>
                    )}
                  </div>

                  <div className="sf-common hear-abtus">
                    <h3>Where Did You Hear About Us?</h3>
                    <div className="inline-chk">
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          value="Facebook"
                          name="about_us"
                          id="facebook"
                          checked={
                            this.state.didyouhear.indexOf("Facebook") >= 0
                              ? true
                              : false
                          }
                          onChange={this.handleTextChange}
                        />
                        <label htmlFor="facebook">Facebook</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          value="Google"
                          name="about_us"
                          id="google"
                          checked={
                            this.state.didyouhear.indexOf("Google") >= 0
                              ? true
                              : false
                          }
                          onChange={this.handleTextChange}
                        />
                        <label htmlFor="google">Google</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          value="Instagram"
                          name="about_us"
                          id="instagram"
                          checked={
                            this.state.didyouhear.indexOf("Instagram") >= 0
                              ? true
                              : false
                          }
                          onChange={this.handleTextChange}
                        />
                        <label htmlFor="instagram">Instagram</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          value="Word Of Mouth"
                          name="about_us"
                          id="wordmouth"
                          checked={
                            this.state.didyouhear.indexOf("Word Of Mouth") >= 0
                              ? true
                              : false
                          }
                          onChange={this.handleTextChange}
                        />
                        <label htmlFor="wordmouth">Word Of Mouth</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          value="Tiktok"
                          name="about_us"
                          id="tiktok"
                          checked={
                            this.state.didyouhear.indexOf("Tiktok") >= 0
                              ? true
                              : false
                          }
                          onChange={this.handleTextChange}
                        />
                        <label htmlFor="tiktok">Tiktok</label>
                      </div>
                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          value="Groupbuy"
                          name="about_us"
                          id="groupbuy"
                          checked={
                            this.state.didyouhear.indexOf("Groupbuy") >= 0
                              ? true
                              : false
                          }
                          onChange={this.handleTextChange}
                        />
                        <label htmlFor="groupbuy">Groupbuy</label>
                      </div>

                      <div className="inline-chk-inner">
                        <input
                          type="checkbox"
                          value="Others"
                          name="about_us"
                          id="others"
                          checked={
                            this.state.didyouhear.indexOf("Others") >= 0
                              ? true
                              : false
                          }
                          onChange={this.handleTextChange}
                        />
                        <label htmlFor="others">Others</label>
                      </div>
                    </div>
                    {this.state.didyouhear.indexOf("Others") >= 0 && (
                      <div className="form-group form-grp-half">
                        <div className="fg-merge">
                          <input
                            type="text"
                            placeholder="Others"
                            className="form-input"
                            name="about_other"
                            value={this.state.about_other}
                            onChange={this.handleTextChange}
                          />
                          {this.state.error_other_about === true && (
                            <span className="error">
                              This field is required.
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="next-step-accept">
                    <div className="step-chk-inner">
                      <input
                        type="checkbox"
                        name="terms"
                        checked={this.state.terms}
                        onChange={this.handleTextChange}
                      />
                      <label>
                        I accept the{" "}
                        <Link to="/terms-conditions" target="_blank">
                          terms & condtions.
                        </Link>
                      </label>
                      {this.state.error_terms === true && (
                        <div>
                          <span className="error">This field is required.</span>
                        </div>
                      )}
                    </div>
                    <div className="step-chk-btn">
                      <a
                        href={void 0}
                        onClick={this.gotoStep.bind(this, 4)}
                        className="button"
                      >
                        Proceed to Next Step
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.currentStep === 4 && (
            <div className="review-chkout">
              <div className="container">
                <div className="mealplan-tittle textcenter">
                  <h4 className="purple">Step 4</h4>
                  <h1>Review & Check Out</h1>
                  <p>
                    Please check through your orders before proceeding to
                    payment.
                  </p>
                </div>
                <div className="self-form">
                  <div className="review-chkout-top">
                    <ul>
                      <li>
                        <strong className="purple">Step 1</strong>
                        <h3>Choose Your Goal</h3>
                        <p>{this.state.selectedGoal}</p>
                      </li>
                      <li>
                        <strong className="purple">Step 2</strong>
                        <h3>Choose Your Plan</h3>
                        <p>
                          {this.state.planname} - {this.state.periode}
                        </p>
                      </li>
                      <li>
                        <strong className="purple">Step 3</strong>
                        <h3>Tell Us About Yourself</h3>
                        <ul>
                          <li>
                            <h4>Your Information</h4>
                            <div className="info-deck">
                              <ul>
                                <li>
                                  <span>First Name</span>
                                  <p>{this.state.first_name}</p>
                                </li>
                                <li>
                                  <span>Last Name</span>
                                  <p>{this.state.last_name}</p>
                                </li>
                                <li>
                                  <span>Email</span>
                                  <p>{this.state.email}</p>
                                </li>
                                <li>
                                  <span>Contact Us</span>
                                  <p>{this.state.contactno}</p>
                                </li>
                                <li>
                                  <span>Address</span>
                                  <p>{this.state.address}</p>
                                </li>
                              </ul>
                            </div>
                          </li>
                          {this.state.seleted_ord_date !== "" && (
                            <li>
                              <h4>Your Preferred Start Date</h4>

                              <p>
                                {format(
                                  this.state.seleted_ord_date,
                                  "dd-MMMM-yyyy"
                                )}
                              </p>
                            </li>
                          )}
                          {this.state.drietary_requirement.length > 0 && (
                            <li>
                              <h4>Any Special Dietary Requirement?</h4>
                              <p>
                                {this.state.drietary_requirement.join(", ")}
                              </p>
                            </li>
                          )}
                          <li>
                            <h4>
                              Would You Like To Receive News From Fit Mum
                              Kitchen? *
                            </h4>
                            <p>{this.state.receive_news}</p>
                          </li>
                          <li>
                            <h4>Where Did You Hear About Us?</h4>
                            <p>
                              {this.state.didyouhear.length > 0
                                ? this.state.didyouhear.join(", ")
                                : ""}
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="review-chkout-btm">
                    <ul>
                      <li className="subtotal">
                        <span>Sub Total {currencySymbol}</span>
                        <span>
                          {(
                            parseFloat(this.state.subTotal) +
                            parseFloat(this.state.planprice)
                          ).toFixed("2")}
                        </span>
                        <p>
                          Shipping and discount will be calculated at next step{" "}
                        </p>
                      </li>
                      <li className="subtotal">
                        <span>Delivery Charges {currencySymbol}</span>
                        <span>
                          {parseFloat(this.state.deliveryCharge).toFixed("2")}
                        </span>
                      </li>
                      <li className="grand-total">
                        <span>Grand Total {currencySymbol}</span>
                        <span>{grandTotalPrice}</span>
                      </li>
                    </ul>

                    <div className="chk-payment chk-payment-main">
                      <div className="chk-payment-row">
                        <div className="chk-payment-title">
                          <h3>Select Your Payment Method</h3>
                        </div>
                        <div className="chk-payment-col">
                          <form action="#">
                            {this.state.paymentmodevalue !== "promotion" &&
                              parseFloat(grandTotalPrice) > 0 && (
                                <ul className="chk-payment-col-radio">
                                  {this.state.cod_payment_enable === 1 && (
                                    <li
                                      className={
                                        this.state.paymentmodevalue == "Cash"
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <input
                                        type="radio"
                                        defaultChecked={
                                          this.state.paymentmodevalue == "Cash"
                                            ? "checked"
                                            : ""
                                        }
                                        id="cash"
                                        onClick={this.choosePayment.bind(
                                          this,
                                          "Cash"
                                        )}
                                        name="radio-group"
                                      />

                                      <label
                                        className="radio_con"
                                        htmlFor="cash"
                                      >
                                        <span>Cash On Delivery</span>
                                        <img src={cashImage} />
                                      </label>
                                    </li>
                                  )}

                                  {this.state.omise_payment_enable === 1 && (
                                    <li
                                      className={
                                        this.state.paymentmodevalue == "Omise"
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <input
                                        type="radio"
                                        defaultChecked={
                                          this.state.paymentmodevalue == "Omise"
                                            ? "checked"
                                            : ""
                                        }
                                        id="omscard"
                                        onClick={this.choosePayment.bind(
                                          this,
                                          "Omise"
                                        )}
                                        name="radio-group"
                                      />
                                      <label
                                        className="radio_con"
                                        htmlFor="omscard"
                                      >
                                        <span>Credit / Debit Card</span>
                                        <img src={paymentImage} />
                                      </label>
                                    </li>
                                  )}
                                  {this.state.omise_payment_enable === 1 && (
                                    <li
                                      className={
                                        this.state.paymentmodevalue ==
                                        "OmisePayNow"
                                          ? "payment-omise active"
                                          : "payment-omise"
                                      }
                                    >
                                      <input
                                        type="radio"
                                        defaultChecked={
                                          this.state.paymentmodevalue ==
                                          "OmisePayNow"
                                            ? "checked"
                                            : ""
                                        }
                                        id="omspaynowcard"
                                        onClick={this.choosePayment.bind(
                                          this,
                                          "OmisePayNow"
                                        )}
                                        name="radio-group"
                                      />
                                      <label
                                        className="radio_con"
                                        htmlFor="omspaynowcard"
                                      >
                                        <img src={paymentPayNowImage} />
                                      </label>
                                    </li>
                                  )}
                                </ul>
                              )}

                            {this.state.paymentmodevalue === "promotion" &&
                              parseFloat(grandTotalPrice) === 0 &&
                              this.state.subTotal > 0 && (
                                <ul className="chk-payment-col-radio promotion-ul-topcls">
                                  <li>
                                    <input
                                      type="radio"
                                      defaultChecked={
                                        this.state.paymentmodevalue ==
                                        "promotion"
                                          ? "checked"
                                          : ""
                                      }
                                      id="promotion"
                                      name="radio-group"
                                    />
                                    <label htmlFor="promotion"></label>
                                    <div className="radio_con">Promotion</div>
                                  </li>
                                </ul>
                              )}
                          </form>
                        </div>
                        <div className="chk-payment-col"></div>
                      </div>

                      <div className="chk-payment-btn">
                        {this.state.paymentmodevalue !== "promotion" &&
                          parseFloat(grandTotalPrice) > 0 && (
                            <div className="chk-payment-btn-row">
                              {/* this.state.paymentmodevalue == "Cash" &&
                                  this.state.cod_payment_enable === 1 && (
                                    <a
                                      href="/"
                                      className="button"
                                      title="Continue"
                                      onClick={this.payCash.bind(this)}
                                    >
                                      Place Order
                                    </a>
                                  ) */}

                              {this.state.globalSettings.omise_public_key &&
                                this.state.contactno != "" &&
                                this.state.paymentmodevalue == "Omise" &&
                                this.state.omise_payment_enable === 1 && (
                                  <a
                                    href={void 0}
                                    onClick={this.orderPayValidaion.bind(
                                      this,
                                      "Omise"
                                    )}
                                    className="button"
                                    title="Continue"
                                  >
                                    Check Out
                                  </a>
                                )}
                            </div>
                          )}
                        {this.state.globalSettings.omise_public_key &&
                          this.state.contactno != "" &&
                          this.state.paymentmodevalue == "OmisePayNow" &&
                          this.state.omise_payment_enable === 1 && (
                            <a
                              href={void 0}
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "OmisePayNow"
                              )}
                              className="button"
                              id="omisepaynow_btn"
                              title="Continue"
                            >
                              Check Out
                            </a>
                          )}
                      </div>
                    </div>

                    {/* <a
                        href={void 0}
                        onClick={this.createMealPlanOrder.bind(this, "a")}
                        className="button"
                      >
                        Check Out
                      </a> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/*Payment omise confirm popup Start*/}
        <div
          id="pay-omiseconf-popup"
          className="white-popup mfp-hide popup_sec pay-omiseconf-popup"
        >
          <div
            className="omise-error-info-div"
            id="omise-error-info-div"
            data-timeoutid=""
            style={{ display: "none" }}
          >
            <div className="container">
              <span className="omise-error-icon">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </span>
              <a
                href="#"
                className="omise_alert_close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={(evt) => {
                  evt.preventDefault();
                }}
              >
                {" "}
                <span aria-hidden="true">×</span>{" "}
              </a>
              <p className="omise-error-msg">Something went wrong</p>
            </div>
          </div>

          {this.state.globalSettings.omise_envir === "dev" && (
            <div className="omisepay-mode-option">Test Mode</div>
          )}

          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img className="makisan-logo-img" src={stripeImage} />
                  </span>
                  <span className="omise-pay-title">{projectTitle}</span>
                </div>
                <div className="omise_pay_body">
                  <div className="omisepop_in">
                    <div className="form_sec">
                      <div className={"uname-box " + this.state.omisenameerror}>
                        <i className="fa fa-user" data-unicode="f007"></i>
                        <input
                          type="text"
                          id="holdername"
                          className="form-control"
                          placeholder="Card Holder Name"
                          value={this.state.holdername}
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "holdername"
                          )}
                        />
                      </div>

                      <div
                        className={"card-numbr-div " + this.state.omisecardrror}
                      >
                        <i
                          className="fa fa-credit-card"
                          data-unicode="f09d"
                        ></i>
                        <input
                          type="text"
                          className={"form-control " + this.state.cardImage}
                          placeholder="Card Number"
                          value={this.state.cardNumber}
                          id="omise_card_number"
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "cardNumber"
                          )}
                        />
                        <span className="card"></span>
                      </div>

                      <div className="form_expire_row">
                        <div className="expire_row_inner">
                          <div
                            className={
                              "expire_left " + this.state.omisemontherror
                            }
                          >
                            <i
                              className="fa fa-calendar-o"
                              data-unicode="f133"
                            ></i>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              id="expiration_month"
                              placeholder="MM"
                              value={this.state.expiration_month}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "expiration_month"
                              )}
                            />
                          </div>

                          <div
                            className={
                              "expire_mdl " + this.state.omiseyearerror
                            }
                          >
                            <input
                              type="text"
                              placeholder="YY"
                              className="form-control"
                              maxLength="4"
                              id="expiration_year"
                              value={this.state.expiration_year}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "expiration_year"
                              )}
                            />
                          </div>

                          <div
                            className={
                              "expire_right " + this.state.omisecodeerror
                            }
                          >
                            <i className="fa fa-lock" data-unicode="f023"></i>
                            <input
                              type="password"
                              maxLength="3"
                              className="form-control"
                              id="security_code"
                              placeholder="CVV"
                              value={this.state.security_code}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "security_code"
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="alt_btns">
                        {this.state.globalSettings.omise_public_key &&
                          this.state.contactno != "" &&
                          this.state.paymentmodevalue == "Omise" &&
                          this.state.omise_payment_enable === 1 && (
                            <button
                              onClick={this.getOmiseToken.bind(this)}
                              className="button omise-pay-btn"
                            >
                              Pay {stripeCurrency} {grandTotalPrice}
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment omise confirm popup - End*/}

        {/*Payment omise Pay Now confirm popup Start*/}
        <div
          id="pay-omisepaynowconf-popup"
          className="white-popup mfp-hide popup_sec pay-omisepaynowconf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img className="makisan-logo-img" src={stripeImage} />
                  </span>
                  <span className="omise-pay-title">{projectTitle}</span>
                </div>
                {Object.keys(this.state.omisePayNowResponse).length > 0 && (
                  <div className="omise_pay_body">
                    <div className="omisepop_in">
                      <div className="omisepop_in">
                        <p className="omise-pay-now-notes">
                          Scan the QR code to pay.
                        </p>
                        {this.state.omisePayNowResponse.download_uri !== "" && (
                          <img
                            src={this.state.omisePayNowResponse.download_uri}
                            id="paynow-qr-image"
                          />
                        )}
                        <p className="omise-paynow-amount-to-paid">
                          <span>
                            $ {this.state.omisepaynowAmount.toFixed("2")}
                          </span>
                        </p>
                        <p className="paynow-seconds">
                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          <span>&nbsp;{this.state.omisePaynowTime} sec</span>
                        </p>
                        <div className="paynow-notes">
                          <h2>Instructions:</h2>
                          <ul>
                            <li>Use your bank app to scan QR Code and pay.</li>
                            <li>
                              You will be paying to Omise Payment SG (Authorised
                              payment processor) for
                            </li>
                            <li>
                              Once you make the payment, payment status of the
                              order will reflect as 'paid', please do not make
                              multiple payments with the same QR Code.
                            </li>
                            <li>
                              Page will automatically refresh after your
                              successful payment.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*payment omise Pay Now confirm popup - End*/}

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">We Are Processing Your Order</h3>
            <div className="process_inner">
              {this.onlinePaymentLoading()}
              {this.orderBCLoading()}
              {this.amountCaptureLoading()}
            </div>
          </div>
        </div>
        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var pagedataRst = Array();
  if (Object.keys(state.pagedata).length > 0) {
    if (state.pagedata[0].status === "ok") {
      pagedataRst = state.pagedata[0].result_set;
    }
  }

  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }
  return {
    globalsettings: globalSettings,
    logindata: state.login,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
  };
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
