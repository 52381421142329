/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { appId, apiUrl, currencySymbol, proFolderUrl } from "../Helpers/Config";
import {
  stripslashes,
  showLoader,
  hideLoader,
  showAlert,
  showPrices,
} from "../Helpers/SettingHelper";
import Slider from "react-slick";
import noimage from "../../common/images/no-img-product.png";
import detailBG from "../../common/images/details-img.jpg";
var Parser = require("html-react-parser");

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    var selectedProSlug = "";
    if (
      this.props.proValue !== "" &&
      typeof this.props.proValue !== undefined &&
      typeof this.props.proValue !== "undefined"
    ) {
      selectedProSlug = this.props.proValue;
    }

    this.state = {
      selectedProSlug: selectedProSlug,
      productdetail: [],
      productcommon: [],
      remaining: 30,
      product_remarks: "",
      mdfinput_value: 1,
      mdfApi_call: "Yes",
      modParentProductId: "",
      modProductPrice: "",
      modProductTotalPrice: "",
      compoinput_value: 1,
      incrCompoPrice: 0,
      compoApi_call: "Yes",
      cartTriggerFlg: "No",
      dayindex: this.props.dayindex,
      dayindexID: this.props.dayindex + "_",
      dayindexPro: "ProductDetailMd" + this.props.dayindex,
    };
  }

  showProductTags(tags) {
    return tags.map((item, index) => (
      <li key={index}>{stripslashes(item.pro_tag_name)}</li>
    ));
  }

  productDetailsMain() {
    var proDetails = this.props.productDetails;
    if (Object.keys(proDetails).length > 0) {
      var imageSource = proFolderUrl + "main-image";
      //var product_gallery_image_source = this.props.productcommon.product_gallery_image_source;
      var product_gallery_image_source = "";
      var desc = "";

      const settings = {
        dots: true,
        arrows: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

      return proDetails.map((data, index) => {
        desc =
          data.product_short_description !== "" &&
          data.product_short_description !== null
            ? stripslashes(data.product_short_description)
            : "";

        var comboLgth = data.set_menu_component
          ? data.set_menu_component.length
          : 0;
        var ModifLgth = data.modifiers ? data.modifiers.length : 0;
        var isCombo = data.product_type === "2" && comboLgth > 0 ? "Yes" : "No";
        var isModifier =
          data.product_type === "4" && ModifLgth > 0 ? "Yes" : "No";
        var isSimple =
          data.product_type === "1" && comboLgth === 0 && ModifLgth === 0
            ? "Yes"
            : "No";

        return (
          <div key={index}>
            <div
              className={"inn-product-popup " + "product_" + data.product_slug}
              key={data.product_primary_id}
              id={this.state.dayindexID + "product_" + data.product_primary_id}
            >
              {/* prodet_bansec div - start */}
              <div className="prodet_bansec">
                {/* product image div - start */}
                <div className="inn-product-img-bg prodet_top">
                  <Slider {...settings}>
                    {data.image_gallery.length > 0 ? (
                      data.image_gallery.map(function (item, imgindex) {
                        return (
                          <div key={imgindex}>
                            {
                              <img
                                src={
                                  product_gallery_image_source +
                                  "/" +
                                  item.pro_gallery_image
                                }
                              />
                            }
                          </div>
                        );
                      })
                    ) : (
                      <div>
                        {data.image_gallery.length > 0 ? (
                          <img src={detailBG} />
                        ) : data.product_thumbnail !== "" ? (
                          <img
                            src={imageSource + "/" + data.product_thumbnail}
                          />
                        ) : (
                          <img src={noimage} />
                        )}
                      </div>
                    )}
                  </Slider>
                </div>
                {/* product image div - end */}
              </div>
              {/* prodet_bansec div - end */}

              {/* combo or modifier top div - start */}
              <div className="inn_product_row">
                {/* product info div - start */}
                <div className="prodet_baninfo">
                  <div className="inn_product_hea">
                    <div className="inn_product_review">
                      <div className="inn_product_hea_left">
                        <h4>{data.catgory_name}</h4>
                        <h3>
                          {" "}
                          {data.product_alias !== ""
                            ? stripslashes(data.product_alias)
                            : stripslashes(data.product_name)}{" "}
                        </h3>
                      </div>
                    </div>

                    {data.product_tag.length > 0 && (
                      <div className="product-tag-list">
                        <ul>{this.showProductTags(data.product_tag)}</ul>
                      </div>
                    )}
                    {desc !== "" && desc !== null && (
                      <p className="desc">{Parser(desc)}</p>
                    )}
                  </div>
                </div>
                {/* product info div - end */}

                <div>
                  {isCombo === "Yes" ? this.comboProDetails(data) : ""}
                  {isModifier === "Yes" ? this.modifierProDetails(data) : ""}
                  {isSimple === "Yes" ? this.simpleProDetails(data) : ""}
                </div>
              </div>
              {/* combo or modifier top div - end */}
            </div>
          </div>
        );
      });
    } else {
      return "";
    }
  }

  closeProductDetails() {
    $(".productDetailMain").hide();
    this.props.sateValChange("selProductEndSection", "Y");
  }

  /* show modifier product details */
  simpleProDetails(proDetailArr) {
    var modProductTotalPrice = proDetailArr.product_price;
    return (
      <div>
        <div className="prd_chosen_sub_row">
          <div
            id={
              this.state.dayindexID +
              "proDtIndex-" +
              proDetailArr.product_primary_id
            }
            className="prd_chosen_sub_col popup_addcart_cls modfir_addcart_cls"
          >
            <div className="product-price-merge">
              <p className="total_price product_details_price">
                <span id={this.state.dayindexID + "id_price_final"}>
                  {showPrices(modProductTotalPrice, "Y")}
                </span>
              </p>

              <div className="addcart_row prd_chosen_sub_item_left cart_update_div addcart_done_maindiv">
                <p className="sel-quality">Select Quantity</p>
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.proQtyAction.bind(
                      this,
                      proDetailArr.product_primary_id,
                      "decr"
                    )}
                  >
                    -
                  </span>
                  <input
                    type="text"
                    value="1"
                    className="proqty_input"
                    readOnly="1"
                  />
                  <span
                    className="qty_plus"
                    onClick={this.proQtyAction.bind(
                      this,
                      proDetailArr.product_primary_id,
                      "incr"
                    )}
                  >
                    +
                  </span>
                </div>
              </div>
            </div>

            <div className="prd_chosen_sub_item_right cart_update_div addcart_done_maindiv">
              <button
                onClick={this.addToCartSimple.bind(this, proDetailArr, "done")}
              >
                Add To Cart
              </button>
            </div>

            <div className="cart-success-msg alert alert_success mdfcart_success_msg"></div>
            <div className="cart-error-msg alert alert_danger mdfcart_error_msg"></div>
          </div>
        </div>
      </div>
    );
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $(
      "#" +
        this.state.dayindexPro +
        " #" +
        this.state.dayindexID +
        "proDtIndex-" +
        indxFlg
    )
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $(
      "#" +
        this.state.dayindexPro +
        " #" +
        this.state.dayindexID +
        "proDtIndex-" +
        indxFlg
    )
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg) {
    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $(
        "#" +
          this.state.dayindexPro +
          " #" +
          this.state.dayindexID +
          "proDtIndex-" +
          IndexFlg
      )
        .find(".smiple_product_lk")
        .hide();
      $(
        "#" +
          this.state.dayindexPro +
          " #" +
          this.state.dayindexID +
          "proDtIndex-" +
          IndexFlg
      )
        .find(".addcart_done_maindiv")
        .show();
      return false;
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  /* show modifier product details */
  modifierProDetails(proDetailArr) {
    var modProductPrice =
      this.state.modProductPrice !== ""
        ? this.state.modProductPrice
        : proDetailArr.product_price;
    var modProductTotalPrice =
      this.state.modProductTotalPrice !== ""
        ? this.state.modProductTotalPrice
        : proDetailArr.product_price;
    $(
      "#" +
        this.state.dayindexPro +
        " #" +
        this.state.dayindexID +
        "id_price_final"
    ).html("<sup>" + currencySymbol + "</sup>" + modProductTotalPrice);
    return (
      <div>
        <div className="prd_chosen_row mdf_common_rows">
          <div
            id={this.state.dayindexID + "modErrorDiv"}
            className="modfr-alert-danger single-danger"
          >
            {" "}
            Product combination is wrong. Please check your combination{" "}
          </div>

          <div className="product_chosen_inner">
            <input
              type="hidden"
              id={this.state.dayindexID + "modProductSlug"}
              name="modProductSlug"
              value={proDetailArr.product_slug}
            />
            <input
              type="hidden"
              id={this.state.dayindexID + "modProductId"}
              name="modProductId"
              value={proDetailArr.product_id}
            />
            <input
              type="hidden"
              id={this.state.dayindexID + "modProductPrice"}
              name="modProductPrice"
              value={modProductPrice}
            />
            <input
              type="hidden"
              id={this.state.dayindexID + "modParentProductId"}
              name="modParentProductId"
              value={this.state.modParentProductId}
            />
            <input
              type="hidden"
              id={this.state.dayindexID + "modProductTotalPrice"}
              name="modProductTotalPrice"
              value={modProductTotalPrice}
            />

            <div className="product_chosen_col">
              <div className="product_chosen_col_inner">
                {this.modifierProOptions(proDetailArr)}
              </div>
            </div>

            {/* <div className="product_chosen_col product_chosen_col_right">
              <div className="text-box">
                <textarea
                  placeholder="You can enter your special remark in the section..."
                  name="special_notes"
                  id={this.state.dayindexID + "special_notes"}
                  maxLength="30"
                  value={this.state.product_remarks}
                  onChange={this.updateRemarks.bind(this)}
                ></textarea>
                <em>{this.state.remaining} Characters remaining</em>
              </div>
            </div> */}
          </div>
        </div>

        <div className="prd_chosen_sub_row">
          <div className="prd_chosen_sub_col popup_addcart_cls modfir_addcart_cls">
            <div className="product-price-merge">
              <p className="total_price product_details_price">
                <sup>$</sup>
                <span id={this.state.dayindexID + "id_price_final"}>
                  {modProductTotalPrice}
                </span>
              </p>
              <div className="addcart_row prd_chosen_sub_item_left cart_update_div mdf_update_div">
                <p className="sel-quality">Select Quantity</p>
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.mfdrQtyAction.bind(this, "decr")}
                  >
                    -
                  </span>
                  <input
                    type="text"
                    value={this.state.mdfinput_value}
                    className="modfir_proqty_input"
                    readOnly="1"
                  />
                  <span
                    className="qty_plus"
                    onClick={this.mfdrQtyAction.bind(this, "incr")}
                  >
                    +
                  </span>
                </div>
              </div>
            </div>

            <div className="prd_chosen_sub_item_right cart_update_div mdf_update_div">
              <button
                onClick={this.addToCartModifier.bind(
                  this,
                  proDetailArr,
                  "done"
                )}
              >
                Add To Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /* show modifier product option */
  modifierProOptions(mdfproducts) {
    var modifiersArr =
      mdfproducts.modifiers !== null && mdfproducts.modifiers !== ""
        ? mdfproducts.modifiers
        : Array();

    if (modifiersArr.length > 0) {
      const html = modifiersArr.map((item, index) => (
        <div
          className="product_chosen_item_left product_chosen_item_left_full"
          key={index}
        >
          <div className="product_chosen_addons">
            <div className="product_chosen_hea">
              <h6>{stripslashes(item.pro_modifier_name)}</h6>
              <span></span>
            </div>
          </div>

          <div className="form-group custom-select-bxcls">
            <div className={"re_select re_select_" + index}>
              {this.modifierValuesOpn(item)}
            </div>
          </div>
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  modifierValuesOpn(mdfMainArr) {
    var mdfrValueArr =
      mdfMainArr.modifiers_values !== null && mdfMainArr.modifiers_values !== ""
        ? mdfMainArr.modifiers_values
        : Array();
    if (mdfrValueArr.length > 0) {
      var mainMdfId = mdfMainArr.pro_modifier_id;
      var mainMdfNm = stripslashes(mdfMainArr.pro_modifier_name);
      var mdfrSelectBoxHtml = "";
      var defaultMdfVal = "";
      $.each(mdfrValueArr, function (index, item) {
        var datamdfVl =
          mainMdfId +
          "~" +
          mainMdfNm +
          "~" +
          stripslashes(item.pro_modifier_value_name) +
          "~" +
          item.pro_modifier_value_id +
          "~" +
          item.pro_modifier_value_price;

        var incPrc =
          parseFloat(item.pro_modifier_value_price) > 0
            ? " ( + $" + item.pro_modifier_value_price + " )"
            : "";

        mdfrSelectBoxHtml +=
          "<option value='" +
          item.pro_modifier_value_id +
          "' data-mdfvl='" +
          datamdfVl +
          "'>" +
          item.pro_modifier_value_name +
          incPrc +
          "</option>";
        if (item.pro_modifier_value_is_default === "Yes") {
          defaultMdfVal = item.pro_modifier_value_id;
        }
      });
      var mdfrSelectDropDown = Parser(mdfrSelectBoxHtml);
      var statMdfVal = this.state["modifier~~" + mainMdfId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultMdfVal;

      return (
        <select
          name="modifier_main"
          value={mdfSelectVl}
          className="modifierList"
          onChange={this.handleChange.bind(this, mainMdfId)}
          id={this.state.dayindexID + "modif" + mainMdfId}
        >
          {mdfrSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  handleChange(mdfVl, event) {
    $(
      "#" +
        this.state.dayindexPro +
        " #" +
        this.state.dayindexID +
        "modErrorDiv"
    ).hide();
    this.setState({
      mdfApi_call: "Yes",
      ["modifier~~" + mdfVl]: event.target.value,
    });
  }

  mfdrQtyAction(actionFlg) {
    var proqtyInput = $(
      "#" + this.state.dayindexPro + " .modfir_proqty_input"
    ).val();
    var modProductPrice = $(
      "#" +
        this.state.dayindexPro +
        " #" +
        this.state.dayindexID +
        "modProductPrice"
    ).val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }

    var productTotalPrice =
      parseFloat(modProductPrice) * parseFloat(proqtyInput);
    productTotalPrice = parseFloat(productTotalPrice).toFixed(2);

    /*$('.modfir_proqty_input').val(proqtyInput);*/
    this.setState({
      mdfApi_call: "No",
      mdfinput_value: proqtyInput,
      modProductTotalPrice: productTotalPrice,
    });
  }

  updateRemarks(event) {
    this.setState({ mdfApi_call: "No", product_remarks: event.target.value });
    this.setState({ remaining: 30 - event.target.value.length });
  }

  addToCartModifier(mdfProdDetail, actionFlg) {
    if (actionFlg === "initial") {
      $("#" + this.state.dayindexPro + " .mdf_add_div").hide();
      $("#" + this.state.dayindexPro + " .mdf_update_div").show();
      return false;
    } else {
      var modCount = $("#" + this.state.dayindexPro + " .modifierList").length;
      $(
        "#" +
          this.state.dayindexPro +
          " #" +
          this.state.dayindexID +
          "modErrorDiv"
      ).hide();
      var modifier = [];
      if (modCount > 0) {
        var errorChk = 0;
        $("#" + this.state.dayindexPro + " .modifierList").each(function () {
          var modVal = $(this).val();
          var modSelectVal = $(this).find("option:selected").attr("data-mdfvl");
          if (modVal != "" && modSelectVal != "") {
            var modifier_sets = modSelectVal.split("~");
            var modifierVal = [];
            modifierVal.push({
              modifier_value_id: modifier_sets[3],
              modifier_value_qty: "1",
              modifier_value_name: modifier_sets[2],
              modifier_value_price: modifier_sets[4],
            });
            modifier.push({
              modifier_id: modifier_sets[0],
              modifier_name: modifier_sets[1],
              modifiers_values: modifierVal,
            });
          } else if (modSelectVal == "" || modVal == "") {
            errorChk = 1;
          }
        });

        if (errorChk == 1) {
          $("#" + this.state.dayindexPro + " .mdfcart_error_msg").html(
            "Sorry!. Product Detail was not valid."
          );
          $("#" + this.state.dayindexPro + " .mdfcart_error_msg").show();
          $("#" + this.state.dayindexPro + " .mdfcart_error_msg")
            .delay(6000)
            .fadeOut();
          return false;
        }
      } else {
        $("#" + this.state.dayindexPro + " .mdfcart_error_msg").html(
          "Sorry!. Product Detail was not valid."
        );
        $("#" + this.state.dayindexPro + " .mdfcart_error_msg").show();
        $("#" + this.state.dayindexPro + " .mdfcart_error_msg")
          .delay(6000)
          .fadeOut();
        return false;
      }
    }
  }

  /* show combo product Details */
  comboProDetails(proDetailArr) {
    var comboArr =
      proDetailArr.set_menu_component !== null &&
      proDetailArr.set_menu_component !== ""
        ? proDetailArr.set_menu_component
        : Array();
    if (Object.keys(comboArr).length > 0) {
      var minMaxSelect = parseInt(proDetailArr.product_apply_minmax_select);
      var compoTotalPrice = proDetailArr.product_price;
      $(
        "#" +
          this.state.dayindexPro +
          " #" +
          this.state.dayindexID +
          "compoTotalPrice"
      ).html(compoTotalPrice);
      return (
        <div>
          <div className="prd_chosen_row compo_top_div">
            <div className="product_chosen_inner">
              <input
                type="hidden"
                id={this.state.dayindexID + "set_menu_component_type"}
                name="set_menu_component_type"
                value={minMaxSelect}
              />
              <input
                type="hidden"
                id={this.state.dayindexID + "product_unitprice"}
                name="product_unitprice"
                value={compoTotalPrice}
              />
              <input
                type="hidden"
                id={this.state.dayindexID + "incr_compo_price"}
                name="incr_compo_price"
                value={this.state.incrCompoPrice}
              />
              <input
                type="hidden"
                id={this.state.dayindexID + "extra_additional_price"}
                name="extra_additional_price"
              />

              <div className="product_chosen_col common_compo_div">
                {minMaxSelect === 0 && this.singleComboDet(comboArr)}
                {minMaxSelect === 1 && this.multipleComboDet(comboArr)}
              </div>

              {/* <div className="product_chosen_col product_chosen_col_right">
                <div className="text-box">
                  <textarea
                    placeholder="You can enter your special remark in the section..."
                    name="special_notes"
                    id={this.state.dayindexID + "special_notes"}
                    maxLength="30"
                    value={this.state.product_remarks}
                    onChange={this.updateRemarksCompo.bind(this)}
                  ></textarea>
                  <em>{this.state.remaining} Characters remaining</em>
                </div>
              </div> */}
            </div>
          </div>

          <div className="prd_chosen_sub_row">
            <div className="prd_chosen_sub_col popup_addcart_cls compo_addcart_cls">
              <div className="product-price-merge">
                {/* addcart_row */}
                <p className="total_price product_details_price">
                  <span id={this.state.dayindexID + "compoprice_final"}>
                    {compoTotalPrice}
                  </span>
                </p>

                <div className=" prd_chosen_sub_item_left cart_update_div compo_update_div">
                  <p className="sel-quality">Select Quantity</p>
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.compoQtyAction.bind(this, "decr")}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      value={this.state.compoinput_value}
                      className="compo_proqty_input"
                      readOnly="1"
                    />

                    <span
                      className="qty_plus"
                      onClick={this.compoQtyAction.bind(this, "incr")}
                    >
                      +
                    </span>
                  </div>
                </div>
              </div>
              <div className="prd_chosen_sub_item_right cart_update_div compo_update_div">
                <button
                  onClick={this.addToCartCombo.bind(this, proDetailArr, "done")}
                >
                  Choose
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  singleComboDet(compoProDetailArr) {
    if (Object.keys(compoProDetailArr).length > 0) {
      const html = compoProDetailArr.map((item, index1) => (
        <div
          className={
            "product_chosen_item_left product_chosen_item_left_full compo_list_div main_combo_div compo_acc_active maincombo-" +
            item.menu_component_id
          }
          data-maincomboidtxt={item.menu_component_id}
          data-combodata={
            item.menu_component_id + "~" + item.menu_component_name + "~0"
          }
          data-combopriceapply={
            item.menu_component_apply_price !== ""
              ? item.menu_component_apply_price
              : 0
          }
          key={"compo" + index1}
        >
          <div className="product_chosen_addons compo_pro_acc compo_acc_action">
            <div className="product_chosen_hea compopro_acc_head">
              <h6>{stripslashes(item.menu_component_name)}</h6>
            </div>
          </div>

          <div className="compo_acc_innerdiv">
            <div className="form-group">
              {this.showSingleComboOptions(item)}
            </div>

            <div
              className="mdfr_list_divlcs"
              data-mismatchpro=""
              data-invcomboprice=""
            >
              <div
                className="mdfr_list_divlcs_error"
                style={{ display: "none", color: "red" }}
              >
                Please choose valid modifiers
              </div>
              {this.showSingleComboMdfr(item)}
            </div>
          </div>
        </div>
      ));

      return (
        <div className="product_chosen_col_inner compo_inner_main">{html}</div>
      );
    } else {
      return "";
    }
  }

  /* show single combo product option - radio btn */
  showSingleComboOptions(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;
      var menuComponentNm = stripslashes(splProducts.menu_component_name);
      var compSelectBoxHtml = "";
      var mdfMainComboProId = "";
      var ismdfrProCount = 0;
      var comboPropriceAply = "";
      var comboProPrice = 0;
      var indvlComponentNm = "";
      var showPricetxt = "";
      var compomainselval = "";

      var statMdfVal = this.state["compo~~" + menuCmpId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultSelectVl;

      compSelectBoxHtml = compoListArr.map((item1, index2) => {
        mdfMainComboProId = item1[0].product_id;
        ismdfrProCount =
          item1[0].modifiers !== null && item1[0].modifiers !== ""
            ? Object.keys(item1[0].modifiers).length
            : 0;
        comboProPrice = item1[0].product_price;
        comboPropriceAply =
          parseFloat(comboProPrice) > 0 &&
          parseInt(menuComponentApplyPrice) === 1 &&
          parseInt(ismdfrProCount) === 0
            ? comboProPrice
            : 0;
        indvlComponentNm =
          item1[0].product_alias !== ""
            ? stripslashes(item1[0].product_alias)
            : stripslashes(item1[0].product_name);

        showPricetxt =
          parseFloat(comboPropriceAply) > 0
            ? " ( + $" + parseFloat(comboPropriceAply).toFixed(2) + " )"
            : "";

        compomainselval =
          item1[0].product_id +
          "~" +
          indvlComponentNm +
          "~" +
          item1[0].product_sku +
          "~" +
          comboProPrice;

        return (
          <div className="custom_radio" key={"cmbrido-" + index2}>
            <input
              type="checkbox"
              name={"combointprochk_" + menuCmpId}
              value={item1[0].product_id}
              className="combointprochk_cls"
              data-compomainselval={compomainselval}
              data-mdfcombopro={mdfMainComboProId}
              data-combopropriceaply={comboPropriceAply}
              data-ismdfrprochk={ismdfrProCount}
              /* checked={this.CheckDefltComboPro(
                item1[0].product_id,
                mdfSelectVl
              )} */
              onChange={this.handleChangeCompoMain.bind(this, menuCmpId)}
            />
            <span>{indvlComponentNm + showPricetxt}</span>
          </div>
        );
      });

      return (
        <div
          className="components_selct components_selctbox_cls"
          id={this.state.dayindexID + "cmpp" + menuCmpId}
        >
          {compSelectBoxHtml}
        </div>
      );
    } else {
      return "";
    }
  }
  CheckDefltComboPro(proId, dfltPro) {
    var chkRadioBtn = proId === dfltPro ? true : false;
    return chkRadioBtn;
  }

  /* show single combo product option */
  showSingleComboOptions_sltBx(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;
      var menuComponentNm = stripslashes(splProducts.menu_component_name);
      var compSelectBoxHtml = "";
      var mdfMainComboProId = "";
      var ismdfrProCount = 0;
      var comboPropriceAply = "";
      var comboProPrice = 0;
      var indvlComponentNm = "";
      var showPricetxt = "";
      var compomainselval = "";

      $.each(compoListArr, function (index2, item1) {
        mdfMainComboProId = item1[0].product_id;
        ismdfrProCount =
          item1[0].modifiers !== null && item1[0].modifiers !== ""
            ? Object.keys(item1[0].modifiers).length
            : 0;
        comboProPrice = item1[0].product_price;
        comboPropriceAply =
          parseFloat(comboProPrice) > 0 &&
          parseInt(menuComponentApplyPrice) === 1 &&
          parseInt(ismdfrProCount) === 0
            ? comboProPrice
            : 0;
        indvlComponentNm =
          item1[0].product_alias !== ""
            ? stripslashes(item1[0].product_alias)
            : stripslashes(item1[0].product_name);

        showPricetxt =
          parseFloat(comboPropriceAply) > 0
            ? " ( + $" + parseFloat(comboPropriceAply).toFixed(2) + " )"
            : "";

        compomainselval =
          item1[0].product_id +
          "~" +
          indvlComponentNm +
          "~" +
          item1[0].product_sku +
          "~" +
          comboProPrice;

        compSelectBoxHtml +=
          "<option value='" +
          item1[0].product_id +
          "' data-compomainselval='" +
          compomainselval +
          "' data-mdfcombopro='" +
          mdfMainComboProId +
          "' data-combopropriceaply='" +
          comboPropriceAply +
          "' data-ismdfrprochk='" +
          ismdfrProCount +
          "' >" +
          indvlComponentNm +
          showPricetxt +
          "</option>";
      });

      var compSelectDropDown = Parser(compSelectBoxHtml);
      var statMdfVal = this.state["compo~~" + menuCmpId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultSelectVl;

      return (
        <select
          name="compomain_select"
          value={mdfSelectVl}
          className="components_selct components_selctbox_cls"
          onChange={this.handleChangeCompoMain.bind(this, menuCmpId)}
          id={this.state.dayindexID + "cmpp" + menuCmpId}
        >
          {compSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  showSingleComboMdfr(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;
      var menuComponentNm = stripslashes(splProducts.menu_component_name);

      const html = compoListArr.map((item1, index2) => (
        <div className="mdfr_list_divlcs_inv" key={"cmdf-" + index2}>
          {item1[0].modifiers !== null &&
            item1[0].modifiers !== "" &&
            this.singleComboMdfrOption(
              menuCmpId,
              item1[0].product_id,
              item1[0].modifiers
            )}
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  singleComboMdfrOption(menuCmpIdTxt, productIdTxt, cmpProMdfr) {
    if (Object.keys(cmpProMdfr).length > 0) {
      const html = cmpProMdfr.map((item2, index3) => (
        <div
          className={
            "compoMdfr_item_left individual_combo_mdf compo_mdf_" + productIdTxt
          }
          key={"cmd-" + index3}
        >
          <div className="product_chosen_addons">
            <div className="product_chosen_hea">
              <h6>{stripslashes(item2.pro_modifier_name)}</h6>
            </div>
          </div>

          <div className="form-group custom-select-bxcls">
            <div className={"re_select re_select_cmd" + index3}>
              {this.singleComboMdfrValuesOpn(menuCmpIdTxt, item2)}
            </div>
          </div>
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  singleComboMdfrValuesOpn(menuCmpIdTxt, mdfMainArr) {
    var mdfrValueArr =
      mdfMainArr.modifiers_values !== null && mdfMainArr.modifiers_values !== ""
        ? mdfMainArr.modifiers_values
        : Array();
    if (mdfrValueArr.length > 0) {
      var mainMdfId = mdfMainArr.pro_modifier_id;
      var mainMdfNm = stripslashes(mdfMainArr.pro_modifier_name);
      var mdfrSelectBoxHtml = "";
      var defaultMdfVal = "";
      $.each(mdfrValueArr, function (index4, item3) {
        var datamdfVl =
          mainMdfId +
          "~" +
          mainMdfNm +
          "~" +
          item3.pro_modifier_value_id +
          "~" +
          stripslashes(item3.pro_modifier_value_name) +
          "~" +
          item3.pro_modifier_value_price;

        var incPrc =
          parseFloat(item3.pro_modifier_value_price) > 0
            ? " ( + $" + item3.pro_modifier_value_price + " )"
            : "";

        mdfrSelectBoxHtml +=
          "<option value='" +
          item3.pro_modifier_value_id +
          "' data-selectmdfval='" +
          datamdfVl +
          "' data-mdfrpricevaluetxt='" +
          item3.pro_modifier_value_price +
          "' >" +
          item3.pro_modifier_value_name +
          incPrc +
          "</option>";
        if (item3.pro_modifier_value_is_default === "Yes") {
          defaultMdfVal = item3.pro_modifier_value_id;
        }
      });
      var mdfrSelectDropDown = Parser(mdfrSelectBoxHtml);
      var statMdfVal =
        this.state["compoInner~~" + menuCmpIdTxt + "~~" + mainMdfId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultMdfVal;

      return (
        <select
          name="compoinner_select"
          value={mdfSelectVl}
          className="components_mdf_selct"
          onChange={this.handleChangeCompoInner.bind(
            this,
            menuCmpIdTxt,
            mainMdfId
          )}
          id={this.state.dayindexID + "modifvl" + mainMdfId}
        >
          {mdfrSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  multipleComboDet(compoProDetailArr) {
    if (Object.keys(compoProDetailArr).length > 0) {
      const html = compoProDetailArr.map((item, index1) => (
        <div
          className={
            "product_chosen_item_left product_chosen_item_left_full compo_list_div main_combo_div minmax_maincombo_div compo_acc_active maincombo-" +
            item.menu_component_id
          }
          data-maincomboidtxt={item.menu_component_id}
          data-combodata={
            item.menu_component_id + "~" + item.menu_component_name + "~1"
          }
          data-combopriceapply={
            item.menu_component_apply_price !== ""
              ? item.menu_component_apply_price
              : 0
          }
          data-minselectcombo={item.menu_component_min_select}
          data-maxselectcombo={item.menu_component_max_select}
          data-modifierapply={item.menu_component_modifier_apply}
          key={"compo" + index1}
        >
          <div className="product_chosen_addons compo_pro_acc compo_acc_action">
            <div className="product_chosen_hea compopro_acc_head">
              <h6>{stripslashes(item.menu_component_name)}</h6>
            </div>
          </div>

          <div className="compo_acc_innerdiv">
            {item.menu_component_modifier_apply === "1" ? (
              <div className="compo_mdfselect_maindiv">
                <div className="form-group">
                  {this.showSingleComboOptions(item)}
                </div>

                <div
                  className="mdfr_list_divlcs"
                  data-mismatchpro=""
                  data-invcomboprice=""
                >
                  <div
                    className="mdfr_list_divlcs_error"
                    style={{ display: "none", color: "red" }}
                  >
                    Please choose valid modifiers
                  </div>
                  {this.showSingleComboMdfr(item)}
                </div>
              </div>
            ) : (
              <div className="compo_minmax_maindiv">
                <div className="max-min-bar">
                  {"You've chosen"} <span className="minSelectCls">0</span>{" "}
                  (Min. {item.menu_component_min_select} & Max.{" "}
                  {item.menu_component_max_select}){" "}
                </div>

                <div className="error_combo_div" style={{ display: "none" }}>
                  {" "}
                  Please select the min number of items.{" "}
                </div>

                {this.showMultipleComboOptions(item)}
              </div>
            )}
          </div>
        </div>
      ));

      return (
        <div className="product_chosen_col_inner compo_inner_main">{html}</div>
      );
    } else {
      return "";
    }
  }

  showComboMultiSelect(mutilSlct) {
    return mutilSlct === 1 ? "none" : "";
  }

  /* show multiple combo product option */
  showMultipleComboOptions(splProducts) {
    var multiSelectApply =
      splProducts.menu_component_multipleselection_apply !== ""
        ? parseInt(splProducts.menu_component_multipleselection_apply)
        : 0;
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;

      const compoMtplHtml = compoListArr.map((item1, index1) => (
        <div
          className={
            "chosen_adn mdfr_list_divlcs individual_combo_pro indvcombo-" +
            menuCmpId +
            "-" +
            item1[0].product_primary_id
          }
          data-mismatchpro=""
          data-invcomboprice="0"
          data-comboprice={item1[0].product_price}
          data-productdata={this.getCmpProData(item1)}
          key={index1}
        >
          <div className="bb-txt2 margin-15 chosen_adn_left">
            <span>
              {item1[0].product_alias !== ""
                ? stripslashes(item1[0].product_alias)
                : stripslashes(item1[0].product_name)}
            </span>{" "}
            <span className="combo_pro_price" style={{ display: "none" }}>
              {parseFloat(item1[0].product_price) > 0
                ? " ( +" + currencySymbol + item1[0].product_price + " )"
                : ""}
            </span>
          </div>
          <div
            className="radio pull-right combo-inc-parent chosen_adn_right"
            style={{ display: this.showComboMultiSelect(multiSelectApply) }}
          >
            <div className="qty_bx">
              <span
                className="qty_minus combo_inc"
                onClick={this.decComboQty.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
              >
                -
              </span>

              <label
                type="text"
                disabled
                data-qtyval="0"
                className="combo-input-label combo-input combolst_qty_value"
              >
                0
              </label>

              <span
                className="qty_plus combo_dec"
                onClick={this.incComboQty.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
              >
                +
              </span>
            </div>
          </div>

          {multiSelectApply === 1 && (
            <div className="checkboxcls pull-right">
              <input
                className="css-checkboxcls"
                type="checkbox"
                onChange={this.comboMultiSelectUpdate.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
                value={item1[0].product_price}
                name={"comboMultiStVal_" + menuCmpId}
                id={
                  this.state.dayindexID +
                  "comboMultiStVal_" +
                  menuCmpId +
                  "_" +
                  item1[0].product_primary_id
                }
              />{" "}
              <label
                htmlFor={
                  this.state.dayindexID +
                  "comboMultiStVal_" +
                  menuCmpId +
                  "_" +
                  item1[0].product_primary_id
                }
                className="css-label-chkbox"
              ></label>
            </div>
          )}
        </div>
      ));

      return <div>{compoMtplHtml}</div>;
    } else {
      return "";
    }
  }

  getCmpProData(proData) {
    var proName =
      proData[0].product_alias !== ""
        ? stripslashes(proData[0].product_alias)
        : stripslashes(proData[0].product_name);
    var pro_datetxt =
      proData[0].product_id +
      "~" +
      proName +
      "~" +
      proData[0].product_sku +
      "~" +
      proData[0].product_price;
    return pro_datetxt;
  }

  comboMultiSelectUpdate(menuCmpId, productPryId) {
    if (
      $(
        "#" +
          this.state.dayindexPro +
          " #" +
          this.state.dayindexID +
          "comboMultiStVal_" +
          menuCmpId +
          "_" +
          productPryId
      ).prop("checked") == true
    ) {
      this.incComboQty(menuCmpId, productPryId, "checkboxact");
    } else {
      this.decComboQty(menuCmpId, productPryId);
    }
  }

  incComboQty(menuCmpId, proId, actionFrm) {
    var $_this = $(
      "#" + this.state.dayindexPro + " .indvcombo-" + menuCmpId + "-" + proId
    );
    var intValInc = $_this.find(".combolst_qty_value").attr("data-qtyval");
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo =
      $_this.closest(".main_combo_div").attr("data-minselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-minselectcombo")
        : "0";
    var maxselectcombo =
      $_this.closest(".main_combo_div").attr("data-maxselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-maxselectcombo")
        : "0";
    var combopriceapply =
      $_this.closest(".main_combo_div").attr("data-combopriceapply") != ""
        ? $_this.closest(".main_combo_div").attr("data-combopriceapply")
        : "0";
    var invCompoQty = this.getInvCompoQty($_this);
    /* actionFrm === "checkboxact" && */
    if (parseInt(invCompoQty) >= parseInt(maxselectcombo)) {
      $(
        "#" +
          this.state.dayindexPro +
          " #" +
          this.state.dayindexID +
          "comboMultiStVal_" +
          menuCmpId +
          "_" +
          proId
      ).prop("checked", false);
      return false;
    }

    var chk_val = 0;
    if (!isNaN(intValInc) && parseInt(invCompoQty) < parseInt(maxselectcombo)) {
      intValInc = parseInt(intValInc + 1);
      chk_val = 1;
    }

    $_this.find(".combolst_qty_value").attr("data-qtyval", intValInc);
    $_this.find(".combolst_qty_value").html(intValInc);

    var comboProPrice = $_this.attr("data-invcomboprice");
    var invCompoQty = this.getInvCompoQty($_this);
    if (
      (invCompoQty > parseInt(minselectcombo) ||
        parseInt(combopriceapply) == 1) &&
      chk_val == 1
    ) {
      $_this.find(".combo_pro_price").show();
      var invComboproPrice = $_this.attr("data-invcomboprice");
      var comboprice = $_this.attr("data-comboprice");
      comboProPrice = parseFloat(invComboproPrice) + parseFloat(comboprice);
    }

    if (parseInt(minselectcombo) == 0 || parseInt(combopriceapply) == 1) {
      $_this.find(".combo_pro_price").show();
    }
    $_this.attr("data-invcomboprice", comboProPrice);
    this.updateProductPricefun();
  }

  decComboQty(menuCmpId, proId) {
    var $_this = $(
      "#" + this.state.dayindexPro + " .indvcombo-" + menuCmpId + "-" + proId
    );
    var intValInc = $_this.find(".combolst_qty_value").attr("data-qtyval");
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo =
      $_this.closest(".main_combo_div").attr("data-minselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-minselectcombo")
        : "0";
    var maxselectcombo =
      $_this.closest(".main_combo_div").attr("data-maxselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-maxselectcombo")
        : "0";
    var combopriceapply =
      $_this.closest(".main_combo_div").attr("data-combopriceapply") != ""
        ? $_this.closest(".main_combo_div").attr("data-combopriceapply")
        : "0";

    var minusChkVal = 0;
    if (!isNaN(intValInc) && parseInt(intValInc) >= 1) {
      var intValInc = parseInt(intValInc - 1);
      minusChkVal = 1;
    }

    $_this.find(".combolst_qty_value").attr("data-qtyval", intValInc);
    $_this.find(".combolst_qty_value").html(intValInc);
    var invCompoQty = this.getInvCompoQty($_this);
    var comboProPrice = $_this.attr("data-invcomboprice");
    var defComboprice = $_this.attr("data-comboprice");
    if (
      (invCompoQty >= parseInt(minselectcombo) && minusChkVal == 1) ||
      (parseInt(combopriceapply) == 1 && minusChkVal == 1)
    ) {
      if (
        parseInt(combopriceapply) == 1 &&
        minusChkVal == 1 &&
        parseFloat(defComboprice) == 0 &&
        parseFloat(comboProPrice) == 0
      ) {
        var temp_price = 0;
        $_this.attr("data-invcomboprice", temp_price);
        $_this.find(".combo_pro_price").hide();
      } else if (
        parseFloat(comboProPrice) >= parseFloat(defComboprice) &&
        parseFloat(comboProPrice) > 0
      ) {
        var temp_price = parseFloat(comboProPrice) - parseFloat(defComboprice);
        $_this.attr("data-invcomboprice", temp_price);
        if (parseFloat(temp_price) == 0) {
          $_this.find(".combo_pro_price").hide();
        }
      } else {
        var rtn_val = 0;
        $_this
          .closest(".main_combo_div")
          .find(".individual_combo_pro")
          .each(function () {
            var thisInvPrc = $(this).attr("data-invcomboprice");
            if (parseFloat(thisInvPrc) > 0 && rtn_val == 0) {
              rtn_val = 1;
              var comboproprice = thisInvPrc;
              var def_combo_price = $(this).attr("data-comboprice");
              var tempPrice =
                parseFloat(comboproprice) - parseFloat(def_combo_price);
              $(this).attr("data-invcomboprice", tempPrice);
              if (parseFloat(tempPrice) == 0) {
                $(this).find(".combo_pro_price").hide();
              }
            }
          });
      }
    }

    if (parseInt(minselectcombo) == 0 || parseInt(combopriceapply) == 1) {
      $_this.find(".combo_pro_price").show();
    }

    this.updateProductPricefun();
  }

  getInvCompoQty($_this) {
    $_this.closest(".main_combo_div").find(".error_combo_div").hide();
    var combolst_qty = 0;
    $_this
      .closest(".main_combo_div")
      .find(".combolst_qty_value")
      .each(function () {
        combolst_qty += parseInt($(this).attr("data-qtyval"));
      });
    return combolst_qty;
  }

  updateRemarksCompo(event) {
    this.setState({ compoApi_call: "No", product_remarks: event.target.value });
    this.setState({ remaining: 30 - event.target.value.length });
  }

  updateProductPricefun() {
    var minmaxMainCnt = $(
      "#" + this.state.dayindexPro + " .compo_minmax_maindiv"
    ).length;
    if (minmaxMainCnt > 0) {
      $("#" + this.state.dayindexPro + " .compo_minmax_maindiv").each(function (
        indx
      ) {
        var invQtyCnt = 0;
        $(this)
          .find(".individual_combo_pro")
          .each(function (indx2) {
            var qtyval = $(this)
              .find(".combolst_qty_value")
              .attr("data-qtyval");
            invQtyCnt = parseInt(invQtyCnt) + parseInt(qtyval);
          });
        $(this).find(".minSelectCls").html(invQtyCnt);
      });
    }

    var combo_pro_price = 0;
    $("#" + this.state.dayindexPro + " .mdfr_list_divlcs").each(function () {
      var invcomboPriceVl = $(this).attr("data-invcomboprice");
      invcomboPriceVl = invcomboPriceVl !== "" ? invcomboPriceVl : 0;

      combo_pro_price += parseFloat(invcomboPriceVl);
    });

    var qty_txt =
      $("#" + this.state.dayindexPro + " .compo_proqty_input").val() != ""
        ? parseInt(
            $("#" + this.state.dayindexPro + " .compo_proqty_input").val()
          )
        : 0;
    var pro_price_val =
      $(
        "#" +
          this.state.dayindexPro +
          " #" +
          this.state.dayindexID +
          "product_unitprice"
      ).val() != ""
        ? parseFloat(
            $(
              "#" +
                this.state.dayindexPro +
                " #" +
                this.state.dayindexID +
                "product_unitprice"
            ).val()
          )
        : 0;

    var exc_price = parseFloat(pro_price_val) + parseFloat(combo_pro_price);
    exc_price = parseInt(qty_txt) * parseFloat(exc_price);

    $(
      "#" +
        this.state.dayindexPro +
        " #" +
        this.state.dayindexID +
        "incr_compo_price"
    ).val(exc_price);
    $(
      "#" +
        this.state.dayindexPro +
        " #" +
        this.state.dayindexID +
        "extra_additional_price"
    ).val(parseFloat(combo_pro_price));
    $(
      "#" +
        this.state.dayindexPro +
        " #" +
        this.state.dayindexID +
        "compoprice_final"
    ).html(
      "<sup>" + currencySymbol + "</sup>" + parseFloat(exc_price).toFixed(2)
    );
  }

  compoQtyAction(actionFlg) {
    var proqtyInput = $(
      "#" + this.state.dayindexPro + " .compo_proqty_input"
    ).val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }

    this.setState({ compoApi_call: "No", compoinput_value: proqtyInput });
  }

  handleChangeCompoMain(compoVl, event) {
    this.setState({
      compoApi_call: "Yes",
      ["compo~~" + compoVl]: event.target.value,
    });
  }

  handleChangeCompoInner(menuCmpIdTxt, compoVl, event) {
    this.setState({
      compoApi_call: "Yes",
      ["compoInner~~" + menuCmpIdTxt + "~~" + compoVl]: event.target.value,
    });
  }

  getComboproData($_this, CompoType) {
    var comboproSet = Array(),
      comboproMdf = Array(),
      aplypriceZero = 0;

    if (CompoType === 1) {
      $_this.find(".individual_combo_pro").each(function () {
        var componentsProDet = $(this).attr("data-productdata");
        var compoProDet = componentsProDet.split("~");
        var combolstQtyValue = $(this)
          .find(".combolst_qty_value")
          .attr("data-qtyval");
        var comboProInvPrice = $(this).attr("data-invcomboprice");
        if (parseInt(combolstQtyValue) > 0) {
          comboproSet.push({
            product_id: compoProDet[0],
            product_name: compoProDet[1],
            product_sku: compoProDet[2],
            product_price: comboProInvPrice,
            product_qty: combolstQtyValue,
            modifiers: comboproMdf,
          });
        }
      });
    } else {
      $_this.find(".components_selct").each(function () {
        var mdfcombopro_id = $(this).find(":selected").attr("data-mdfcombopro");
        var combopriceapplychk = $(this)
          .closest(".main_combo_div")
          .attr("data-combopriceapply");
        var aplyprice_temp = $(this)
          .find(":selected")
          .attr("data-combopropriceaply");
        var comboMdfSet = Array();
        $(this)
          .closest(".main_combo_div")
          .find(".compo_mdf_" + mdfcombopro_id)
          .each(function () {
            var combopro_mdf_txt = $(this).find(".components_mdf_selct").val();
            var comboMdfValueSet = Array();
            if (combopro_mdf_txt !== "") {
              var modifierCombosets_txt = $(this)
                .find(".components_mdf_selct")
                .find("option:selected")
                .attr("data-selectmdfval");
              var mdfSetDet =
                modifierCombosets_txt !== "" &&
                modifierCombosets_txt !== undefined
                  ? modifierCombosets_txt.split("~")
                  : Array();

              if (Object.keys(mdfSetDet).length > 0) {
                comboMdfValueSet.push({
                  modifier_value_name: mdfSetDet[3],
                  modifier_value_id: mdfSetDet[2],
                  modifier_value_price: mdfSetDet[4],
                  modifier_value_qty: 1,
                });
                comboMdfSet.push({
                  modifier_name: mdfSetDet[1],
                  modifier_id: mdfSetDet[0],
                  modifiers_values: comboMdfValueSet,
                });
              }
            }
          });
        if ($(this).find("checked").length > 0) {
          var componentsProDet = $(this)
            .find("checked")
            .attr("data-compomainselval");
          var compoProDet = componentsProDet.split("~");
          var combolstQtyValue = 1;
          var comboProInvPrice =
            parseFloat(combopriceapplychk) > 0
              ? parseFloat(aplyprice_temp)
              : parseFloat(aplypriceZero);
          comboproSet.push({
            product_id: compoProDet[0],
            product_name: compoProDet[1],
            product_sku: compoProDet[2],
            product_price: comboProInvPrice,
            product_qty: combolstQtyValue,
            modifiers: comboMdfSet,
          });
        }
      });
    }

    return comboproSet;
  }

  checkMinValfun($_this) {
    var combo_qtycount = 0,
      min_val_error = 0;
    var min_selectcombo =
      $_this.attr("data-minselectcombo") != ""
        ? $_this.attr("data-minselectcombo")
        : "0";
    $_this.find(".combolst_qty_value").each(function () {
      var qtyval = $(this).attr("data-qtyval");
      combo_qtycount += parseInt(qtyval);
    });
    if (parseInt(min_selectcombo) > parseInt(combo_qtycount)) {
      min_val_error = 1;
    }
    return min_val_error;
  }

  addToCartCombo(compoProdDetail, actionFlg) {
    var InvalidMdfrCompo = "No";
    $("#" + this.state.dayindexPro + " .main_combo_div").each(function () {
      if ($(this).find(".mdfr_list_divlcs").attr("data-mismatchpro") == "1") {
        InvalidMdfrCompo = "Yes";
      }
    });

    if (InvalidMdfrCompo == "No") {
      if (actionFlg === "initial") {
        $("#" + this.state.dayindexPro + " .compo_add_div").hide();
        $("#" + this.state.dayindexPro + " .compo_update_div").show();

        //Last Updated
        return false;
      } else {
        var $_react_this = this;
        var menuSet = Array();
        var productDetailsMain = Array();
        var modifier = Array();
        var CompoType = $(
          "#" +
            this.state.dayindexPro +
            " #" +
            this.state.dayindexID +
            "set_menu_component_type"
        ).val();
        CompoType = parseInt(CompoType);
        var compo_errors = "0";

        if (CompoType === 1) {
          $("#" + this.state.dayindexPro + " .main_combo_div").each(
            function () {
              var modifierapply = $(this).attr("data-modifierapply");
              if (modifierapply === "1") {
                var combodata_txt = $(this).attr("data-combodata");
                var menu_component = combodata_txt.split("~");
                var productDetails = $_react_this.getComboproData($(this), 0);
                productDetailsMain.push({
                  menu_component_id: menu_component[0],
                  menu_component_name: menu_component[1],
                  product_details: productDetails,
                  min_max_flag: menu_component[2],
                });
              } else {
                var check_min_val = $_react_this.checkMinValfun($(this));
                if (check_min_val == 0) {
                  var combodata_txt = $(this).attr("data-combodata");
                  menu_component = combodata_txt.split("~");
                  var productDetails = $_react_this.getComboproData(
                    $(this),
                    CompoType
                  );
                  productDetailsMain.push({
                    menu_component_id: menu_component[0],
                    menu_component_name: menu_component[1],
                    product_details: productDetails,
                    min_max_flag: menu_component[2],
                  });
                } else {
                  compo_errors = "1";
                  $(this).find(".error_combo_div").show();
                  if (
                    $(
                      "#ProductDetailMd" +
                        $_react_this.state.dayindex +
                        " .error_combo_div:visible"
                    ).length > 0
                  ) {
                    $("html, body").animate(
                      {
                        scrollTop:
                          $(
                            "#ProductDetailMd" +
                              $_react_this.state.dayindex +
                              " .error_combo_div:visible:first"
                          ).offset().top - 200,
                      },
                      1000
                    );
                  }
                  return false;
                }
              }
            }
          );
        } else {
          $("." + this.state.dayindexID + "main_combo_div").each(function () {
            var combodata_txt = $(this).attr("data-combodata");
            var menu_component = combodata_txt.split("~");
            var productDetails = $_react_this.getComboproData(
              $(this),
              CompoType
            );
            if (productDetails !== "") {
              productDetailsMain.push({
                menu_component_id: menu_component[0],
                menu_component_name: menu_component[1],
                product_details: productDetails,
                min_max_flag: menu_component[2],
              });
            }
          });
        }
        menuSet = productDetailsMain;

        if (compo_errors == "0" && Object.keys(menuSet).length > 0) {
          $(
            "#" +
              this.state.dayindex +
              "_proIndex-" +
              compoProdDetail.product_primary_id
          ).addClass("active");
          $(
            "#" +
              this.state.dayindex +
              "_proIndex-" +
              compoProdDetail.product_primary_id +
              " .compo_product_lk"
          ).html("Selected");
          var addPrice = $(
            "#" +
              this.state.dayindex +
              "_product_" +
              compoProdDetail.product_primary_id +
              " #" +
              this.state.dayindex +
              "_extra_additional_price"
          ).val();
          var selectedQty = $(
            "#" +
              this.state.dayindex +
              "_product_" +
              compoProdDetail.product_primary_id +
              " .compo_proqty_input"
          ).val();
          $(
            "#" +
              this.state.dayindex +
              "_proIndex-" +
              compoProdDetail.product_primary_id
          ).addClass("active");
          $(
            "#" +
              this.state.dayindex +
              "_proIndex-" +
              compoProdDetail.product_primary_id +
              " .compo_product_lk"
          ).html("Selected");

          $(
            "#" +
              this.state.dayindex +
              "_proIndex-" +
              compoProdDetail.product_primary_id +
              " .meal-plane-combo-addt-price"
          ).show();
          $(
            "#" +
              this.state.dayindex +
              "_proIndex-" +
              compoProdDetail.product_primary_id +
              " .meal-plane-combo-addt-price span"
          ).html("(+$" + addPrice + ")");
          $(
            "#" +
              this.state.dayindex +
              "_proIndex-" +
              compoProdDetail.product_primary_id +
              " .meal-plane-combo-addt-price strong"
          ).html(" X" + selectedQty);
          $(
            "#" +
              this.state.dayindex +
              "_proIndex-" +
              compoProdDetail.product_primary_id +
              " .updateproqty_input"
          ).val(selectedQty);
          console.log(
            $(
              "#" +
                this.state.dayindex +
                "_proIndex-" +
                compoProdDetail.product_primary_id +
                " .proextraprice_input"
            ).length,
            "MMMMMM"
          );
          $(
            "#" +
              this.state.dayindex +
              "_proIndex-" +
              compoProdDetail.product_primary_id +
              " .proextraprice_input"
          ).val(addPrice);
          $("body").removeClass("meal-plan-popup");
          $("#" + this.state.dayindexPro).modal("toggle");
        } else {
          if (
            $(
              "#ProductDetailMd" +
                this.state.dayindex +
                " .error_combo_div:visible"
            ).length > 0
          ) {
            var sectionOffset = $(
              "#ProductDetailMd" +
                this.state.dayindex +
                " .error_combo_div:visible:first"
            ).position();
            $("#ProductDetailMd" + this.state.dayindex).animate(
              {
                scrollTop: sectionOffset.top - 30,
              },
              "slow"
            );
          }
          $("#" + this.state.dayindexPro + " .compocart_error_msg").html(
            "Sorry!. Product Detail was not valid."
          );
          $("#" + this.state.dayindexPro + " .compocart_error_msg").show();
          $("#" + this.state.dayindexPro + " .compocart_error_msg")
            .delay(6000)
            .fadeOut();
          return false;
        }
      }
    } else {
      if (
        $(
          "#ProductDetailMd" + this.state.dayindex + " .error_combo_div:visible"
        ).length > 0
      ) {
        var sectionOffset = $(
          "#ProductDetailMd" +
            this.state.dayindex +
            " .error_combo_div:visible:first"
        ).position();
        $("#ProductDetailMd" + this.state.dayindex).animate(
          {
            scrollTop: sectionOffset.top - 30,
          },
          "slow"
        );
      }
      $("#" + this.state.dayindexPro + " .compocart_error_msg").html(
        "Sorry!. Invalid product combination."
      );
      $("#" + this.state.dayindexPro + " .compocart_error_msg").show();
      $("#" + this.state.dayindexPro + " .compocart_error_msg")
        .delay(6000)
        .fadeOut();
      return false;
    }
  }

  checkProductPrice() {
    var allModVal = "";
    var errorChk = 0;
    var productID = $(
      "#" +
        this.state.dayindexPro +
        " #" +
        this.state.dayindexID +
        "modProductId"
    ).val();
    var inc_lp = 1;
    var TotalCnt = $("#" + this.state.dayindexPro + " .modifierList").length;

    $("#" + this.state.dayindexPro + " .modfir_addcart_cls").show();
    showLoader("modfir_addcart_cls", "class");

    $("#" + this.state.dayindexPro + " .modifierList").each(function () {
      var modVal = $(this).val();
      var modSelectVal = $(this).find("option:selected").attr("data-mdfvl");
      if (modVal != "" && modSelectVal != "") {
        var modifier_sets = modSelectVal.split("~");
        allModVal +=
          inc_lp == TotalCnt ? modifier_sets[3] : modifier_sets[3] + ";";
      } else if (modSelectVal == "" || modVal == "") {
        errorChk = 1;
      }
      inc_lp++;
    });

    if (errorChk === 0 && allModVal != "") {
      Axios.get(
        apiUrl +
          "products/validate_product?app_id=" +
          appId +
          "&product_id=" +
          productID +
          "&modifier_value_id=" +
          allModVal
      ).then((res) => {
        var response = res.data;
        hideLoader("modfir_addcart_cls", "class");
        if (response.status === "ok") {
          var proQty = $(".modfir_proqty_input").val();
          var productPrice = response.result_set[0].product_price;
          var productTotalPrice =
            parseFloat(response.result_set[0].product_price) *
            parseFloat(proQty);
          productTotalPrice = parseFloat(productTotalPrice).toFixed(2);
          /*$("#modParentProductId").val(response.result_set[0].alias_product_primary_id);
					$("#modProductPrice").val(productPrice);
					$("#modProductTotalPrice").val(productTotalPrice);
					$('#id_price_final').html(productTotalPrice);*/
          this.setState({
            mdfApi_call: "No",
            modParentProductId: response.result_set[0].alias_product_primary_id,
            modProductPrice: productPrice,
            modProductTotalPrice: productTotalPrice,
          });
        } else {
          $("#" + this.state.dayindexPro + " .modfir_addcart_cls").hide();
          $(
            "#" +
              this.state.dayindexPro +
              " #" +
              this.state.dayindex +
              "modErrorDiv"
          ).show();
          $(
            "#" +
              this.state.dayindexPro +
              " #" +
              this.state.dayindex +
              "modErrorDiv"
          )
            .delay(6000)
            .fadeOut();
        }
      });
    }
  }

  setModifierValFun($_this) {
    var mdfcombopro = $_this.find(":selected").attr("data-mdfcombopro");
    $_this.closest(".main_combo_div").find(".individual_combo_mdf").hide();
    $_this
      .closest(".main_combo_div")
      .find(".compo_mdf_" + mdfcombopro)
      .show();
  }

  setOverallSubmdfrPrice($_this) {
    var mainmdfrid = $_this.find(":selected").attr("data-mdfcombopro");
    var ismdfrprochk = $_this.find(":selected").attr("data-ismdfrprochk");
    var combopriceapplychk = $_this
      .closest(".main_combo_div")
      .attr("data-combopriceapply");
    var maincomboidtxt = $_this
      .closest(".main_combo_div")
      .attr("data-maincomboidtxt");
    var mdfrpricevaluetxt_val = 0,
      inv_comopo_mismatch_pro = "";

    if (parseFloat(ismdfrprochk) > 0) {
      if (this.state.compoApi_call === "Yes") {
        var rtrn_msg = this.checkModifierPricefun(
          $_this,
          maincomboidtxt,
          mainmdfrid
        );
      }
    } else {
      if (parseFloat(combopriceapplychk) > 0) {
        if ($_this.find(":checked").length > 0) {
          var aplyprice_temp = 0;
          $_this.find(":checked").each(function () {
            if (parseFloat($(this).attr("data-combopropriceaply")) > 0) {
              aplyprice_temp =
                parseFloat(aplyprice_temp) +
                parseFloat($(this).attr("data-combopropriceaply"));
            }
          });
          /*   var aplyprice_temp = $_this
            .find(":checked")
            .attr("data-combopropriceaply"); */

          mdfrpricevaluetxt_val = parseFloat(aplyprice_temp);
        }
      }

      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-invcomboprice", mdfrpricevaluetxt_val);
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-mismatchpro", inv_comopo_mismatch_pro);
      $_this.closest(".main_combo_div").find(".mdfr_list_divlcs_error").hide();
    }

    this.checkModifierErrorfun();
  }

  checkModifierPricefun($_this, maincomboidtxt, mdfcombopro_id) {
    var returntxt_msg = "";
    var sub_mdfr_ids = "";
    $("#" + this.state.dayindexPro + " .maincombo-" + maincomboidtxt)
      .find(".compo_mdf_" + mdfcombopro_id)
      .each(function () {
        var modVal = $(this).find(".components_mdf_selct").val();
        if (modVal !== "") {
          var modifier_combosets_txt = $(this)
            .find(".components_mdf_selct")
            .find("option:selected")
            .attr("data-selectmdfval");
          var modifier_combosets =
            modifier_combosets_txt !== "" &&
            modifier_combosets_txt !== undefined
              ? modifier_combosets_txt.split("~")
              : new Array();
          if (modifier_combosets.length >= 2) {
            if (modifier_combosets[2]) {
              if (sub_mdfr_ids != "") {
                sub_mdfr_ids += ";";
              }
              sub_mdfr_ids = sub_mdfr_ids + modifier_combosets[2];
            }
          }
        }
      });

    if (sub_mdfr_ids !== "") {
      showLoader("compo_addcart_cls", "class");
      Axios.get(
        apiUrl +
          "products/validate_product?app_id=" +
          appId +
          "&product_id=" +
          mdfcombopro_id +
          "&modifier_value_id=" +
          sub_mdfr_ids
      ).then((res) => {
        var response = res.data;
        if (response.status === "ok") {
          var tempval = "";
          this.updateIndvModifrprice(
            $_this
              .closest(".main_combo_div")
              .find(".compo_mdf_" + mdfcombopro_id + ":first")
          );
        } else {
          var tempval = "1";
        }
        $_this
          .closest(".main_combo_div")
          .find(".mdfr_list_divlcs")
          .attr("data-mismatchpro", tempval);
        this.checkModifierErrorfun();
        hideLoader("compo_addcart_cls", "class");
      });
    }

    return returntxt_msg;
  }

  updateIndvModifrprice($_this) {
    var mdfrpricevaluetxt_val = 0;
    $_this
      .closest(".mdfr_list_divlcs_inv")
      .find(".components_mdf_selct")
      .each(function () {
        var mdfrpricevaluetxt = $(this)
          .find(":selected")
          .attr("data-mdfrpricevaluetxt");
        mdfrpricevaluetxt =
          mdfrpricevaluetxt !== "" && mdfrpricevaluetxt !== undefined
            ? parseFloat(mdfrpricevaluetxt)
            : 0;
        mdfrpricevaluetxt_val =
          parseFloat(mdfrpricevaluetxt_val) + parseFloat(mdfrpricevaluetxt);
      });
    $_this
      .closest(".mdfr_list_divlcs")
      .attr("data-invcomboprice", mdfrpricevaluetxt_val);
  }

  checkModifierErrorfun() {
    var over_allerror = "";
    $("#" + this.state.dayindexPro + " .mdfr_list_divlcs").each(function () {
      if ($(this).attr("data-mismatchpro") == "1") {
        over_allerror = "1";
        $(this).find(".mdfr_list_divlcs_error").show();
      } else {
        $(this).find(".mdfr_list_divlcs_error").hide();
      }
    });

    /*$('#comopo_mismatch_pro').val(over_allerror);*/

    this.updateProductPricefun();

    if (over_allerror == "1") {
      $("#" + this.state.dayindexPro + " .compo_addcart_cls").hide();
    } else {
      $("#" + this.state.dayindexPro + " .compo_addcart_cls").show();
    }
  }

  componentDidUpdate() {
    var TotalCnt = $("#" + this.state.dayindexPro + " .modifierList").length;
    var modProductSlug = $(
      "#" +
        this.state.dayindexPro +
        " #" +
        this.state.dayindexID +
        "modProductSlug"
    ).val();
    if (
      TotalCnt > 0 &&
      this.state.mdfApi_call === "Yes" &&
      this.state.selectedProSlug === modProductSlug
    ) {
      this.checkProductPrice();
    }

    var $_reactThis = this;
    var individualComboCnt = $(
      "#" + this.state.dayindexPro + " .individual_combo_mdf"
    ).length;
    if (individualComboCnt > 0) {
      $("#" + this.state.dayindexPro + " .main_combo_div").each(function () {
        $_reactThis.setModifierValFun($(this).find(".components_selct"));
      });

      if ($("#" + this.state.dayindexPro + " .components_selct").length > 0) {
        $("#" + this.state.dayindexPro + " .components_selct").each(
          function () {
            $_reactThis.setOverallSubmdfrPrice($(this));
          }
        );
      }
    }

    var indlMinMxComboCnt = $(
      "#" + this.state.dayindexPro + " .individual_combo_pro"
    ).length;
    if (indlMinMxComboCnt > 0) {
      $("#" + this.state.dayindexPro + " .main_combo_div").each(function () {
        var minselectcombo_txt =
          $(this).data("minselectcombo") != ""
            ? $(this).data("minselectcombo")
            : "0";
        var combopriceapply_txt =
          $(this).data("combopriceapply") != ""
            ? $(this).data("combopriceapply")
            : "0";
        if (
          parseInt(minselectcombo_txt) === 0 ||
          parseInt(combopriceapply_txt) === 1
        ) {
          $(this).find(".combo_pro_price").show();
        } else {
          // $(this).find(".combo_pro_price").hide();
        }
      });
    }

    var minmaxMainCnt = $(
      "#" + this.state.dayindexPro + " .minmax_maincombo_div"
    ).length;
    var chkAplyModfInMinmax = 0;
    if (minmaxMainCnt > 0) {
      chkAplyModfInMinmax = $(
        "#" + this.state.dayindexPro + " .minmax_maincombo_div"
      ).find(".components_selctbox_cls").length;
      this.updateProductPricefun();
    }

    var singleSelectCompo = $(
      "#" + this.state.dayindexPro + " .components_selctbox_cls"
    ).length;
    if (
      singleSelectCompo > 0 &&
      ((minmaxMainCnt === 0 &&
        indlMinMxComboCnt === 0 &&
        individualComboCnt === 0) ||
        chkAplyModfInMinmax > 0)
    ) {
      if ($("#" + this.state.dayindexPro + " .components_selct").length > 0) {
        $("#" + this.state.dayindexPro + " .components_selct").each(
          function () {
            $_reactThis.setOverallSubmdfrPrice($(this));
          }
        );
      }
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };

  closeProductDetails() {
    $("body").removeClass("meal-plan-popup");
  }
  render() {
    /* prodetailinner-main-div */
    return (
      <div
        className="modal fade commom-modal-topcls "
        id={"ProductDetailMd" + this.state.dayindex}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="common-modal-head">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.closeProductDetails.bind(this)}
                  aria-hidden="true"
                >
                  X
                </button>
              </div>

              {/* modal-detail-box start */}
              <div className="modal-detail-box">
                {this.productDetailsMain()}
              </div>
              {/* modal-detail-box end */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(ProductDetail);
