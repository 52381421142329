/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_MEAL_PLAN_CORDERDETAIL,
  SET_MEAL_PLAN_CORDERDETAIL,
} from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetMealPlanCorderDetail = function* () {
  yield takeEvery(GET_MEAL_PLAN_CORDERDETAIL, workerGetMealPlanCorderDetail);
};

function* workerGetMealPlanCorderDetail({ params }) {
  try {
    const uri =
      apiUrl + "mealplan/order_history?order_status=C&app_id=" + appId + params;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_MEAL_PLAN_CORDERDETAIL, value: resultArr });
  } catch {
    console.log("Get Current order Failed");
  }
}
